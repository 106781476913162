import { t } from 'i18next';
import React from 'react';
import { GenericFeedback, Processing } from 'src/components/';

export const ProcessingCard = () => {
  return (
    <GenericFeedback>
      <Processing
        title={t('LegitimizationProcessingTitle')}
        message={t('LegitimizationProcessing')}
      />
    </GenericFeedback>
  );
};
