import React from 'react';
import { format } from 'date-fns';
import moment from 'moment';

import { Month, TableCaption, Year } from './styles';
import { getLocale } from './DayPicker';

export const Caption = ({ displayMonth }) => {
  const dateLocale: string = moment.locale();
  const locale = getLocale(dateLocale);
  const date = new Date(displayMonth);
  const month = format(date, 'MMMM', { locale });

  return (
    <TableCaption>
      <Year>{date.getFullYear()}</Year>
      <Month>{month}</Month>
    </TableCaption>
  );
};
