import { GridStyled, mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';

export const Container = styled(GridStyled)`
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.pallete.borders.filterBottom}`};
`;

export const GridStatus = styled(GridStyled)`
  ${mediaMaxWidth2.tablet`
    align-items: flex-end;
  `}
`;
