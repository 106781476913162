import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Card } from '../../Card';
import { CardLoader } from 'src/components';
import {
  ISigningRulesAndSignatories,
  TSigningCaseDetailsRouteProps,
} from 'src/models';
import { getReject } from 'src/state/Signing/Reject/selectors';
import {
  getOtherContractingParties,
  getSigningCaseData,
  getSignatures,
} from 'src/state/SigningCaseDetails/selectors';
import * as signingRulesAndSignatoriesSelectors from 'src/state/SigningRulesAndSignatories/selectors';
import { TRootState } from 'src/state/types';
import { Signatories } from './components';
import { getCardType } from './services';
import { getOpenedSigningCaseListItem } from 'src/state/SigningCasesList/selectors';
import * as fromRoot from 'src/state/rootReducer';
import { Config } from 'src/state/Config';

interface Props extends TSigningCaseDetailsRouteProps, WithNamespaces {
  signingCase: ReturnType<typeof getSigningCaseData>;
  signingCaseListItem: ReturnType<typeof getOpenedSigningCaseListItem>;
  isSigningRulesAndSignatoriesLoading: boolean;
  isSignatureSuccessful: ReturnType<typeof fromRoot.getIsSignatureSuccessful>;
  signatories: ReturnType<
    typeof signingRulesAndSignatoriesSelectors.getSignatoriesV2
  >;
  signatures: ReturnType<typeof getSignatures>;
  signingRules: ISigningRulesAndSignatories['signingRules'];
  otherContractingParties: ReturnType<typeof getOtherContractingParties>;
  rejected: ReturnType<typeof getReject>;
  show: boolean;
}

class SigneesCard extends Component<Props> {
  render() {
    const {
      signingCase,
      signatories,
      signatures,
      otherContractingParties,
      signingRules,
      show,
      t,
    } = this.props;

    if (!show || !signingCase) {
      return null;
    }

    const title = t('AuthorizedSignatories');
    const status = this.getSigningCaseStatus();
    const cardType = getCardType(status);

    if (this.isLoading()) {
      return (
        <Card title={title} type={cardType}>
          <CardLoader />
        </Card>
      );
    } else if (!this.doSignatoriesExist()) {
      return null;
    }

    if (
      this.hasSignatoriesOrOthercontractingParties(
        signatories,
        otherContractingParties
      )
    ) {
      return (
        <Card
          labelText={title}
          title={title}
          type={cardType}
          data-testid="signatories-card"
        >
          <Signatories
            signatories={signatories || []}
            contractingParties={otherContractingParties}
            signatures={signatures}
            status={status}
            signingRules={signingRules}
          />
        </Card>
      );
    }

    return null;
  }

  private hasSignatoriesOrOthercontractingParties = (
    signatories,
    otherContractingParties
  ) =>
    (signatories && signatories.length) ||
    (otherContractingParties && otherContractingParties.length);

  private isLoading = () => {
    return (
      this.props.isSigningRulesAndSignatoriesLoading &&
      this.didSignatorySignOrReject()
    );
  };

  private didSignatorySignOrReject = () => {
    return (
      this.props.rejected.data || this.props.isSignatureSuccessful.data.success
    );
  };

  private doSignatoriesExist = () =>
    this.props.signatories && this.props.signatories.length;

  private getSigningCaseStatus = () => {
    return (
      this.props.signingCase.status ||
      (this.props.signingCaseListItem && this.props.signingCaseListItem.status)
    );
  };
}

export const doShowSignatories = isSigningCaseReadyToSign =>
  !(Config.isSwedishSite() && isSigningCaseReadyToSign);

const mapStateToProps = (
  state: TRootState,
  props: TSigningCaseDetailsRouteProps
) => {
  const signingCase = getSigningCaseData(state, props.match.params.caseId);
  const signatures = getSignatures(state, props.match.params.caseId);
  return {
    isSignatureSuccessful: fromRoot.getIsSignatureSuccessful(state),
    isSigningRulesAndSignatoriesLoading: signingRulesAndSignatoriesSelectors.getIsLoading(
      state
    ),
    otherContractingParties: getOtherContractingParties(
      state,
      props.match.params.caseId
    ),
    rejected: getReject(state),
    signatories: signingRulesAndSignatoriesSelectors.getSignatoriesV2(
      state,
      signingCase && signingCase.status,
      signatures
    ),
    signatures: signatures,
    signingCase: signingCase,
    signingCaseListItem: getOpenedSigningCaseListItem(state),
    signingRules: signingRulesAndSignatoriesSelectors.getSigningRules(state),
    show: doShowSignatories(
      fromRoot.isSigningCaseReadyToSign(state, props.match.params.caseId)
    ),
  };
};

const ExtendedComponent = withNamespaces()(
  withRouter(
    connect(
      mapStateToProps,
      null
    )(SigneesCard)
  )
);

export { ExtendedComponent as SigneesCard };
export default SigneesCard;
