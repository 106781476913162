import { ISigningCaseListItem } from 'src/models';

export const listState = {
  hasNextPage: false,
  isLoading: false,
  list: [],
  page: 0,
};

export type SingingCaseList = {
  list: ISigningCaseListItem[];
} & Partial<ListState>;

export interface IState {
  Archived: SingingCaseList;
  AwaitingYourSignature: SingingCaseList;
  Pending: SingingCaseList;
  openedSigningCase?: {
    status: string;
    index: number;
  };
}

export const initialState: IState = {
  Archived: listState,
  AwaitingYourSignature: listState,
  Pending: listState,
};

export type ListState = Readonly<typeof listState>;

export interface IfetchSigningCasesSuccess extends SingingCaseList {
  status: string;
}
