import styled from 'styled-components';
import { white } from 'src/styles';

export const SigningRulesContainer = styled.div`
  margin: 0 20px 10px;
  padding: 15px;
  background-color: ${white};
  font-size: 0.8em;
  border-left: ${({ theme }) =>
    `3px solid ${theme.pallete.borders.signingRules}`};
`;
