import { Identity } from 'src/models';
import { action, ActionType } from 'typesafe-actions';
import ActionTypes from './types';

const Actions = {
  getIdentity: () => action(ActionTypes.IDENTITY_GET),
  getIdentityFailure: (props: string) =>
    action(ActionTypes.IDENTITY_GET_FAILURE, props),
  getIdentitySuccess: (props: Identity) =>
    action(ActionTypes.IDENTITY_GET_SUCCESS, props),
};

export type TActions = ActionType<typeof Actions>;
export default Actions;
