import React from 'react';
import { GenericTableRow } from 'src/components/PortalList/PortalList.styles';
import { IMandateLimit } from 'src/models';
import { TableCellTitle, TableTitle } from 'src/components';

interface ISignatoryTableRow {
  limit: IMandateLimit;
}

const SigningRuleTableRow = ({ limit }: ISignatoryTableRow) => {
  return (
    <GenericTableRow>
      <TableCellTitle cursor="default">
        <TableTitle
          title={limit.productArea}
          subtitle={limit.agreement || ''}
        />
      </TableCellTitle>
    </GenericTableRow>
  );
};

export default SigningRuleTableRow;
