import styled from 'styled-components';

import { hexToHSLWithGradient } from 'src/styles/colors';
import { defaultHover, defaultActive } from 'src/styles/effects';
import { Color } from 'src/styles';
import { Link } from 'react-router-dom';

type ColorType = Color | 'success' | 'error';

interface ButtonProps {
  colorType?: ColorType;
  disabled?: boolean;
  fullWidth?: boolean;
  theme: any;
}

const lightGrey = 'rgba(0,0,0,0.1)';

export const getActiveButtonColor = ({ colorType, theme }: ButtonProps) =>
  colorType
    ? colorType === 'secondary'
      ? theme.pallete.action.active
      : theme.pallete.buttons[colorType]
    : theme.pallete.buttons.primary;

export const getButtonColor = (props: ButtonProps) =>
  props.disabled
    ? 'rgba(0,0,0,0.4)'
    : props.colorType === 'secondary'
    ? props.theme.pallete.buttons.secondaryText
    : 'white';

export const getButtonBackground = ({ colorType, theme }: ButtonProps) =>
  colorType && hexToHSLWithGradient(theme.pallete.buttons[colorType]);

export const getBackground = (props: ButtonProps) =>
  props.disabled ? lightGrey : getButtonBackground(props);

export const Button = styled.button<ButtonProps>`
  position: relative;
  border: ${({ colorType }) =>
    colorType === 'secondary' ? `1px solid #ebebeb` : 'none'};
  border-radius: 1.3em;
  font-size: 1em;
  line-height: 1em;
  padding: 0.8em 2em;
  color: ${getButtonColor};
  background: ${getBackground};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  text-align: center;
  transition: none !important;

  ${({ colorType, theme }) =>
    colorType &&
    `
    &:active {
      ${defaultActive(getActiveButtonColor({ colorType, theme }))};
    }

  `}

  &:hover {
    ${({ disabled }) => !disabled && defaultHover};
    color: ${getButtonColor};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
`;

export const LinkStyled = styled(Link)`
  display: inline-block;
`;
