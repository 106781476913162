import { Link } from 'react-router-dom';
import { bluePrimary } from 'src/styles/colors';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const PortalLink = styled(Link)`
  color: ${bluePrimary};

  &:hover {
    color: ${bluePrimary};
  }
`;

export const GridStyled = styled(Grid)`
  padding: 20px;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  line-height: 2rem;
`;
