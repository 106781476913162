import { combineReducers } from 'redux';
import { company } from './Company';
import { mandate } from './CreateMandate';
import {
  reducer as expirationDateReducer,
  isExpirationDateEnabled,
  isExpirationDateFormatValid,
} from './ExpirationDate';
import { productAreas } from './ProductAreas';
import { reducer as requiredSignatoriesReducer } from './RequiredSignatories';
import { fromSignatories, signatories } from './Signatories';

const reducer = combineReducers({
  company,
  createMandate: mandate,
  expirationDate: expirationDateReducer,
  isExpirationDateEnabled,
  isExpirationDateFormatValid,
  productAreas,
  requiredSignatories: requiredSignatoriesReducer,
  signatories,
});

export default reducer;

type State = ReturnType<typeof reducer>;

export const getCompany = (state: State) => state.company;
export const getCreateMandate = (state: State) => state.createMandate;
export const getExpirationDate = (state: State) => state.expirationDate;
export const getisExpirationDateEnabled = (state: State) =>
  state.isExpirationDateEnabled;
export const getisExpirationDateFormatValid = (state: State) =>
  state.isExpirationDateFormatValid;
export const getProductAreas = (state: State) => state.productAreas;
export const getRequiredSignatories = (state: State) =>
  state.requiredSignatories;

export const getSignatories = (state: State) => state.signatories;

export const getValidSignatories = (state: State) =>
  fromSignatories.getValidSignatories(getSignatories(state));

export const areSignatoriesValid = (state: State) =>
  fromSignatories.areSignatoriesValid(getSignatories(state));

export const getSignatoriesFormatted = (state: State) =>
  fromSignatories.getSignatoriesFormatted(getSignatories(state));
