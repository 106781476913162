enum ActionTypes {
  ADD_SIGNATORY = 'Mandates/New/ADD_SIGNATORY',
  ADD_SIGNATORY_SUCCESS = 'Mandates/New/ADD_SIGNATORY_SUCCESS',
  ADD_SIGNATORY_FAILURE = 'Mandates/New/ADD_SIGNATORY_FAILURE',
  REMOVE_SIGNATORY = 'Mandates/New/REMOVE_SIGNATORY',
  UPDATE_SIGNATORY = 'Mandates/New/UPDATE_SIGNATORY',
  VALIDATE_SIGNATORY = 'Mandates/New/VALIDATE_SIGNATORY',
}

export default ActionTypes;
