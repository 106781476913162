import Grid, { GridProps } from '@material-ui/core/Grid';
import React from 'react';
import styled, { css, InterpolationValue } from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 1124px;
  margin: 0 auto;
  padding: 0;
  position: relative;
`;

interface Ibreakpoints {
  mobileSmall: number;
  mobile: number;
  tabletSmall: number;
  tablet: number;
  large: number;
}

/* tslint:disable:object-literal-sort-keys */
export const breakpoints: Ibreakpoints = {
  mobileSmall: 320,
  mobile: 480,
  tabletSmall: 600,
  tablet: 768,
  large: 970,
};

type TMedia = {
  [K in keyof Ibreakpoints]: (styles: string) => string;
};

export const mediaMaxWidth: TMedia = Object.keys(breakpoints).reduce(
  (media, breakpoint) => {
    media[breakpoint] = (styles: string) => {
      return `@media (max-width: ${breakpoints[breakpoint]}px) {
        ${styles}
      }`;
    };

    return media;
  },
  {} as any
);

interface IBreakpoints {
  [breakpoint: string]: string;
}

type TBreakpointGenerator = Record<
  keyof IBreakpoints,
  (styles: TemplateStringsArray, ...placeholders: any[]) => InterpolationValue[]
>;

const getBreakpoint = (
  style: string,
  list: IBreakpoints | Ibreakpoints,
  breakpoint: string
) => `${style}: ${list[breakpoint] || 0}px`;

/**
 * Generate object of functions that accepts styles and generate
 * media query for them.
 */
const getBreakpoints = (b: IBreakpoints | Ibreakpoints, style: string) => {
  return Object.keys(b).reduce(
    (media: any, breakpoint: any) => {
      media[breakpoint] = (styles, ...placeholders) =>
        css`
          @media (${getBreakpoint(style, b, breakpoint)}) {
            ${css(styles, ...placeholders)}
          }
        `;

      return media;
    },
    {} as TBreakpointGenerator
  );
};

/**
 * Improved version of mediaMaxWidth. It accepts TemplateStringsArray instead
 * of string. It is better because styles are passed to css function which
 * preserves functions that are written inside of TemplateStringsArray.
 *
 * Reference: https://www.styled-components.com/docs/api#css
 *
 * Eventually mediaMaxWidth should be deleted.
 */
export const mediaMaxWidth2 = getBreakpoints(breakpoints, 'max-width');
const minWidthBreakpoints = Object.keys(breakpoints).reduce((acc, x) => {
  acc[x] = acc[x] + 1;
  return acc;
}, breakpoints);

export const mediaMinWidth = getBreakpoints(minWidthBreakpoints, 'min-width');
export const defaultTransition = 'all 0.2s ease-out';

interface IGridProps {
  padding?: number;
  screenUp?: 'mobile' | 'tabletSmall' | 'tablet' | 'large';
  screenDown?: 'mobile' | 'tabletSmall' | 'tablet' | 'large';
}

type GridStyledProps = IGridProps & GridProps;

export const GridStyled = styled(
  ({ padding, screenDown, screenUp, ...props }: GridStyledProps) => {
    return <Grid {...props} />;
  }
)<GridStyledProps>`
  padding: ${props => props.padding || 0}px;

  ${props =>
    props.screenUp &&
    mediaMaxWidth2[props.screenUp]`
    display: none !important;
  `}

  ${props =>
    props.screenDown &&
    mediaMinWidth[props.screenDown]`
    display: none !important;
  `}
`;
