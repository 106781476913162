import { AxiosError } from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LoadingScreen } from 'src/components';
import * as fromRoot from 'src/state/rootReducer';
import { fetchUserData } from 'src/state/UserData';
import Onboarding from '../Onboarding';
import {
  Background,
  Cookie,
  ErrorCard,
  Footer,
  Header,
  ProcessingCard,
  SessionTimeout,
} from './components';
import TechnicalError from './TechnicalError';
import { isForbidden } from 'src/utils/Errors';
import { Config } from 'src/state/Config';

interface StateProps {
  customerCreationError: AxiosError | null;
  isUserLoaded: boolean;
  isCreatingCustomer: boolean;
  isCustomerCreated: boolean;
  isLoadingUserData: boolean;
  shouldOnboardCustomer: boolean;
  userDataError: AxiosError | null;
  userId?: string;
}

interface DispatchProps {
  fetchUser: typeof fetchUserData.request;
}

export type Props = StateProps &
  DispatchProps &
  RouteComponentProps<any> &
  WithNamespaces;

interface State {
  isMounted: boolean;
}

export class Layout extends Component<Props, State> {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });

    if (!this.props.userId) {
      this.props.fetchUser();
    }
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  render() {
    const {
      children,
      customerCreationError,
      userDataError,
      userId,
      isUserLoaded,
      isLoadingUserData,
      isCreatingCustomer,
      shouldOnboardCustomer,
      isCustomerCreated,
      t,
    } = this.props;

    if (isLoadingUserData && !isCustomerCreated) {
      return (
        <Background>
          <LoadingScreen text={t('Loading')} data-testid="app-loading-screen" />
        </Background>
      );
    }

    if (!userId && !userDataError && !isCustomerCreated) {
      return <Background />;
    }

    return (
      <>
        <Header showNavigation={isUserLoaded} data-testid="header" />
        <SessionTimeout />
        {this.state.isMounted ? (
          isCreatingCustomer || (isLoadingUserData && isCustomerCreated) ? (
            <ProcessingCard />
          ) : customerCreationError ? (
            <ErrorCard />
          ) : userDataError && !shouldOnboardCustomer ? (
            <TechnicalError
              title={t(getErrorTitle(userDataError))}
              info={t(getErrorInformation(userDataError))}
            />
          ) : shouldOnboardCustomer ? (
            <Onboarding />
          ) : (
            children
          )
        ) : null}
        <Footer
          hasTwoPhoneNumbers={hasTwoPhoneNumbers()}
          userId={userId}
          data-testid="footer"
        />
        <Cookie />
      </>
    );
  }
}

export const getErrorTitle = (error: AxiosError) =>
  error
    ? isForbidden(error)
      ? 'UnauthorisedAccess'
      : 'InternalServerError'
    : '';

export const hasTwoPhoneNumbers = () =>
  Config.isFinlandSite() ||
  Config.isSwedishSite() ||
  Config.isNorwaySite() ||
  Config.isRealkreditDenmark();

export const getErrorInformation = (error: AxiosError) =>
  error && !isForbidden(error) ? 'TechnicalError' : '';

export default withRouter(
  connect(
    state => ({
      customerCreationError: fromRoot.getCustomerCreationError(state),
      isCreatingCustomer: fromRoot.isCreatingCustomer(state),
      isCustomerCreated: fromRoot.isCustomerCreated(state),
      isLoadingUserData: fromRoot.getIsUserDataLoading(state),
      isUserLoaded: fromRoot.getIsUserLoaded(state),
      userDataError: fromRoot.getUserDataError(state),
      shouldOnboardCustomer: fromRoot.getShouldOnboardCustomer(state),
      userId: fromRoot.getUserId(state),
    }),
    {
      fetchUser: fetchUserData.request,
    }
  )(withNamespaces()(Layout))
);
