import { denormalize } from 'normalizr';
import { TRootState } from 'src/state/types';
import { IData } from './models';
import * as selectors from './selectors';
import { Entity } from './services';
import { ISigningCase } from 'src/models';
import * as signingCaseUtils from 'src/utils/SigningCase';

export const getSigningCaseDetails = (state: TRootState) =>
  state.signingCaseDetails;

export const getContractingParty = (
  state: TRootState,
  contractingPartyId: string
) => {
  const signingCaseDetails = selectors.getSigningCaseDetailsData(state);
  const contractingParty = selectors.getSigningCaseEntry(
    state,
    'contractingParties',
    contractingPartyId
  );

  return denormalize(
    contractingParty,
    Entity.mainContractingParty,
    signingCaseDetails
  );
};

export const getOtherContractingParties = (state: TRootState, id: string) => {
  const signingCase = selectors.getSigningCase(state, id).data;
  return signingCase && signingCase.otherContractingParties;
};

export const getIsLoading = (state: TRootState): boolean =>
  getSigningCaseDetails(state).isLoading;

export const getSigningCaseDetailsData = (state: TRootState) =>
  selectors.getSigningCaseDetails(state).data;

type Entities = keyof IData;
export const getSigningCaseEntity = (state: TRootState, prop: Entities) =>
  (selectors.getSigningCaseDetailsData(state) || {})[prop];

export const getSigningCaseEntry = (
  state: TRootState,
  prop: Entities,
  id: string
) => (getSigningCaseEntity(state, prop) || {})[id];

export const getSigningCase = (state: TRootState, id: string) => {
  return {
    data: selectors.getSigningCaseData(state, id),
    isLoading: selectors.getIsLoading(state),
  };
};

export const getSigningCaseData = (state: TRootState, id: string) => {
  const signingCaseDetails = selectors.getSigningCaseDetailsData(state);
  const signingCase = selectors.getSigningCaseEntry(state, 'signingCases', id);

  return denormalize(
    signingCase,
    Entity.signingCase,
    signingCaseDetails
  ) as ISigningCase;
};

export const isSigningCaseReadyToSign = (state: TRootState, id: string) => {
  const signingCase = getSigningCaseData(state, id);
  return signingCase
    ? signingCaseUtils.isSigningCaseReadyToSign(signingCase.status)
    : false;
};

export const getSignatures = (state: TRootState, id: string) => {
  const signingCase = selectors.getSigningCase(state, id).data;
  return (
    signingCase &&
    signingCase.mainContractingParty &&
    signingCase.mainContractingParty.signatures
  );
};
