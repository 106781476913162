import React from 'react';
import { t } from 'i18next';

interface IDocumentsListInfoText {
  className?: string;
}

export const DocumentsListInfoText = ({
  className,
}: IDocumentsListInfoText) => {
  return (
    <span
      aria-label={t('YouMustReviewAllDocumentsBeforeSigning')}
      className={className}
    >
      {t('YouMustReviewAllDocumentsBeforeSigning')}
    </span>
  );
};
