import axios from 'axios';
import { ApiTypes } from 'src/models';
import { Config } from 'src/state/Config';
import { queryParameters } from '../constants';

const getSigningMandateUrl = () => `${Config.host}/SigningMandate`;

export const fetchMandate: ApiTypes.GetSigningMandate = async id =>
  axios.get(`${getSigningMandateUrl()}/${id}`, {
    params: {
      [queryParameters.languageCode]: Config.getLanguageCode(),
    },
  });

export const fetchMandates: ApiTypes.GetSigningMandates = async () =>
  axios.get(getSigningMandateUrl(), {
    params: {
      [queryParameters.languageCode]: Config.getLanguageCode(),
    },
  });

export const createMandate: ApiTypes.CreateMandate = async props =>
  axios.post(getSigningMandateUrl(), props);

export const revokeMandate = async ({ mandateId }) =>
  axios.post(`${getSigningMandateUrl()}/RevokeMandate`, {
    mandateId,
  });
