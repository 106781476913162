import { Component } from 'react';
import { canLoad } from './services';

interface IInfiniteScroll extends DefaultProps {
  hasNextPage: boolean;
  loadNextPage: () => void;
}

const defaultProps = {
  isLoading: false,
  treshold: 250,
};

type DefaultProps = Readonly<typeof defaultProps>;

class InfiniteList extends Component<IInfiniteScroll> {
  static defaultProps: DefaultProps = defaultProps;

  componentDidMount() {
    window.addEventListener('scroll', this.loadNext);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => undefined);
  }

  render() {
    const { children } = this.props;

    return children;
  }

  private loadNext = () => {
    const { isLoading, hasNextPage, treshold } = this.props;

    if (canLoad(isLoading, hasNextPage, treshold)) {
      this.props.loadNextPage();
    }
  };
}

export default InfiniteList;
