import axios, { AxiosPromise } from 'axios';
import { Config } from 'src/state/Config';
import { queryParameters } from '../constants';
import { UserData } from '../../../UserData';

const getAuthenticationApi = () => `${Config.host}/Authentication`;

type LogOut = () => AxiosPromise<string>;
export const logOut: LogOut = async () =>
  axios.put(`${getAuthenticationApi()}/LogOff`, null, {
    params: {
      [queryParameters.languageCode]: Config.getLanguageCode(),
    },
  });

type GetCurrentUser = () => AxiosPromise<UserData>;
export const getCurrentUser: GetCurrentUser = async () => {
  return axios.get(
    `${getAuthenticationApi()}/GetCurrentUser?hideIdPart=${!Config.isUkSite()}`
  );
};

type RefreshSession = () => AxiosPromise;
export const refreshSession: RefreshSession = async () => {
  return axios.put(`${getAuthenticationApi()}/RefreshSession`);
};
