import React, { HTMLAttributes, useEffect, useState } from 'react';
import { ISigningCase } from 'src/models';
import ContractingPartyStatus from '../ContractingPartyStatus';
import {
  HeaderContainer,
  SigningCaseBorder,
  SigningCaseTitle,
  StatusContainer,
  Tag,
  TitleContainer,
} from './SigningCaseHeader.styles';
import { Subtitle as CardSubtitle } from 'src/components/Card/Title';
import { getTextFromAgreementStatus } from 'src/utils/getTextFromAgreementStatus';
import { getSigningCaseHeaderColor } from 'src/utils/getSigningCaseHeaderColor';

interface Props extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subTitle: string;
  status: ISigningCase['status'];
  deadline: string;
}

const SigningCaseHeader = (props: Props) => {
  const { title, subTitle, status, deadline, ...rest } = props;
  const [caseStatus, setCaseStatus] = useState(status);

  useEffect(() => {
    setCaseStatus(status);
  }, [status]);

  return (
    <SigningCaseBorder status={status} {...rest}>
      <HeaderContainer>
        <Tag
          color={getSigningCaseHeaderColor(status)}
          date={deadline}
          status={status}
          data-testid="signing-case-tag"
        />
        <TitleContainer>
          <SigningCaseTitle title={title}>{title}</SigningCaseTitle>
          <CardSubtitle title={subTitle}>{subTitle}</CardSubtitle>
        </TitleContainer>
        <StatusContainer>
          <ContractingPartyStatus
            status={status}
            displayedStatusText={getTextFromAgreementStatus(caseStatus)}
          />
        </StatusContainer>
      </HeaderContainer>
    </SigningCaseBorder>
  );
};

export default SigningCaseHeader;
