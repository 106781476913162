//@ts-nocheck
import React from 'react';
import { withRouter } from 'react-router-dom';
import { ReturnButton } from 'src/components/Buttons';
import { SigningDocumentCover } from 'src/components/Cover';
import { SigneesCard } from 'src/components/PortalList/PortalSigneesCard';
import { SigningCaseDetails } from 'src/components/PortalList/PortalSigningCaseDetails';
import { getSigningCaseListPath } from './utils';

const Case = props => {
  const { match } = props;

  return (
    <>
      <SigningDocumentCover />
      <SigningCaseDetails />
      <SigneesCard />
      <ReturnButton
        linkProps={{
          to: getSigningCaseListPath(match.path),
        }}
      />
    </>
  );
};

export default withRouter(Case);
