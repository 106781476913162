import styled from 'styled-components';

export const Subtitle = styled.span`
  display: block;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8em;
  margin-top: 0.2em;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const GenericTableRow = styled(TableRow)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
