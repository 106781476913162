import { ActionType, isActionOf } from 'typesafe-actions';
import { AsyncAction } from '../models';

export const isLoading = (actions: AsyncAction) => (
  state: boolean = false,
  action: ActionType<any>
) => {
  if (
    isActionOf(actions.failure, action) ||
    isActionOf(actions.success, action)
  ) {
    return false;
  } else if (isActionOf(actions.request, action)) {
    return true;
  }

  return state;
};
