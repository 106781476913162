import { schema } from 'normalizr';
import { ISignatureResponse } from 'src/models';

const signature = new schema.Entity(
  'signatures',
  {},
  {
    idAttribute: (signature: ISignatureResponse) => signature.externalId,
  }
);

const otherContractingParty = new schema.Entity('otherContractingParties', {
  signatures: [signature],
});

const readBy = new schema.Entity(
  'documentReads',
  {},
  {
    idAttribute: 'personId',
  }
);

const document = new schema.Entity('documents', {
  readBy: [readBy],
});

export const mainContractingParty = new schema.Entity('contractingParties', {
  documents: [document],
  otherContractingParties: [otherContractingParty],
  signatures: [signature],
});

export const signingCase = new schema.Entity('signingCases', {
  mainContractingParty,
});
