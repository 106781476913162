import { Grid } from '@material-ui/core';
import React, { Component, HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import { PortalButton } from 'src/components/Buttons';
import { Card, CardBorderTop } from 'src/components';
import { SigningDocumentCover } from 'src/components/Cover';
import { Subtitle, Title, Link } from 'src/components/Text';
import { CountryOptions } from 'src/pages/Onboarding/models';
import { GridStyled } from 'src/styles/layout';
import { createCustomer } from '../../state/CustomerCreation';
import { CountryInput } from './components';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';

interface Props extends HTMLAttributes<HTMLDivElement>, WithNamespaces {
  createCustomer: typeof createCustomer.request;
}

type State = Readonly<{
  countries: CountryOptions;
}>;
const initialState: State = {
  countries: [],
};

class Onboarding extends Component<Props, State> {
  state = initialState;

  render() {
    const padding = 8;
    const { t } = this.props;

    return (
      <div data-testid="onboarding">
        <SigningDocumentCover />
        <Card popout={true} showNotifications={true}>
          <CardBorderTop />
          <GridStyled container={true} justify="center" padding={8}>
            <GridStyled item={true} xs={12} padding={24}>
              <Title>{t('Citizenship')}</Title>
            </GridStyled>
            <GridStyled item={true} xs={12} padding={padding}>
              <CountryInput
                value={this.state.countries}
                onChange={this.onChange}
              />
            </GridStyled>
            <GridStyled
              container={true}
              item={true}
              justify="flex-end"
              padding={padding}
            >
              <Grid item={true} xs={12} md="auto">
                <PortalButton
                  onClick={this.onContinue}
                  disabled={!this.canContinue()}
                  fullWidth={true}
                  data-testid="onboarding-continue-btn"
                >
                  {t('Continue')}
                </PortalButton>
              </Grid>
            </GridStyled>
            <GridStyled item={true} xs={12} padding={padding}>
              <Subtitle fontStyle="italic">
                <Trans
                  i18nKey="UserCitizenshipAknowledgement"
                  components={[
                    <Link
                      href={t('PrivacyNoticePrivateLink')}
                      target="_blank"
                      rel="noreferrer noopener"
                      colorType="secondary"
                    >
                      Text
                    </Link>,
                    <Link
                      href={t('PrivacyNoticeBusinessLink')}
                      target="_blank"
                      rel="noreferrer noopener"
                      colorType="secondary"
                    >
                      Text
                    </Link>,
                  ]}
                />
              </Subtitle>
            </GridStyled>
          </GridStyled>
        </Card>
      </div>
    );
  }

  private onContinue = () => {
    this.props.createCustomer(this.state.countries.map(c => c.value));
  };

  private onChange = (c: CountryOptions) => {
    this.setState(setCountries(c));
  };

  private canContinue = () => {
    return this.state.countries.length > 0;
  };
}

const setCountries = (selectedCountries: CountryOptions) => (state: State) => ({
  ...state,
  countries: selectedCountries,
});

const ExtendedComponent = connect(
  null,
  {
    createCustomer: createCustomer.request,
  }
)(withNamespaces()(Onboarding));

export { ExtendedComponent as Onboarding };
