import { CreateMandate } from 'src/state/Mandates/New/CreateMandate';
import { SELECT } from './actions';

const initialState = '';

const reducer = (state: string = initialState, action: any) => {
  if (action.type === SELECT) {
    return action.payload;
  } else if (action.type === CreateMandate.REQUEST) {
    return initialState;
  }

  return state;
};

export default reducer;
