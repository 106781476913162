import React from 'react';
import { ISigningCaseListItem } from 'src/models';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import { IconStyled } from './styles';

export interface IAgreementStatusIcon
  extends React.HTMLAttributes<HTMLElement> {
  status: ISigningCaseListItem['status'];
  displayedStatusText?: any;
}

const AgreementStatusIcon = (props: IAgreementStatusIcon) => {
  const { status, ...rest } = props;

  const getIconProps = (statusProp: ISigningCaseListItem['status']) => {
    const statusIcon = {
      readyToSign: { type: 'warning' },
      waiting: { type: 'waiting' },
      signed: { type: 'ok' },
      rejected: { type: 'delete' },
    };
    const statusFunction = {
      readyToSign: isSigningCaseReadyToSign,
      waiting: isSigningCaseWaiting,
      signed: isSigningCaseSigned,
      rejected: isSigningCaseRejected,
    };
    const key = Object.keys(statusFunction).find(k =>
      statusFunction[k](statusProp)
    );

    // @ts-ignore
    return statusIcon[key] || null;
  };
  const iconProps = getIconProps(status);

  return iconProps ? (
    <IconStyled type={iconProps.type} aria-label="" {...rest} />
  ) : null;
};

export default AgreementStatusIcon;
