import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { Card } from '../../../Card';
import { IframeModal } from 'src/components/Modals';
import { CardLoader } from 'src/components';
import { DocumentListActions } from 'src/components/PortalList/PortalDocumentsList';
import { IDocumentResponse, TSigningCaseDetailsRouteProps } from 'src/models';
import { notificationsManager } from 'src/state/Notifications';
import {
  CreateSignature,
  IsSignatureSuccessful,
  RejectSigningCase,
} from 'src/state/Signing';
import { getReject, isRejecting } from 'src/state/Signing/Reject/selectors';
import { Actions as SigningCaseDetailsActions } from 'src/state/SigningCaseDetails';
import {
  getContractingParty,
  getSigningCase,
} from 'src/state/SigningCaseDetails/selectors';
import { getOpenedSigningCaseListItem } from 'src/state/SigningCasesList/selectors';
import {
  isSigningCaseReadyToSign,
  isSigningCaseUpdating,
} from 'src/utils/SigningCase';
import { SigningCaseHeader } from './components';
import {
  isRejected,
  isSignatureSuccessful,
  getNextTimeout,
  openDocumentContent,
} from './services';
import * as fromRoot from 'src/state/rootReducer';
import { getDocumentContentUrl } from 'src/state/api';
import RejectDialog from 'src/components/Modals/RejectDialog/RejectDialog';
import { DocumentsList } from 'src/components/DocumentsList/DocumentsList';
import { DocumentsListItem } from 'src/components/DocumentsList';

interface ISigningCaseDetails extends TSigningCaseDetailsRouteProps {
  rejectActions: typeof RejectSigningCase.Actions;
  signActions: typeof IsSignatureSuccessful.Actions;
  signingCaseDetailsActions: typeof SigningCaseDetailsActions;
  signatureActions: typeof CreateSignature.Actions;
  notifications: typeof notificationsManager;
  contractingParty: ReturnType<typeof getContractingParty>;
  isSignatureSuccessful: ReturnType<typeof fromRoot.getIsSignatureSuccessful>;
  signature: ReturnType<typeof fromRoot.getSignature>;
  rejected: ReturnType<typeof getReject>;
  isRejecting: ReturnType<typeof isRejecting>;
  signingCaseListItem: ReturnType<typeof getOpenedSigningCaseListItem>;
  signingCase: ReturnType<typeof getSigningCase>;
  isSignatureCreated: boolean;
  canSign: ReturnType<typeof fromRoot.getCanSign>;
}

const state = {
  rejectModalOpened: false,
  showSignModal: false,
  timeout: 1000,
};

type State = Readonly<typeof state> &
  Readonly<{
    timeoutId?: number;
  }>;

class SigningCaseDetails extends Component<ISigningCaseDetails, State> {
  readonly state: State = state;

  componentDidMount() {
    this.fetchSigningCase();
  }

  componentWillUnmount() {
    this.props.signingCaseDetailsActions.resetOpenedSigningCase();
    this.state.timeoutId && clearTimeout(this.state.timeoutId);
  }

  componentDidUpdate(prevProps: ISigningCaseDetails) {
    const signed = isSignatureSuccessful(
      prevProps.isSignatureSuccessful.data,
      this.props.isSignatureSuccessful.data
    );

    const rejected = isRejected(
      prevProps.rejected.data,
      this.props.rejected.data
    );

    const updating =
      prevProps.signingCase.isLoading &&
      !this.props.signingCase.isLoading &&
      isSigningCaseUpdating((this.props.signingCase.data || {}).status);

    if (signed || rejected) {
      this.fetchSigningCase();
    } else if (updating) {
      this.pollSigningCase();
    }
  }

  render() {
    const signingCase = this.props.signingCase.data;
    const { contractingParty, signature, signingCaseListItem } = this.props;

    const showSpinner =
      (this.isLoading() && !signingCase && !signingCaseListItem) ||
      this.props.isRejecting;

    if (showSpinner) {
      return (
        <Card popout={true}>
          <CardLoader data-testid="signing-case-loader" />
        </Card>
      );
    }

    if ((!signingCase || !contractingParty) && !signingCaseListItem) {
      return <Card popout={true} showNotifications={true} />;
    }

    const title = signingCase ? signingCase.name : signingCaseListItem.name;
    const subTitle = contractingParty
      ? contractingParty.name
      : signingCaseListItem.contractingPartyName;
    const status = signingCase
      ? signingCase.status
      : signingCaseListItem.status;
    const deadline = signingCase
      ? moment(signingCase.date).format('L')
      : signingCaseListItem.date;

    return (
      <Card popout={true} showNotifications={true}>
        <SigningCaseHeader
          title={title}
          subTitle={subTitle}
          status={status}
          deadline={deadline}
          data-testid="signing-case-header"
        />
        {this.renderContent()}
        {this.state.rejectModalOpened ? (
          <RejectDialog
            onConfirm={this.reject}
            onCancel={this.toggleRejectModal}
            data-testid="dialog-modal"
          />
        ) : null}
        {this.doShowSignModal() && (
          <IframeModal
            id={signature.data.id}
            url={signature.data.url}
            onSign={this.signDocument}
            onCancel={this.closeSignModal}
          />
        )}
      </Card>
    );
  }

  private doShowSignModal = () =>
    this.props.isSignatureCreated && this.state.showSignModal;

  private renderContent = () => {
    const { signingCase, contractingParty, signingCaseListItem } = this.props;

    if (this.isLoading()) {
      return <CardLoader />;
    }

    const status =
      signingCase && signingCase.data
        ? signingCase.data.status
        : signingCaseListItem && signingCaseListItem.status;

    if (!(status && contractingParty)) {
      return null;
    }

    return (
      <>
        <DocumentsList>
          {contractingParty.documents.map((document, index) => (
            <DocumentsListItem
              key={index}
              document={document}
              status={signingCase.data.status}
              onClick={() => this.openDocument(document)}
              onKeyDown={e => {
                if (e.keyCode === 13 || e.keyCode === 32) {
                  this.openDocument(document);
                }
              }}
              data-testid="document-list-item"
              tabIndex={0}
              downloadLink={getDocumentContentUrl(
                signingCase.data.id,
                contractingParty.id,
                document.id,
                document.mimeType,
                true
              )}
            />
          ))}
        </DocumentsList>
        {this.renderMenu(status)}
      </>
    );
  };

  private renderMenu = (status: any) => {
    if (isSigningCaseReadyToSign(status)) {
      return (
        <DocumentListActions
          canSign={this.props.canSign}
          onReject={this.toggleRejectModal}
          onSign={this.createSignature}
          data-testid="signing-case-menu"
        />
      );
    }

    return null;
  };

  private openDocument = (document: IDocumentResponse) => {
    openDocumentContent(
      this.props.signingCase.data.id,
      this.props.contractingParty.id,
      document.id,
      document.mimeType
    );

    if (!document.isRead) {
      this.props.signingCaseDetailsActions.documentMarkRead({
        documentId: document.id,
      });
    }
  };

  private createSignature = () => {
    if (!this.props.signature.isLoading) {
      const { signatureActions } = this.props;
      const signingCase = this.props.signingCase.data;

      this.openSignModal();
      signatureActions.createSignature({
        contractingPartyId: signingCase!.mainContractingParty.id,
        signingCaseId: signingCase!.id,
      });
    }
  };

  private signDocument = () => {
    if (this.props.signature.data && this.props.signature.data.id) {
      const signingCase = this.props.signingCase.data;

      this.props.signActions.signDocument({
        contractingPartyId: signingCase.mainContractingParty.id,
        signatureId: this.props.signature.data.id,
        signingCaseId: signingCase.id,
      });
    }
  };

  private toggleRejectModal = () => this.setState(toggleRejectModal);

  private closeSignModal = () => this.setState(toggleSignModal(false));

  private openSignModal = () => this.setState(toggleSignModal(true));

  private fetchSigningCase = () => {
    const { signingCaseDetailsActions, match } = this.props;
    signingCaseDetailsActions.fetchSigningCase(match.params);
  };

  private reject = () => {
    const { caseId, partyId } = this.props.match.params;

    this.toggleRejectModal();
    this.props.rejectActions.reject({
      contractingPartyId: partyId,
      signingCaseId: caseId,
    });
  };

  private isLoading = () => this.props.signingCase.isLoading;

  private pollSigningCase = () => {
    const timeoutId = setTimeout(
      this.fetchSigningCase.bind(this),
      this.state.timeout
    );
    this.setState(state => ({
      timeoutId,
      timeout: getNextTimeout(state.timeout),
    }));
  };
}

const toggleSignModal = (show?: boolean) => (prevState: State) => ({
  ...prevState,
  showSignModal: show || !prevState.showSignModal,
});

const toggleRejectModal = (state: State) => ({
  ...state,
  rejectModalOpened: !state.rejectModalOpened,
});

const mapStateToProps = (state, props: TSigningCaseDetailsRouteProps) => ({
  canSign: fromRoot.getCanSign(state, props.match.params.partyId),
  contractingParty: getContractingParty(state, props.match.params.partyId),
  isSignatureSuccessful: fromRoot.getIsSignatureSuccessful(state),
  rejected: getReject(state),
  isRejecting: isRejecting(state),
  signature: fromRoot.getSignature(state),
  signingCase: getSigningCase(state, props.match.params.caseId),
  signingCaseListItem: getOpenedSigningCaseListItem(state),
  isSignatureCreated: fromRoot.getIsSignatureCreated(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getOpenedSigningCaseListItem: bindActionCreators(
    getOpenedSigningCaseListItem,
    dispatch
  ),
  notifications: bindActionCreators(notificationsManager, dispatch),
  rejectActions: bindActionCreators(RejectSigningCase.Actions, dispatch),
  signActions: bindActionCreators(IsSignatureSuccessful.Actions, dispatch),
  signatureActions: bindActionCreators(CreateSignature.Actions, dispatch),
  signingCaseDetailsActions: bindActionCreators(
    SigningCaseDetailsActions,
    dispatch
  ),
});

const ExtendedComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SigningCaseDetails)
);

export { ExtendedComponent as SigningCaseDetails };

export default SigningCaseDetails;
