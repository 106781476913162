import Grid from '@material-ui/core/Grid';
import { PortalButton } from 'src/components/Buttons';
import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';
import { darkRedError } from 'src/styles';

export const Button = styled(PortalButton)`
  ${mediaMaxWidth2.tabletSmall`
    width: 100%;
  `};
`;

export const Container = styled(Grid)`
  margin-bottom: 4px !important;
`;

export const GridInput = styled(Grid)`
  position: relative;
`;

export const GridBottom = styled(Grid)`
  align-self: flex-end;
  text-align: center;
`;

export const Text = styled.span`
  bottom: -1px;
  color: ${darkRedError};
  font-size: 12px;
  position: relative;
`;
