import { ApiTypes } from 'src/models';
import { action, ActionType } from 'typesafe-actions';
import ActionTypes from './types';

const Actions = {
  createSignature: (params: ApiTypes.ICreateSignature) =>
    action(ActionTypes.CREATE_SIGNATURE, params),
  createSignatureFailure: () => action(ActionTypes.CREATE_SIGNATURE_FAILURE),
  createSignatureRequest: () => action(ActionTypes.CREATE_SIGNATURE_REQUEST),
  createSignatureSuccess: (params: ApiTypes.ISignature) =>
    action(ActionTypes.CREATE_SIGNATURE_SUCCESS, params),
};

export type TActions = ActionType<typeof Actions>;
export default Actions;
