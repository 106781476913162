import { Grid } from '@material-ui/core';
import { Logo } from './components';
import { defaultTransition, mediaMaxWidth } from 'src/styles/layout';
import styled from 'styled-components';

export const AppBar = styled.header`
  display: flex;
  width: 100%;
  max-height: 54px;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px 2px 4px 0px rgba(57, 67, 68, 0.2);
  background-color: ${({ theme }) => theme.pallete.backgrounds.header};
`;

export const Navigation = styled(Grid)`
  list-style: none;
`;

export const LogoStyled = styled(Logo)`
  margin: 0 20px 0 15px;
  width: 160px;
  height: 54px;

  ${mediaMaxWidth.large(`
    transition: ${defaultTransition};
    margin: 0 0 0 10px;
  `)}
`;

export const Logout = styled(Grid)`
  font-size: 0.9em;
`;
