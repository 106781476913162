//@ts-nocheck
import { curryRight, flow } from 'lodash';
import { INewSignatory } from 'src/pages/Mandates/models';
import {
  CreateMandateType,
  CreateMandate,
} from 'src/state/Mandates/New/CreateMandate';
import { Signatories } from './actions';
import {
  isSignatoryValid,
  removeDuplicates,
  updateBySignatoryId,
  updateSignatory,
} from './services';
import ActionTypes from './types';
import { isOfType } from 'typesafe-actions';

const initialState: INewSignatory[] = [];
type State = INewSignatory[];

const signatories = (
  state: State = initialState,
  action: Signatories & CreateMandateType
) => {
  if (isOfType(ActionTypes.ADD_SIGNATORY, action)) {
    return state.concat({
      ...action.payload,
      error: '',
      isLoading: true,
    });
  } else if (
    isOfType(ActionTypes.ADD_SIGNATORY_SUCCESS, action) ||
    isOfType(ActionTypes.ADD_SIGNATORY_FAILURE, action)
  ) {
    if (isSignatoryValid(action.payload)) {
      const updateById = curryRight(updateBySignatoryId)(action.payload);
      return flow([updateById, removeDuplicates])(state) as State;
    }

    return updateSignatory(state, action.payload);
  } else if (isOfType(ActionTypes.REMOVE_SIGNATORY, action)) {
    return state.filter((_, i) => i !== action.payload);
  } else if (isOfType(ActionTypes.UPDATE_SIGNATORY, action)) {
    const { id, signatory: newSignatory } = action.payload;

    return state.map((signatory, i) => (id === i ? newSignatory : signatory));
  } else if (isOfType(ActionTypes.VALIDATE_SIGNATORY, action)) {
    const { externalId } = action.payload;

    return state.map(s =>
      s.externalId === externalId ? { ...s, isLoading: true } : s
    );
  } else if (isOfType(CreateMandate.REQUEST, action)) {
    return initialState;
  }

  return state;
};

export default signatories;

export const getValidSignatories = (state: State) =>
  state.filter(s => isSignatoryValid(s));

export const areSignatoriesValid = (state: State) =>
  state.length > 0 && state.every(s => isSignatoryValid(s));

export const getSignatoriesFormatted = (state: State) =>
  state.map(signatory => `${signatory.fullName} [${signatory.externalId}]`);
