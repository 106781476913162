import { call, put, takeLatest } from 'redux-saga/effects';
import { getIdentity } from '../api';
import { notificationsManager } from '../Notifications';
import identity from './actions';
import ActionTypes from './types';

function* getIdentitySaga() {
  try {
    const identityResp = yield call(getIdentity);
    yield put(identity.getIdentitySuccess(identityResp.data));
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
    yield put(identity.getIdentityFailure(e));
  }
}

export function* watchGetIdentity() {
  yield takeLatest(ActionTypes.IDENTITY_GET, getIdentitySaga);
}
