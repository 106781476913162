import React from 'react';
import { Icon } from 'src/components/Icons';
import { ISignee } from './ISignee';
import { IconContainer } from './PortalSigneesList.styles';
import { t } from 'i18next';

interface ISigneeActionIcon {
  actionRole: ISignee['actionRole'];
}

const SigneeActionIcon = ({ actionRole }: ISigneeActionIcon) => {
  const icon = {
    Signee: { type: 'ok', label: t('Ok') },
    Rejector: { type: 'delete', label: t('Close') },
  };
  //@ts-ignore
  const iconProps = icon[actionRole];

  return (
    <IconContainer>
      <Icon type={iconProps.type} aria-label={iconProps.label} />
    </IconContainer>
  );
};

export default SigneeActionIcon;
