import { createAsyncAction } from 'typesafe-actions';
import { UserData } from './models';

const prefix = 'FETCH_USER_DATA';

export const fetchUserData = createAsyncAction(
  `${prefix}_REQUEST`,
  `${prefix}_SUCCESS`,
  `${prefix}_FAILURE`
)<undefined, UserData, Error>();
