import React, { ComponentProps, useState } from 'react';
import { ISigneesList } from './ISigneesList';
import SigneesListBody from './SigneesListBody';
import SigneesListFrame from './SigneesListFrame';
import Button from '../../Buttons/Button';
import styled from 'styled-components';

const SignessList = ({
  signatories,
  signatures,
  ...rest
}: ISigneesList & ComponentProps<typeof SigneesListFrame>) => {
  const visibleListItemsNumber = 5;
  const [visibleList, setVisibleList] = useState(visibleListItemsNumber);
  const displayedButtonText =
    visibleList === visibleListItemsNumber
      ? 'Show more items'
      : 'See less items';
  const signatoriesItems = signatories.slice(0, visibleList).map(item => item);
  const toggleItems = () =>
    visibleList === visibleListItemsNumber
      ? setVisibleList(signatories.length)
      : setVisibleList(visibleListItemsNumber);

  if (signatories && signatories.length) {
    return (
      <>
        <SigneesListFrame
          {...rest}
          render={() => (
            <>
              <SigneesListBody
                signatures={signatures}
                signatories={signatoriesItems}
              />
            </>
          )}
        />
        {signatories.length > visibleListItemsNumber ? (
          <ButtonShowMore onClick={toggleItems}>
            {displayedButtonText}
          </ButtonShowMore>
        ) : null}
      </>
    );
  } else {
    return null;
  }
};

export default SignessList;

export const ButtonShowMore = styled(Button)`
  display: flex;
  margin: 1rem auto;
  border: 1px solid #ebebeb;
  border-radius: 1.3em;
  font-size: 1em;
  padding: 0.8em 3em;
  color: #002337;
  background: linear-gradient(
    to bottom,
    hsla(200, 60%, 103%, 1) 0%,
    hsla(200, 58%, 99%, 1) 100%
  );

  &:hover {
    box-shadow: 2px 4px 8px -6px darkgrey;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: 600px) {
    width: 90%;
    justify-content: center;
  }
`;
