import axios from 'axios';
import { ApiTypes } from 'src/models';
import { Config } from 'src/state/Config';

const getDocumentUrl = () => `${Config.host}/Document`;

export const getDocumentContentUrl = (
  signingCaseId: string,
  contractingPartyId: string,
  documentId: string,
  format: string,
  downloadable: boolean = false
) =>
  `${Config.host}/Document/Content?id=${signingCaseId}&contractingPartyId=${contractingPartyId}&` +
  `documentId=${documentId}&format=${format}&downloadable=${downloadable}`;

export const getContent: ApiTypes.GetDocumentContent = async props =>
  axios.get(`${getDocumentUrl()}/Content`, {
    params: props,
  });

export const markRead: ApiTypes.MarkDocumentRead = async props =>
  axios.put(
    getDocumentUrl(),
    {},
    {
      params: props,
    }
  );
