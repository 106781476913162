import React, { Component, lazy, Suspense, FC } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { Archive, Case, Mandates, ForSigning, MandateDetails } from './pages';
import * as routes from './routes';
import { Config } from './state/Config';
import { notificationsManager } from './state/Notifications';

interface IRootSwitch extends RouteComponentProps<any> {
  clearNotifications: typeof notificationsManager.clearNotifications;
}

const getLanguageCodePath = () => {
  const languageCodePattern: string = (Config.getSupportedLanguageCodes() as any).reduce(
    (pattern: string, language: string) =>
      pattern.length ? `${pattern}|${language}` : language,
    ''
  );

  return `:languageCode(${languageCodePattern})`;
};

const addLanguageCodePath = (url: string) => `/${getLanguageCodePath()}${url}`;

interface RouteParams {
  languageCode: string;
}
const RootRoutes: FC<RouteComponentProps<RouteParams>> = () => {
  return (
    <Switch>
      <Route
        path={addLanguageCodePath(routes.home)}
        component={ForSigning}
        exact
      />
      <Route
        path={addLanguageCodePath(routes.archive)}
        component={Archive}
        exact
      />
      <Route
        path={[
          addLanguageCodePath(`${routes.archive}${routes.signingCaseView}`),
          addLanguageCodePath(routes.signingCaseView),
        ]}
        component={Case}
      />
      <Route
        path={addLanguageCodePath(routes.mandates)}
        component={Mandates}
        exact
      />
      {renderNewMandate()}
      <Route
        path={addLanguageCodePath(routes.mandate)}
        component={MandateDetails}
        exact
      />
      <Route
        path={addLanguageCodePath(routes.onboarding)}
        component={OnboardingAsync}
        exact
      />
    </Switch>
  );
};

export class RootSwitch extends Component<IRootSwitch> {
  componentDidUpdate(prevProps: IRootSwitch) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.clearNotifications();
    }
  }

  render() {
    const path = this.props.location.pathname;

    return (
      <Suspense fallback={null}>
        <Switch>
          <Route
            sensitive
            path={`/${getLanguageCodePath()}`}
            component={RootRoutes}
          />
          <Redirect from="*" to={`/${Config.getLanguageCode()}${path}`} />
        </Switch>
      </Suspense>
    );
  }
}

const OnboardingAsync = lazy(() =>
  import(
    /* webpackChunkName: "Onboarding" */
    'src/pages/Onboarding'
  )
);

export const renderNewMandate = () => {
  if (Config.isUkSite()) {
    return null;
  }

  const NewMandateLazy = lazy(() =>
    import(
      /* webpackChunkName: "NewMandateForm" */
      'src/pages/Mandates/pages/New/New'
    )
  );

  return (
    <Route
      path={addLanguageCodePath(routes.newMandate)}
      component={NewMandateLazy}
      exact
    />
  );
};

const connected = connect(
  null,
  {
    clearNotifications: notificationsManager.clearNotifications,
  }
)(RootSwitch);

export default withRouter(connected);
