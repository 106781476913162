import { buttonFocusStyles } from '../FilterButton';
import styled from 'styled-components';
import { TimeframeButtonProps } from './interfaces';

export const TimeframeButton = styled.button<TimeframeButtonProps>`
  display: inline-block;
  height: 30px;
  font-size: 0.8em;
  padding: 0.5em 1em;
  border-radius: 15px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.pallete.borders.filter : 'transparent'};
  color: ${({ theme }) => theme.pallete.borders.filter};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    ${buttonFocusStyles}
  }
`;
