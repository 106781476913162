import React from 'react';
import { IconStyled } from 'src/pages/Mandates/components';
import { IconContainer } from './PersonIcon.styles';

const PersonIcon = () => {
  return (
    <IconContainer>
      <IconStyled type="person" aria-label="" />
    </IconContainer>
  );
};

export default PersonIcon;
