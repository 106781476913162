import { TActions } from './actions';
import { ISigningRulesAndSignatoriesState } from './ISigningRulesAndSignatories';
import ActionTypes from './types';

const initialState = {
  isLoading: false,
  signatories: null,
  signingRules: null,
};

const signingRulesAndSignatoriesReducer = (
  state: ISigningRulesAndSignatoriesState,
  action: TActions
) => {
  if (action.type === ActionTypes.FETCH_SIGNING_RULES_AND_SIGNATORIES_SUCCESS) {
    const { signatories, signingRules } = action.payload;

    return {
      isLoading: false,
      signatories,
      signingRules,
    };
  } else if (
    action.type === ActionTypes.FETCH_SIGNING_RULES_AND_SIGNATORIES_REQUEST
  ) {
    return {
      isLoading: true,
      signatories: null,
      signingRules: null,
    };
  } else if (
    action.type === ActionTypes.FETCH_SIGNING_RULES_AND_SIGNATORIES_FAILURE
  ) {
    return initialState;
  }

  return state || initialState;
};

export default signingRulesAndSignatoriesReducer;
