import React, { ComponentType, ReactNode } from 'react';
import { Subtitle } from '../Title';
import {
  HeaderContainer,
  StatusContainer,
  TitleContainer,
  CardTitle,
} from './Header.styles';

interface IHeader {
  title: string;
  subtitle: string;
  StatusComponent?: ComponentType<any>;
  statusComponentProps?: object;
  children?: ReactNode;
}

export const CardHeader = ({
  title,
  subtitle,
  StatusComponent,
  statusComponentProps = {},
  children,
  ...rest
}: IHeader) => {
  return (
    <HeaderContainer {...rest}>
      <TitleContainer>
        <CardTitle>{title}</CardTitle>
        <Subtitle>{subtitle}</Subtitle>
      </TitleContainer>
      {StatusComponent ? (
        <StatusContainer>
          <StatusComponent
            {...statusComponentProps}
            data-testid="header-status"
          />
        </StatusContainer>
      ) : null}
    </HeaderContainer>
  );
};
