import { Identity } from 'src/models';
import { TActions } from './actions';
import ActionTypes from './types';

interface State {
  isLoading: boolean;
  data?: Identity;
  error?: string;
}

const initialState: State = {
  isLoading: false,
};

const reducer = (state: State = initialState, action: TActions) => {
  if (action.type === ActionTypes.IDENTITY_GET) {
    return {
      isLoading: true,
    };
  } else if (action.type === ActionTypes.IDENTITY_GET_SUCCESS) {
    return {
      data: action.payload,
      isLoading: false,
    };
  } else if (action.type === ActionTypes.IDENTITY_GET_FAILURE) {
    return {
      error: action.payload,
      isLoading: false,
    };
  }

  return state;
};

export default reducer;

export const getCompanyRoles = (state: State) => {
  return state.data && state.data.companyRoles;
};

export const getIsIdentityLoading = (state: State) => state.isLoading;

export const getExternalId = (state: State) => {
  return state.data && state.data.externalId;
};

export const getError = (state: State) => state.error;
