import React, { FC } from 'react';
import { Content } from 'src/styles/layout';
import { GridProps } from '@material-ui/core/Grid';
import { Item } from './styles';

export const CoverItem: FC<GridProps> = ({ children, className, ...rest }) => {
  return (
    <Content className={className}>
      <Item {...rest}>{children}</Item>
    </Content>
  );
};
