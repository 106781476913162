import React, { MouseEvent } from 'react';
import { DeadlineDate } from 'src/components/PortalList/PortalDeadlineDate';
import { ISigningCaseListItem } from 'src/models';
import {
  AgreementDeadlineMobile,
  TableCellMobile,
  TableCellTitle,
  TableCellBig,
  TableCellLast,
} from 'src/components';
import { Row, Title, AgreementStatus, AgreementStatusMobile } from './styles';
import { getTextFromAgreementStatus } from 'src/utils/getTextFromAgreementStatus';
import { getAgreementColor } from 'src/utils/getAgreementColor';

type TableRowEventType = MouseEvent<HTMLTableRowElement>;

interface Props {
  document: ISigningCaseListItem;
  onClick?: (e: TableRowEventType, document: ISigningCaseListItem) => void;
}

export const SigningCasesListItem = ({ document, onClick }: Props) => {
  const color = getAgreementColor(
    document.status,
    document.contractingPartyType
  );

  const onSigningCaseClick = (e: TableRowEventType) => {
    if (onClick) {
      onClick(e, document);
    }
  };

  return (
    <Row
      contractingPartyType={document.contractingPartyType}
      documentStatus={document.status}
      tabIndex={0}
      onClick={onSigningCaseClick}
    >
      <TableCellTitle color={color}>
        <Title title={document.name} subtitle={document.contractingPartyName} />
      </TableCellTitle>
      <TableCellBig>
        <DeadlineDate date={document.date} />
      </TableCellBig>
      <TableCellLast align="right" hide={true}>
        <AgreementStatus
          status={document.status}
          color={color}
          displayedStatusText={getTextFromAgreementStatus(document.status)}
        />
      </TableCellLast>
      <TableCellMobile color={color}>
        <AgreementDeadlineMobile date={document.date} />
        <AgreementStatusMobile status={document.status} color={color} />
      </TableCellMobile>
    </Row>
  );
};
