import { TRootState } from 'src/state/types';

const getSigningCasesList = (state: TRootState) => state.signingCasesList;

export const getAwaitingSigningCases = (state: TRootState) =>
  getSigningCasesList(state).AwaitingYourSignature;

export const getAwaitingSigningCasesCount = (state: TRootState) =>
  getSigningCasesList(state).AwaitingYourSignature!.list.length;

export const getPendingSigningCase = (state: TRootState) =>
  getSigningCasesList(state).Pending;

export const getArchiveSigningCase = (state: TRootState) =>
  getSigningCasesList(state).Archived;

export const getOpenedSigningCaseListItem = (state: TRootState) => {
  const signingCase = getSigningCasesList(state).openedSigningCase;

  if (signingCase) {
    const { index, status } = signingCase;
    const list = getSigningCasesList(state)[status].list;
    return list ? list[index] : null;
  }

  return null;
};
