import actions from './actions';
import ActionTypes from './types';
import { ActionType } from 'typesafe-actions';
import {
  Actions as signingCaseActions,
  ActionTypes as SigningCaseActions,
} from '../../SigningCaseDetails';

const initialState = {
  data: false,
  isLoading: false,
};

const { resetOpenedSigningCase } = signingCaseActions;
type State = Readonly<typeof initialState>;
type Actions =
  | ActionType<typeof actions>
  | ActionType<typeof resetOpenedSigningCase>;

const reducer = (state: State, action: Actions) => {
  if (action.type === ActionTypes.REJECT_REQUEST) {
    return {
      data: false,
      isLoading: true,
    };
  } else if (action.type === ActionTypes.REJECT_SUCCESS) {
    return {
      data: action.payload,
      isLoading: false,
    };
  } else if (
    action.type === ActionTypes.REJECT_FAILURE ||
    action.type === SigningCaseActions.RESET_OPENED_SIGNING_CASE
  ) {
    return initialState;
  }

  return state || initialState;
};

export default reducer;
