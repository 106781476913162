import { t } from 'i18next';

export const getTextFromAgreementStatus = status => {
  if (status === 'NotSigned' || status === 'PartiallySigned') {
    return t('AgreementYouMustSign');
  }

  if (status === 'Updating') {
    return t('AgreementUpdating');
  }

  if (status === 'Signed') {
    return t('AgreementSigned');
  }

  if (status === 'Expired') {
    return t('AgreementExpired');
  }

  if (status === 'Cancelled') {
    return t('AgreementCancelled');
  }

  if (status === 'CancelledBySignatory') {
    return t('AgreementRejected');
  }

  if (status === 'Manual') {
    return t('AgreementManual');
  }

  if (status === 'SignedByCurrentContractingParty') {
    return t('AgreementAwaitingOtherContractingParty');
  }
};
