import { SigningCaseService } from 'src/state/SigningCase';
import { ListState } from '../models';
import * as routes from 'src/routes';

export const getListState = (
  oldList: ListState['list'],
  newList: ListState['list'],
  page: ListState['page'],
  listStatus: any
) =>
  page !== 1 && SigningCaseService.isArchived(listStatus)
    ? oldList.concat(newList)
    : newList;

export const getSigningCaseViewPath = (
  currentPath: string,
  signingCaseId: string,
  contractingPartyId: string
) => {
  const subPath = routes.signingCaseView
    .replace(routes.params.signingCaseId, signingCaseId)
    .replace(routes.params.contractingPartyId, contractingPartyId);
  const historyPath =
    currentPath.slice(-1) === '/' ? currentPath.slice(0, -1) : currentPath;

  return `${historyPath}${subPath}`;
};
