import { fontSizeStyle, buttonFocusStyles } from '../FilterButton';
import { linearGradientGrey, white } from 'src/styles/colors';
import styled from 'styled-components';
import { StatusButtonProps } from './interfaces';

export const StatusButtonMenu = styled.div`
  display: flex;
`;

const width = 33.3;
const borderRadiusSize = 1.3;
const borderRadiusUnits = 'em';
const borderRadius = borderRadiusSize.toString() + borderRadiusUnits;
const borderRoundLeft = `${borderRadius} 0 0 ${borderRadius}`;
const borderRoundRight = `0 ${borderRadius} ${borderRadius} 0`;

export const StatusButton = styled.button<StatusButtonProps>`
  display: inline-block;
  color: ${({ theme }) => theme.pallete.primary.main};
  padding: 0.8em 1em;
  font-size: 1em;
  line-height: 1em;
  cursor: pointer;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.pallete.borders.filter}`};
  width: ${width}%;
  background-color: ${({ isSelected, theme }: StatusButtonProps) =>
    isSelected ? theme.pallete.action.filterActive : white};

  &:focus {
    ${buttonFocusStyles}
  }

  &:first-child {
    width: ${width - 10}%;
    border-right: 0;
    border-radius: ${borderRoundLeft};

    &:hover:after {
      border-radius: ${borderRoundLeft};
    }
  }

  &:last-child {
    width: ${width + 10}%;
    border-left: 0;
    border-radius: ${borderRoundRight};

    &:hover:after {
      border-radius: ${borderRoundRight};
    }
  }

  &:hover:after {
    content: '';
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${linearGradientGrey};
  }

  ${fontSizeStyle};
`;
