import { Grid } from '@material-ui/core';
import { darkBlue } from 'src/styles/colors';
import styled from 'styled-components';

export const CoverSection = styled(Grid)`
  color: ${darkBlue};
  background-size: cover;
  height: 250px;
`;

export const Item = styled(Grid)`
  padding: 8px;
`;
