import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { bluePrimary, white } from 'src/styles/colors';
import styled from 'styled-components';

interface IAreaContainer {
  selected: boolean;
}

export const getAreaBorder = (selected: boolean) => {
  const color = selected ? bluePrimary : 'transparent';
  return `2px solid ${color}`;
};

export const AreaContainer = styled(Grid)<IAreaContainer>`
  background-color: #f0f4f5;
  border: ${props => getAreaBorder(props.selected)};
  border-bottom: ${props =>
    props.selected ? getAreaBorder(props.selected) : '1px solid #c7d4da'};
  border-radius: 4px;
  cursor: pointer;
  height: 100px;
  overflow: hidden;
  padding: 15px;
  position: relative;

  &:hover {
    border: 2px solid #8bc5d7;
  }

  &:after {
    display: block;
    width: 100%;
    position: absolute;
    border-radius: 4px;
    content: '';
    height: 30%;
    left: 0;
    bottom: 0%;
    z-index: 1;
    background: linear-gradient(
      to bottom,
      rgba(240, 244, 245, 0) 0%,
      rgba(240, 244, 245, 1) 100%
    );
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  color: black;
  padding: 0 !important;
`;

export const GridCheckbox = styled(Grid)`
  text-align: right;
`;

export const Description = styled.span`
  overflow: hidden;
  overflow-wrap: break-word;
  color: rgb(0, 55, 85);
  font-size: 0.8em;
  opacity: 0.7;
`;

export const TagButton = styled.button`
  display: none;
  border-top-left-radius: 4px;
  border: none;
  color: ${white};
  background-color: #6dbace;
  position: absolute;
  cursor: pointer;
  height: 20px;
  text-align: center;
  padding: 5px 15px;
  bottom: 0;
  right: 0;
  font-size: 10px;
  text-transform: uppercase;
  transition: all 0.2s ease-out;
  z-index: 2;

  ${AreaContainer}:hover & {
    display: block;
  }

  &:hover {
    background-color: ${bluePrimary};
  }
`;
