import styled from 'styled-components';
import { Icon } from 'src/components/Icons';
import {
  darkGreenPrimary,
  hexToHSLWithGradient,
  white,
} from 'src/styles/colors';
import { defaultTransition, mediaMaxWidth2 } from 'src/styles/layout';
import { secondaryHover } from 'src/styles/effects';

const verticalAlign = 'middle';
const breakpointStyles = 'line-height: 1.4em;';
const breakpointStylesButton =
  breakpointStyles +
  `
  padding: 0.5em 0.6em 0.7em 0.7em;
  font-size: 1.4em;
  transition: ${defaultTransition};
`;

export const LogoutButton = styled.button`
  padding: 0.8em 0.8em 1em 1.3em;
  background: ${({ theme }) =>
    hexToHSLWithGradient(theme.pallete.buttons.success)};
  color: ${white};
  font-weight: 700;
  line-height: 1.2em;
  font-size: 1.2em;
  display: inline-block;
  height: 54px;
  vertical-align: ${verticalAlign};
  cursor: pointer;
  border: none;

  ${mediaMaxWidth2.large`${breakpointStylesButton}`}
  &:hover {
    ${secondaryHover};
    color: ${darkGreenPrimary};
    background: ${({ theme }) =>
      hexToHSLWithGradient(theme.pallete.buttons.secondary)};
  }

  &:active {
    background: rgb(34, 169, 122);
    color: ${white};
  }

  &:focus {
    outline: none;
  }
`;

export const LogoutIcon = styled(Icon)`
  padding: 0 0.3em;
  font-size: 1.2em;
  vertical-align: ${verticalAlign};

  ${mediaMaxWidth2.large`${breakpointStyles}`}
`;

export const LogoutText = styled.span`
  vertical-align: ${verticalAlign};

  ${mediaMaxWidth2.large`
    display: none;
  `}
`;
