import Grid from '@material-ui/core/Grid';
import React, { ComponentProps } from 'react';
import { DayPicker } from 'src/components';
import { FilterButton } from './FilterButton';
import { StatusSelect, Status } from './StatusSelect';
import { TimeframeSelect, Timeframe } from './TimeframeSelect';
import { GridStyled } from 'src/styles/layout';
import { Container, GridStatus } from './styles';
import { t } from 'i18next';

interface Props {
  className?: string;
  date: Date;
  isDateValid: boolean;
  timeframe: Timeframe;
  status: Status;
  setDate: ComponentProps<typeof DayPicker>['onClick'];
  setTimeframe: ComponentProps<typeof TimeframeSelect>['onClick'];
  setStatus: ComponentProps<typeof StatusSelect>['onClick'];
  applyFilter: () => void;
}

export const Filter = ({
  date,
  isDateValid,
  timeframe,
  status,
  applyFilter,
  setDate,
  setTimeframe,
  setStatus,
}: Props) => {
  const padding = 16;

  return (
    <Container
      container={true}
      wrap="wrap"
      alignContent="center"
      data-testid="data-testid"
    >
      <GridStyled container={true} item={true} xs={12} xl={4} padding={padding}>
        <Grid item={true} xs={12}>
          <DayPicker
            date={date}
            onClick={setDate}
            label={t('ArchivedBefore')}
            dayPickerProps={{
              disabled: [
                {
                  after: new Date(),
                },
              ],
            }}
          />
        </Grid>
        <GridStyled item={true} padding={8}>
          <TimeframeSelect timeframe={timeframe} onClick={setTimeframe} />
        </GridStyled>
      </GridStyled>
      <GridStatus
        container={true}
        item={true}
        xs={12}
        xl={8}
        padding={padding}
        spacing={8}
      >
        <GridStyled item={true} xs={12} lg={8}>
          <StatusSelect
            status={status}
            onClick={setStatus}
            data-testid="status-menu"
          />
        </GridStyled>
        <GridStyled item={true} xs={12} lg={4}>
          <FilterButton
            data-testid="apply-filter-button"
            onClick={applyFilter}
            disabled={!isDateValid}
          >
            {t('Filter')}
          </FilterButton>
        </GridStyled>
      </GridStatus>
    </Container>
  );
};
