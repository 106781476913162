import React from 'react';
import { TableCellTitle } from 'src/components';
import { IMandatedSignatory } from 'src/models';
import { TableRow } from 'src/pages/Mandates/components/MandatesTable';
import { darkBlue } from 'src/styles/colors';
import PersonIcon from '../PersonIcon';
import { SignatoryTitle } from './SignatoryTableRow.styles';

interface ISignatoryTableRow {
  signatory: IMandatedSignatory;
}

const SignatoryTableRow = (props: ISignatoryTableRow) => {
  return (
    <TableRow>
      <TableCellTitle color={darkBlue} cursor="default">
        <PersonIcon />
        <SignatoryTitle
          title={props.signatory.fullName}
          subtitle={props.signatory.externalId}
        />
      </TableCellTitle>
    </TableRow>
  );
};

export default SignatoryTableRow;
