import styled from 'styled-components';
import { Icon } from 'src/components';

export const Subtitle = styled.span`
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1em;
  margin-top: 0.2em;
`;

const iconFontSize = '1.2em';

export const IconStyled = styled(Icon)`
  font-size: ${iconFontSize};
`;
