import styled from 'styled-components';
import { mediaMaxWidth2, mediaMinWidth } from 'src/styles/layout';
import { PortalButton } from 'src/components/Buttons';

export const buttonFocusShadow = `
  box-shadow: ${({ theme }) =>
    `0 0 0 2px ${theme.pallete.action.timeframeActive}`};
`;

export const buttonFocusStyles = `
  ${buttonFocusShadow};
`;

export const fontSizeStyle = `
  ${mediaMaxWidth2.mobile`
    font-size: 0.9em;
  `}

  ${mediaMaxWidth2.mobileSmall`
    font-size: 0.65em;
  `}
`;

export const FilterButton = styled(PortalButton)`
  margin-top: 0;
  width: 100%;

  ${fontSizeStyle}

  ${mediaMinWidth.tablet`
    margin-top: 27px;
  `}
`;
