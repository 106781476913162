import React from 'react';
import {
  OtherContractingPartiesList,
  SigneesList,
} from 'src/components/PortalList/PortalSigneesList';
import { isSigningCaseReadyToSign } from 'src/utils/SigningCase';
import { SigningRules } from '../SigningRules';
import {
  ISignatory,
  ISigningRule,
  ISigningCaseStatus,
  IOtherContractingParty,
  ISignatureResponse,
} from 'src/models';

interface Props {
  signatories: ISignatory[];
  contractingParties: IOtherContractingParty[];
  signatures: ISignatureResponse[];
  signingRules: ISigningRule[];
  status: keyof ISigningCaseStatus;
}
const Signatories = ({
  signatories = [],
  contractingParties = [],
  signatures,
  signingRules,
  status,
}: Props) => {
  const rulesList =
    signingRules !== undefined &&
    signingRules.filter((item, index, rule) => {
      return rule.findIndex(i => i.rule === item.rule) === index;
    });

  if (haveSignatories(signatories, contractingParties)) {
    return (
      <>
        {isSigningCaseReadyToSign(status) && (
          <SigningRules rules={rulesList as ISigningRule[]} />
        )}
        <SigneesList
          signatories={signatories}
          signatures={signatures}
          showRoleHeader={haveRoles(signatories)}
          data-testid="signatories"
        />
        <OtherContractingPartiesList
          status={status}
          contractingParties={contractingParties}
          showHeader={false}
          data-testid="other-contracting-parties"
        />
      </>
    );
  }

  return null;
};

export default Signatories;

export const haveSignatories = (
  signatories: ISignatory[],
  otherContractingParties: IOtherContractingParty[]
) => {
  return (
    (signatories && signatories.length) ||
    (otherContractingParties && otherContractingParties.length)
  );
};

export const haveRoles = (signatories: ISignatory[]) => {
  return !!signatories.find(
    signatory => signatory.companyRoles && signatory.companyRoles.length
  );
};
