import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { onboardCustomer } from 'src/state/CustomerCreation';
import { setCurrentDate, setExpirationDate } from 'src/state/UserSession';
import store from '../../../store';
import { getLocationHeader, isOnboarding } from './services';

const sessionExpirationDateHeader = 'session-expiration-date';
const serverDateHeader = 'date';

export const responseInterceptorSuccess = (resp: AxiosResponse) => {
  if (
    resp.headers[sessionExpirationDateHeader] &&
    resp.headers[serverDateHeader]
  ) {
    const sessionExpirationDate = moment.utc(
      resp.headers[sessionExpirationDateHeader]
    );
    const serverDate = moment.utc(resp.headers[serverDateHeader]);
    store.dispatch(setExpirationDate(sessionExpirationDate));
    store.dispatch(setCurrentDate(serverDate));
  }

  return resp;
};

export const responseInterceptorErr = err => {
  if (err.response && err.response.status === 401) {
    const redirectUrl = getLocationHeader(err.response.headers);

    if (redirectUrl.length) {
      if (isOnboarding(redirectUrl)) {
        store.dispatch(onboardCustomer());
      } else {
        window.location.assign(redirectUrl + window.location.href);
        throw axios.CancelToken.source().cancel('Redirect');
      }
    }
  }

  return Promise.reject(err);
};
