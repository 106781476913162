import React from 'react';
import { EmptyArchiveMessageContainer } from './styles';
import { t } from 'i18next';

export const EmptyArchiveMessage = () => {
  return (
    <EmptyArchiveMessageContainer data-testid="data-testid">
      <span>{t('YouHaveNoAgreementsInTheArchive')}</span>
    </EmptyArchiveMessageContainer>
  );
};
