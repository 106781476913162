import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { TonCookieSelect } from 'src/pages/Mandates/models';
import { CookieConsent } from './components';
import { MandatesLink } from './styles';
import { ActionCookieContainer, PortalMenuButton } from 'src/components/Menu';

export interface MenuProps {
  step: number;
  cookieSelected: boolean;
  isContinueDisabled: boolean;
  onBack: () => void;
  onContinue: () => void;
  onSubmit: () => void;
  onCookieSelected: TonCookieSelect;
  isOneCompanyRole: boolean;
}

interface IMenu extends WithNamespaces, MenuProps {}

const initialState = {
  cookieSelected: false,
};
type TState = Readonly<typeof initialState>;

class Menu extends Component<IMenu, TState> {
  public state = initialState;

  public render() {
    const {
      step,
      cookieSelected,
      isContinueDisabled = false,
      onBack,
      onContinue,
      onCookieSelected,
      onSubmit,
      isOneCompanyRole,
      t,
    } = this.props;

    return (
      <ActionCookieContainer container={true} justify="flex-end" spacing={8}>
        {step === 1 && (
          <Grid item={true}>
            <CookieConsent onClick={onCookieSelected} value={cookieSelected} />
          </Grid>
        )}
        <Grid container={true} item={true} justify="flex-end" spacing={8}>
          <Grid item={true} xs={12} md={'auto'}>
            {step > 0 ? (
              step === 1 && isOneCompanyRole ? (
                <MandatesLink to={'/Mandates'} colorType="secondary">
                  {t('MandateCancelWizard')}
                </MandatesLink>
              ) : (
                <PortalMenuButton
                  type="button"
                  onClick={onBack}
                  colorType="secondary"
                >
                  {t('Back')}
                </PortalMenuButton>
              )
            ) : (
              <MandatesLink to={'/Mandates'} colorType="secondary">
                {t('MandateCancelWizard')}
              </MandatesLink>
            )}
          </Grid>
          <Grid item={true} xs={12} md={'auto'}>
            <PortalMenuButton
              type="button"
              disabled={isContinueDisabled}
              onClick={step === 3 ? onSubmit : onContinue}
              data-testid="new-mandate-continue-btn"
            >
              {step === 3 ? t('MandateSave') : t('Continue')}
            </PortalMenuButton>
          </Grid>
        </Grid>
      </ActionCookieContainer>
    );
  }
}

export default withNamespaces()(Menu);
