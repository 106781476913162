import React, { ButtonHTMLAttributes, FC } from 'react';
import { Icon } from 'src/components/Icons';
import { Circle } from './CircleButton.styles';

interface ICircleButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconType?: 'ok' | 'delete' | 'refresh' | 'pencil';
  iconAltText?: string;
}

const CircleButton: FC<ICircleButton> = props => {
  const { children, iconAltText, iconType, ...buttonProps } = props;

  return (
    <Circle {...buttonProps}>
      {iconType ? <Icon type={iconType} aria-label={iconAltText} /> : null}
      {children}
    </Circle>
  );
};

export default CircleButton;
