import React from 'react';
import { AgreementStatusText } from 'src/components/PortalList/PortalAgreementStatus';
import { StatusIcon, StatusParagraph } from './ContractingPartyStatus.styles';
import { IContractingPartyStatus } from './IContractingPartyStatus';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import { getSigningCaseHeaderColor } from 'src/utils/getSigningCaseHeaderColor';

const ContractingPartyStatus = ({
  status,
  className,
  displayedStatusText,
}: IContractingPartyStatus) => {
  return (
    <div className={className}>
      <StatusIcon
        status={status}
        color={getSigningCaseHeaderColor(status)}
        style={getSigningCaseStyle(status)}
      />
      <StatusParagraph status={status}>
        <AgreementStatusText
          status={status}
          displayedStatusText={displayedStatusText}
        />
      </StatusParagraph>
    </div>
  );
};

export default ContractingPartyStatus;

const getSigningCaseStyle = status => {
  return isSigningCaseReadyToSign(status)
    ? { marginRight: '-13px' }
    : isSigningCaseWaiting(status)
    ? { marginRight: '-5px' }
    : isSigningCaseSigned(status)
    ? { marginRight: '-4px' }
    : isSigningCaseRejected(status)
    ? { marginRight: '-6px' }
    : {};
};
