import React from 'react';
import { Card } from 'src/components';
import { ICompanyMandate } from 'src/models';
import MandatesTable from '../MandatesTable';
import { t } from 'i18next';

interface IMandatesCards {
  mandates: ICompanyMandate[];
}

const MandatesCards = ({ mandates }: IMandatesCards) => {
  if (mandates.length) {
    return (
      <>
        {mandates.map((mandate, index) => (
          <Card
            labelText={mandate.companyName}
            title={mandate.companyName}
            key={index}
            data-testid="company-mandates"
            showNotifications={index === 0}
          >
            <MandatesTable
              activeMandates={mandate.activeMandates}
              inactiveMandates={mandate.inactiveMandates}
            />
          </Card>
        ))}
      </>
    );
  } else {
    return <Card title={t('SigningMandatesTitle')} showNotifications={true} />;
  }
};

export default MandatesCards;
