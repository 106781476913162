import moment from 'moment';
import { ISigneesList } from 'src/components/PortalList/PortalSigneesList/ISigneesList';
import { ISignatory } from 'src/models';

export const getSignatures = (
  signatory: ISignatory,
  signatures: ISigneesList['signatures']
) =>
  signatures
    ? signatures.filter(signature => signature.externalId === signatory.externalId)
    : [];

export const concatRoles = (companyRoles: ISignatory['companyRoles']) =>
  companyRoles.reduce(
    (roles, role) =>
      roles.length
        ? roles.concat(`, ${role.roleCode}`)
        : roles.concat(role.roleCode),
    ''
  );

export const formatDate = (d: string) => {
  const actionDateTime = moment(new Date(d));

  return actionDateTime.isValid() ? actionDateTime.format('L') : '';
};
