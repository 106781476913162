import { ApiTypes } from 'src/models';
import { action } from 'typesafe-actions';
import { ActionType } from 'typesafe-actions';

export enum CreateMandate {
  REQUEST = 'CREATE_MANDATE_REQUEST',
  SUCCESS = 'CREATE_MANDATE_SUCCESS',
  FAILURE = 'CREATE_MANDATE_FAILURE',
}

export const createMandate = {
  failure: (props: Error) => action(CreateMandate.FAILURE, props),
  request: (props: ApiTypes.ICreateSigningMandateProps) =>
    action(CreateMandate.REQUEST, props),
  success: (props: ApiTypes.ICreateSigningMandateResp) =>
    action(CreateMandate.SUCCESS, props),
};

export type CreateMandateType = ActionType<typeof createMandate>;
