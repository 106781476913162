import React, { Component } from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SigningCasesList } from './SigningCasesList';
import { SigningCaseStatusApi } from 'src/models';
import { ISigningCaseListItem } from 'src/models/SigningCase';
import Actions from 'src/state/SigningCasesList/actions';
import { getPendingSigningCase } from 'src/state/SigningCasesList/selectors';
import { Card, LoadingCard } from '../components';
import { IAgreementCard } from './models';

export class AgreementsWaiting extends Component<IAgreementCard> {
  private status: SigningCaseStatusApi = 'Pending';

  componentDidMount() {
    this.props.actions.fetchSigningCases({
      status: 'Pending',
    });
  }

  render() {
    const { signingCases } = this.props;
    const title = t('AgreementsInProcess');

    if (!signingCases.list.length) {
      return null;
    }

    if (signingCases.isLoading) {
      return <LoadingCard title={title} />;
    }

    return (
      <Card
        labelText={title}
        title={title}
        popout={false}
        data-testid="pending-signing-cases"
      >
        <SigningCasesList
          documents={signingCases.list}
          type={'Waiting'}
          onClick={this.onSigningCaseClick}
        />
      </Card>
    );
  }

  private onSigningCaseClick = (
    signignCaseListItem: ISigningCaseListItem,
    index: number
  ) => {
    const { actions, history, location } = this.props;

    actions.goSigningCaseDetails({
      history,
      index,
      location,
      signignCaseListItem,
      status: this.status,
    });
  };
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
});

const connectedComponent = connect(
  state => ({
    signingCases: getPendingSigningCase(state),
  }),
  mapDispatchToProps
)(AgreementsWaiting);
const ExtendedComponent = withRouter(connectedComponent);
export { ExtendedComponent as AgreementsWaitingCard };
