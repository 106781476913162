import {
  bluePrimary,
  lightGrayTextSecondary,
  white,
  whiteSecondary,
} from 'src/styles/colors';
import styled from 'styled-components';
import { ICircleStep } from '../../components/types';

export const CircleSpan = styled.div<ICircleStep>`
  text-align: center;
  display: block;
  margin: 10px 2em;
  color: ${props => (props.active ? bluePrimary : lightGrayTextSecondary)};
  transition: all 0.2s ease-out;

  &:before {
    //@ts-ignore
    content: '${props => (props.active ? 'L' : props.activeStep + 1)}';
    transform: ${props =>
      props.active ? 'rotate(220deg) scaleY(-1)' : 'none'};
    color: ${white};
    border: ${props => (props.active ? '2px solid #FFF' : 'none')};
    box-shadow: ${props =>
      props.active ? '0 0 0 2px #007BC7, 0 0 0 2px #007BC7' : 'none'};;
    display: inline-block;
    justify-content: center;
    align-content: center;
    width: 30px;
    height: 30px;
    border-radius: 18px;
    position: absolute;
    top: -3px;
    left: calc(50% - 12px);
    background-color: ${props => (props.active ? bluePrimary : whiteSecondary)};
    z-index: 1;
  }
`;
