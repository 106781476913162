import { Grid } from '@material-ui/core';
import React from 'react';
import { PortalButton } from 'src/components/Buttons';
import { PortalMenuButton } from 'src/components/Menu';
import { t } from 'i18next';

interface Props {
  className?: string;
  canSign: boolean;
  onReject: () => void;
  onSign: () => void;
}

const SignMenu = ({ canSign, onReject, onSign }: Props) => {
  return (
    <Grid container={true} justify="flex-end" spacing={8}>
      <Grid item={true} xs={12} md="auto">
        <PortalMenuButton
          onClick={onReject}
          fullWidth={true}
          tabIndex={0}
          data-testid="reject-btn"
          colorType="error"
        >
          {t('Reject')}
        </PortalMenuButton>
      </Grid>
      <Grid item={true} xs={12} md="auto">
        <PortalButton
          disabled={!canSign}
          onClick={onSign}
          fullWidth={true}
          tabIndex={0}
          data-testid="sign-btn"
        >
          {t('Sign')}
        </PortalButton>
      </Grid>
    </Grid>
  );
};

export default SignMenu;
