import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { Color, getThemeColor } from 'src/styles';

const animationDuration = '1s';

export const Container = styled.div`
  position: relative;
`;

export const SpinnerPrimary = styled(CircularProgress)`
  color: white !important;
  position: absolute;
`;

interface SpinnerSecondaryType {
  color: Color;
}

export const SpinnerSecondary = styled(CircularProgress)<SpinnerSecondaryType>`
  animation-duration: ${animationDuration} !important;
  position: relative;
  color: ${({ color, theme }) => getThemeColor(color, theme)} !important;
`;
