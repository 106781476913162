import { flowRight } from 'lodash';
import React from 'react';
import { isSigningCaseArchived } from 'src/utils/SigningCase';
import { ITag } from '../interfaces';
import { DateText, TagText } from '../Tag.styles';
import { t } from 'i18next';

export const DateComponent = ({ className, date, status }: ITag) => (
  <TagText className={className}>
    <span>
      {flowRight(
        t,
        getTagText
      )(status)}
    </span>
    <DateText>{date}</DateText>
  </TagText>
);

const getTagText = (status: ITag['status']) =>
  isSigningCaseArchived(status) ? 'DateArchived' : 'Expires';
