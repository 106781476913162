import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LoadingCard } from 'src/components/Loaders';
import { Config } from 'src/state/Config';
import { fetchMandates } from 'src/state/Mandates';
import * as fromRoot from 'src/state/rootReducer';
import { Cover as SigningDocumentCover } from './components';
import MandatesCards from './components/MandatesCards';

interface Props {
  fetchData: typeof fetchMandates.getRequest;
  mandates: ReturnType<typeof fromRoot.getMandates>;
  isLoading: boolean;
  isMandateRevoking: boolean;
  isMandateRevoked: boolean;
}

class Mandates extends Component<Props> {
  componentDidMount() {
    if (!this.props.isMandateRevoking) {
      this.props.fetchData();
    }
  }

  shouldComponentUpdate(nextProps: Props) {
    if (
      this.props.isMandateRevoking &&
      !nextProps.isMandateRevoking &&
      !this.props.isMandateRevoked &&
      nextProps.isMandateRevoked
    ) {
      this.props.fetchData();
      return false;
    }

    return true;
  }

  render() {
    const { isLoading, mandates = [] } = this.props;

    return (
      <>
        <SigningDocumentCover canCreateMandate={!Config.isUkSite()} />
        {isLoading ? (
          <LoadingCard title="SigningMandatesTitle" />
        ) : (
          <MandatesCards mandates={mandates} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  mandates: fromRoot.getMandates(state),
  isLoading: fromRoot.getIsMandatesLoading(state),
  isMandateRevoking: fromRoot.getIsMandateRevoking(state),
  isMandateRevoked: fromRoot.getIsMandateRevoked(state),
});

export default connect(
  mapStateToProps,
  {
    fetchData: fetchMandates.getRequest,
  }
)(Mandates);
