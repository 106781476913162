import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { SigningDocumentCover, CoverItem } from 'src/components/Cover';
import { Grid } from '@material-ui/core';
import { Link } from './styles';
import { t } from 'i18next';

interface Props extends RouteComponentProps<any> {
  canCreateMandate?: boolean;
}

const CoverSection = ({ canCreateMandate = false, match }: Props) => {
  return (
    <SigningDocumentCover container={true} alignItems="flex-end">
      {canCreateMandate ? (
        <CoverItem container justify="flex-end">
          <Grid item xs={12} md="auto">
            <Link to={`${match.url}/New`} data-testid="new-mandate-btn">
              {t('MandateCreateButton')}
            </Link>
          </Grid>
        </CoverItem>
      ) : null}
    </SigningDocumentCover>
  );
};

export default withRouter(CoverSection);
