import { TableRow } from 'src/components/PortalList/PortalList.styles';
import styled from 'styled-components';
import { isSigningCaseReadyToSign } from 'src/utils/SigningCase';
import { ISigningCaseListItem } from 'src/models';
import { lightBlue, transparentBlue } from 'src/styles/colors';
import { TableTitle } from 'src/components/Table/TableTitle';
import { mediaMaxWidth2 } from 'src/styles/layout';
import { SigningCaseStatus } from '../SigningCaseStatus';

interface RowProps {
  contractingPartyType: ISigningCaseListItem['contractingPartyType'];
  documentStatus: ISigningCaseListItem['status'];
}

const getBackgroundColor = (props: RowProps) => {
  if (
    isCompanyDocumentNotSigned(props.contractingPartyType, props.documentStatus)
  ) {
    return '#EFF6F9';
  } else {
    return 'white';
  }
};

const getBackgroundHoverColor = (props: RowProps) => {
  if (
    isCompanyDocumentNotSigned(props.contractingPartyType, props.documentStatus)
  ) {
    return lightBlue;
  } else {
    return transparentBlue;
  }
};

const isCompanyDocumentNotSigned = (
  contractingPartyType: ISigningCaseListItem['contractingPartyType'],
  documentStatus: ISigningCaseListItem['status']
): boolean => {
  return (
    contractingPartyType === 'Company' &&
    isSigningCaseReadyToSign(documentStatus)
  );
};

export const Row = styled(TableRow)`
  background-color: ${getBackgroundColor};

  &:hover {
    background-color: ${getBackgroundHoverColor};
  }
`;

export const Title = styled(TableTitle)`
  ${mediaMaxWidth2.tabletSmall`
    font-size: 1.2em;
  `}
`;

export const AgreementStatus = styled(SigningCaseStatus)`
  color: ${props => props.color};
`;

export const AgreementStatusMobile = styled(SigningCaseStatus)`
  display: inline-block;
  padding: 10px 5px;
  width: 50%;
  text-align: right;
  color: ${props => props.color} ${mediaMaxWidth2.tabletSmall`
    font-size: 1.2em;
  `};
`;
