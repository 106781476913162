import React from 'react';
import {
  INewSignatory,
  TRemoveCallback,
  TRemoveSignatory,
  TUpdateSignatory,
  TUpdateSignatoryCallback,
} from 'src/pages/Mandates/models';
import SignatoryListRow from './components/SignatoryListRow';
import { EmptyListText } from './SignatoriesList.styles';
import { t } from 'i18next';

interface ISignatoriesList {
  signatories?: INewSignatory[];
  removeSignatory: TRemoveCallback;
  updateSignatory: TUpdateSignatoryCallback;
  refreshSignatory: any;
}

const SignatoriesList = ({
  signatories = [],
  removeSignatory,
  updateSignatory,
  refreshSignatory,
}: ISignatoriesList) => {
  return (
    <>
      {signatories.length ? (
        signatories.map((signatory, index) => (
          <SignatoryListRow
            key={index}
            signatory={signatory}
            removeSignatory={onRemoveSignatory(index, removeSignatory)}
            updateSignatory={onUpdateSignatory(index, updateSignatory)}
            refreshSignatory={refreshSignatory}
          />
        ))
      ) : (
        <EmptyListText>{t('MandateNoSignatoriesCurrentlySet')}</EmptyListText>
      )}
    </>
  );
};

const onRemoveSignatory: TRemoveSignatory = (id, callback) => () => {
  callback(id);
};

const onUpdateSignatory: TUpdateSignatory = (id, callback) => signatory => {
  callback({ id, signatory });
};

export default SignatoriesList;
