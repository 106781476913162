import { Grid } from '@material-ui/core';
import withWidth, { isWidthUp, WithWidth } from '@material-ui/core/withWidth';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import React, { Component, HTMLAttributes } from 'react';
import { NavList } from 'src/components/NavList';
import LogoutButton from 'src/components/PortalLogoutButton';
import {
  getNavigationLinks,
  isHomeRoute,
  createLanguageMenuLinks,
} from 'src/routes';
import { Content } from 'src/styles/layout';
import { AppBar, LogoStyled, Logout, Navigation } from './styles';
import { getAwaitingSigningCasesCount } from 'src/state/SigningCasesList/selectors';
import SigningCaseListActions from 'src/state/SigningCasesList/actions';
import * as fromRoot from 'src/state/rootReducer';
import { getRejectData } from 'src/state/Signing/Reject/selectors';
import { LanguageMenu } from './components';
import { Config } from 'src/state/Config';
import HamburgerMenu from 'src/components/HamburgerMenu';

interface DispatchProps {
  fetchSigningCases: typeof SigningCaseListActions.fetchSigningCases;
}

interface StateProps extends HTMLAttributes<HTMLElement> {
  awaitingSigningCasesCount: ReturnType<typeof getAwaitingSigningCasesCount>;
  isSignatureSigned: ReturnType<typeof fromRoot.getIsSignatureSuccessful>;
  isSignatureRejected: ReturnType<typeof getRejectData>;
  showNavigation?: boolean;
  shouldOnboardCustomer: boolean;
  isUserOnboarded: boolean;
  width: WithWidth['width'];
}

type Props = DispatchProps & StateProps & RouteComponentProps;

export class Header extends Component<Props> {
  componentDidMount() {
    if (!this.props.shouldOnboardCustomer) {
      this.props.fetchSigningCases({ status: 'AwaitingYourSignature' });
    }
  }

  componentDidUpdate(prevProps) {
    if (shouldFetchSigningCases(prevProps, this.props)) {
      this.props.fetchSigningCases({ status: 'AwaitingYourSignature' });
    }
  }

  render() {
    const {
      showNavigation,
      awaitingSigningCasesCount,
      width,
      location,
      isUserOnboarded,
      ...rest
    } = this.props;
    const languageCode = Config.getLanguageCode();
    const supportedLanguageCodes = Config.getSupportedLanguageCodes();
    const links = getNavigationLinks(
      isUserOnboarded,
      Config.isDanicaDKSite(),
      Config.isNordaniaDenmarkSite()
    ).map(x => ({
      ...x,
      title: t(x.title),
    }));
    const languageMenuLinks = createLanguageMenuLinks(
      location.pathname,
      languageCode,
      supportedLanguageCodes
    );
    const isDesktopScreen = isWidthUp('xl', width);

    return (
      <AppBar {...rest}>
        <Content>
          <Navigation container component="nav">
            {showNavigation && (
              <Grid item component="li" xs="auto" hidden={isDesktopScreen}>
                <HamburgerMenu
                  links={links}
                  data-testid="navigation-btn-mobile"
                  awaitingSigningCasesCount={awaitingSigningCasesCount}
                  languageCode={languageCode}
                  languageMenuLinks={languageMenuLinks}
                />
              </Grid>
            )}
            <Grid item component="li" xs="auto">
              <LogoStyled to="/" />
            </Grid>
            {showNavigation && (
              <>
                <Grid
                  item
                  component="li"
                  xs={6}
                  xl={6}
                  hidden={!isDesktopScreen}
                >
                  <NavList
                    list={links}
                    awaitingSigningCasesCount={awaitingSigningCasesCount}
                  />
                </Grid>
              </>
            )}
            <Grid
              container
              item
              component="li"
              alignItems="center"
              xs
              justify="flex-end"
              spacing={8}
            >
              {location && languageMenuLinks.length > 1 && (
                <Grid item hidden={!isDesktopScreen}>
                  <LanguageMenu
                    languageCode={languageCode}
                    languageMenuLinks={languageMenuLinks}
                  />
                </Grid>
              )}
              <Logout item>
                <LogoutButton />
              </Logout>
            </Grid>
          </Navigation>
        </Content>
      </AppBar>
    );
  }
}

export const shouldFetchSigningCases = (
  prevProps: Props,
  currentProps: Props
) =>
  isAgreementSigned(prevProps, currentProps) ||
  isAgreementRejected(prevProps, currentProps) ||
  isOnHomePage(prevProps, currentProps) ||
  isCustomerOnboarded(prevProps, currentProps);

export const isCustomerOnboarded = (prevProps: Props, currentProps: Props) =>
  prevProps.shouldOnboardCustomer && !currentProps.shouldOnboardCustomer;

export const isAgreementSigned = (prevProps: Props, currentProps: Props) =>
  !prevProps.isSignatureSigned.data.success &&
  currentProps.isSignatureSigned.data.success;

export const isAgreementRejected = (prevProps: Props, currentProps: Props) =>
  !prevProps.isSignatureRejected && currentProps.isSignatureRejected;

export const isOnHomePage = (prevProps: Props, currentProps: Props) => {
  return (
    !isHomeRoute(prevProps.location.pathname) &&
    isHomeRoute(currentProps.location.pathname)
  );
};

const mapStateToProps = state => ({
  awaitingSigningCasesCount: getAwaitingSigningCasesCount(state),
  isSignatureSigned: fromRoot.getIsSignatureSuccessful(state),
  isSignatureRejected: getRejectData(state),
  shouldOnboardCustomer: fromRoot.getShouldOnboardCustomer(state),
  isUserOnboarded: fromRoot.getIsUserOnboarded(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSigningCases: status =>
    dispatch(SigningCaseListActions.fetchSigningCases(status)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withWidth()(Header))
);
