import React from 'react';
import { Table, TableHead, TableHeaderCell } from 'src/components';
import { t } from 'i18next';

export const DocumentsList = ({ children }) => {
  return (
    <Table>
      <TableHead>
        <tr>
          <TableHeaderCell>{t('AgreementDocuments')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>{children}</tbody>
    </Table>
  );
};
