import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { CardLoader } from 'src/components';
import { TonCookieSelect } from 'src/pages/Mandates/models';
import {
  isProductAreasStep,
  isSignatoriesStep,
} from 'src/pages/Mandates/services';
import { TRootState } from 'src/state';
import { createMandate } from 'src/state/Mandates/New';
import IdentityActions from 'src/state/Identity/actions';
import { fetchIdentity } from 'src/state/Identity';
import { notificationsManager } from 'src/state/Notifications';
import * as fromRoot from 'src/state/rootReducer';
import { ErrorScreen } from './components/ErrorScreen';
import FormBody from './components/FormBody';
import { LoadingScreen } from './components/LoadingScreen';
import ProgressSteps from './components/ProgressSteps';
import { SuccessScreen } from './components/SuccessScreen';
import Menu from './components/Menu';
import { Form } from './NewMandateForm.styles';
import { selectCompany } from 'src/state/Mandates/New/Company/actions';
import { Config } from 'src/state/Config';
import { fetchProductAreas } from 'src/state/ProductAreas';
import { productAreasSelect } from 'src/state/Mandates/New/ProductAreas';
import { Title } from 'src/components/Card/Title';

interface Props extends WithNamespaces {
  createMandateData: ReturnType<typeof fromRoot.getCreateMandate>;
  haveErrorNotifications: ReturnType<typeof fromRoot.haveErrorNotifications>;
  haveSelectedProductAreas: ReturnType<
    typeof fromRoot.haveSelectedProductAreas
  >;
  areSignatoriesValid: boolean;
  mandatesNew: ReturnType<typeof fromRoot.getNewMandateForm>;
  mandatesNewForm: ReturnType<typeof fromRoot.getNewMandateFormData>;
  isExpirationDateValid: boolean;
  isNewMandateFormLoading: ReturnType<typeof fromRoot.isNewMandateFormLoading>;
  create: typeof createMandate.request;
  clearNotifications: typeof notificationsManager['clearNotifications'];
  getIdentity: typeof fetchIdentity.getIdentity;
  companyRoles: ReturnType<typeof fromRoot.getCompanyRoles>;
  is1CompanyRole: boolean;
  is1ProductArea: boolean;
  shouldFetchProductAreas: boolean;
  selectCompany: typeof selectCompany.set;
  fetchProductAreas: typeof fetchProductAreas['request'];
  selectProductArea: typeof productAreasSelect['set'];
}

export interface IStep {
  index: number;
  name: string;
}

const initialState = {
  cookieSelected: false,
  isInvalid: false,
  areStepsModified: false,
  step: 0,
  steps: [
    { index: 0, name: 'MandateCompany' },
    { index: 1, name: 'MandateSignatories' },
    { index: 2, name: 'MandateAreas' },
    { index: 3, name: 'MandateDetails' },
  ],
};

type State = Readonly<typeof initialState>;

export class NewMandateForm extends Component<Props, State> {
  public readonly state = {
    ...initialState,
    steps: initialState.steps.map(({ index, name }) => ({
      index,
      name: this.props.t(name),
    })),
  };

  componentWillUpdate() {
    if (this.props.haveErrorNotifications) {
      this.invalidateForm();
    }
  }

  componentDidUpdate(prevProps) {
    const companyRolesUpdated =
      this.props.companyRoles !== prevProps.companyRoles;

    if (companyRolesUpdated) {
      this.setSteps();
    }
  }

  componentDidMount() {
    this.initializeForm();
    if (this.isFormInitialized()) this.setSteps();
  }

  shouldComponentUpdate() {
    return !this.state.isInvalid;
  }

  public render() {
    const { haveErrorNotifications, isNewMandateFormLoading } = this.props;
    const { cookieSelected, step, steps, areStepsModified } = this.state;

    if (this.props.createMandateData.isLoading) {
      return <LoadingScreen />;
    }

    if (this.props.createMandateData.error) {
      return <ErrorScreen />;
    }

    if (this.props.createMandateData.data) {
      return (
        <SuccessScreen
          caseId={this.props.createMandateData.data.CaseId}
          contractingPartyId={
            this.props.createMandateData.data.ContractingPartyId
          }
        />
      );
    }

    if (isNewMandateFormLoading || !areStepsModified) {
      return <CardLoader />;
    }

    return (
      <Form>
        <ProgressSteps
          activeStep={step}
          steps={steps}
          data-testid="progress-steps"
        />
        <Title>{steps[step].name}</Title>
        {!haveErrorNotifications && <FormBody step={this.getActiveStep()} />}
        <Menu
          step={this.getActiveStep()}
          isOneCompanyRole={this.props.is1CompanyRole}
          cookieSelected={cookieSelected}
          isContinueDisabled={this.isContinueDisabled()}
          onCookieSelected={this.onCookieSelect}
          onBack={this.onBack}
          onContinue={this.onContinue}
          onSubmit={this.onSubmit}
        />
      </Form>
    );
  }

  private initializeForm = () => {
    if (this.props.shouldFetchProductAreas) {
      this.props.fetchProductAreas();
    }

    if (!this.props.companyRoles) {
      this.props.getIdentity();
    }
  };

  private isFormInitialized = (
    { companyRoles, shouldFetchProductAreas } = this.props
  ) => isFormInitialized(companyRoles, shouldFetchProductAreas);

  private isContinueDisabled = () =>
    isContinueDisabled(
      this.getActiveStep(),
      this.state.cookieSelected,
      this.props.areSignatoriesValid,
      this.props.haveSelectedProductAreas,
      this.props.haveErrorNotifications,
      this.props.isExpirationDateValid
    );

  private getActiveStep = (): number => this.state.steps[this.state.step].index;

  private setSteps = (): void => {
    const steps = this.getSteps();

    this.setState({
      areStepsModified: true,
      steps,
    });
  };

  private getSteps = (): IStep[] => {
    let steps = this.state.steps;

    if (this.props.is1CompanyRole) {
      this.props.selectCompany(this.props.companyRoles![0].companyId);
      steps = steps.filter(step => !isCompanyStep(step.index));
    }

    if (this.props.is1ProductArea) {
      this.props.selectProductArea({
        index: '0',
        value: true,
      });
      steps = steps.filter(step => !isMandateAreasStep(step.index));
    }

    return steps;
  };

  private onBack = () => this.setState(decrementStep);

  private onContinue = () => {
    if (isSignatoriesStep(this.state.step)) {
      this.props.clearNotifications();
    }

    this.setState(incrementStep);
  };

  private onSubmit = () => {
    this.props.create(this.props.mandatesNewForm);
  };

  private invalidateForm = () => this.setState({ isInvalid: true });

  private onCookieSelect: TonCookieSelect = e =>
    this.setState({ cookieSelected: e.target.checked });
}

type TStateReducer = (prevState: State, props?: Props) => State;

export const isContinueDisabled = (
  step: number,
  cookieSelected: boolean,
  areSignatoriesValid: boolean,
  haveSelectedProductAreas: boolean,
  haveErrorNotifications: boolean,
  isExpirationDateValid: boolean
) => {
  if (isSignatoriesStep(step)) {
    return !areSignatoriesValid || !cookieSelected;
  }
  if (isProductAreasStep(step)) {
    return !haveSelectedProductAreas;
  }
  if (isProductAreasStep(step)) {
    return !haveSelectedProductAreas;
  }

  return haveErrorNotifications || !isExpirationDateValid;
};

export const decrementStep: TStateReducer = prevState => ({
  ...prevState,
  step: prevState.step - 1,
});

export const incrementStep: TStateReducer = prevState => ({
  ...prevState,
  step: prevState.step + 1,
});

export const isCompanyStep = (index: number) => index === 0;
export const isMandateAreasStep = (index: number) => index === 2;

export const shouldFetchProductAreas = (
  isRealkreditDenmark: boolean,
  haveProductAreas: boolean
) => isRealkreditDenmark && !haveProductAreas;

export const isFormInitialized = (companyRoles, shouldFetchProductAreas) =>
  companyRoles && !shouldFetchProductAreas;

const isExpirationDateValid = (state): boolean => {
  const isDateEnabled = fromRoot.getIsNewMandateExpirationDateEnabled(state);
  const isDateFormatValid = fromRoot.getIsNewMandateExpirationDateFormatValid(
    state
  );
  return !isDateEnabled || (isDateEnabled && isDateFormatValid);
};

const connectedComponent = connect(
  (state: TRootState) => ({
    createMandateData: fromRoot.getCreateMandate(state),
    haveErrorNotifications: fromRoot.haveErrorNotifications(state),
    haveSelectedProductAreas: fromRoot.haveSelectedProductAreas(state),
    areSignatoriesValid: fromRoot.areSignatoriesValid(state),
    isNewMandateFormLoading: fromRoot.isNewMandateFormLoading(state),
    mandatesNew: fromRoot.getNewMandateForm(state),
    mandatesNewForm: fromRoot.getNewMandateFormData(state),
    isExpirationDateValid: isExpirationDateValid(state),
    companyRoles: fromRoot.getCompanyRoles(state),
    is1CompanyRole: fromRoot.is1CompanyRole(state),
    shouldFetchProductAreas: shouldFetchProductAreas(
      Config.isRealkreditDenmark(),
      fromRoot.haveProductAreas(state)
    ),
    is1ProductArea: fromRoot.is1ProductArea(state),
  }),
  {
    create: createMandate.request,
    clearNotifications: notificationsManager.clearNotifications,
    getIdentity: IdentityActions.getIdentity,
    selectCompany: selectCompany.set,
    fetchProductAreas: fetchProductAreas.request,
    selectProductArea: productAreasSelect.set,
  }
)(NewMandateForm);

export default withNamespaces()(connectedComponent);
