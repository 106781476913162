import React from 'react';
import { ValidityWrapper } from './ValidityDate.styles';

interface IValidityDate {
  title: string;
  content: string;
}

export const ValidityDate = ({ title, content }: IValidityDate) => {
  return (
    <ValidityWrapper>
      <span>{title}: </span>
      <strong>{content}</strong>
    </ValidityWrapper>
  );
};
