import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import { GridStyled } from '../../../styles/layout';
import { Title } from '../../Text';

interface Props {
  title: string;
  info?: string;
  menu?: JSX.Element;
}

export const MessageScreen: FC<Props> = ({ title, info, menu }) => {
  const padding = 20;

  return (
    <Grid container={true} justify="center">
      <GridStyled item={true} xs={12} padding={padding}>
        <Title>{title}</Title>
      </GridStyled>
      {info && (
        <GridStyled item={true} xs={12} padding={padding}>
          <Grid container={true} item={true} justify="center">
            <span>{info}</span>
          </Grid>
        </GridStyled>
      )}
      {menu && (
        <GridStyled item={true} xs={12} padding={padding}>
          {menu}
        </GridStyled>
      )}
    </Grid>
  );
};
