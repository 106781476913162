import styled from 'styled-components';
import { ISigningCaseStatus } from 'src/models';
import {
  isSigningCaseSigned,
  isSigningCaseRejected,
} from 'src/utils/SigningCase';

export const getOtherSigneesColor = (status: keyof ISigningCaseStatus) => {
  return isSigningCaseSigned(status)
    ? '#e2f2ee'
    : isSigningCaseRejected(status)
    ? '#fbe5e4'
    : '#e9f3f7';
};

export const List = styled.div<{ status: keyof ISigningCaseStatus }>`
  background-color: ${({ status }) => getOtherSigneesColor(status)};
`;

export const Title = styled.h3`
  font-weight: 700;
  text-align: center;
  padding: 1.5em 1em 1em;
  margin: 0;
  font-size: 1.4em;
  font-family: 'DanskeHuman';
`;
