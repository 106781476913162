import { css } from 'styled-components';
import iconFontEot from '../assets/fonts/icon-font.eot';
import iconFontWoff from '../assets/fonts/icon-font.woff';
import iconFontWoff2 from '../assets/fonts/icon-font.woff2';
import iconFontTtf from '../assets/fonts/icon-font.ttf';
import iconFontSvg from '../assets/fonts/icon-font.svg';

export default css`
  @font-face {
    font-family: 'DanskeIcons';
    src: url(${iconFontEot});
    src: url(${iconFontEot}?88234912#iefix) format('embedded-opentype'),
      url(${iconFontWoff2}?88234912) format('woff2'),
      url(${iconFontWoff}?88234912) format('woff'),
      url(${iconFontTtf}?88234912) format('truetype'),
      url(${iconFontSvg}?88234912#fontello) format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^='generic-icon']:before,
  [class*=' generic-icon']:before {
    font-family: 'DanskeIcons';
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin: 0;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .generic-icon.add:before {
    content: '\\e800';
  }
  .generic-icon.arrow:before {
    content: '\\e801';
  }
  .generic-icon.attention:before,
  .generic-icon.warning:before {
    content: '\\e802';
  }
  .generic-icon.attention-outline:before {
    content: '\\e803';
  }
  .generic-icon.block:before {
    content: '\\e804';
  }
  .generic-icon.check:before,
  .generic-icon.ok:before,
  .generic-icon.done:before {
    content: '\\e805';
  }
  .generic-icon.clock:before {
    content: '\\e806';
  }
  .generic-icon.date:before {
    content: '\\e807';
  }
  .generic-icon.delete:before,
  .generic-icon.cancel:before {
    content: '\\e808';
  }
  .generic-icon.double-down:before {
    content: '\\e809';
  }
  .generic-icon.download:before {
    content: '\\e80a';
  }
  .generic-icon.forward:before {
    content: '\\e80b';
  }
  .generic-icon.info:before {
    content: '\\e80c';
  }
  .generic-icon.lock-open:before,
  .generic-icon.logout:before {
    content: '\\e80d';
  }
  .generic-icon.menu:before {
    content: '\\e80e';
  }
  .generic-icon.more-horizontal:before {
    content: '\\e80f';
  }
  .generic-icon.more-vertical:before {
    content: '\\e810';
  }
  .generic-icon.pencil:before {
    content: '\\e811';
  }
  .generic-icon.person:before {
    content: '\\e812';
  }
  .generic-icon.refresh:before,
  .generic-icon.waiting:before {
    content: '\\e813';
  }
  .generic-icon.remove:before {
    content: '\\e814';
  }
  .generic-icon.search:before {
    content: '\\e815';
  }
  .generic-icon.settings:before {
    content: '\\e816';
  }
`;
