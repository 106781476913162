import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import Signature from './actions';
import ActionTypes from './types';

type Signature = ReturnType<typeof Signature.createSignature>;

function* createSignature(params: Signature) {
  try {
    const { contractingPartyId, signingCaseId } = params.payload;

    yield put(Signature.createSignatureRequest());

    const signature = yield call(
      api.createSignature,
      signingCaseId,
      contractingPartyId
    );

    yield put(Signature.createSignatureSuccess(signature.data.data));
  } catch (e) {
    yield put(Signature.createSignatureFailure());
    yield put(notificationsManager.addNotification(e));
  }
}

export function* watch() {
  yield takeLatest(ActionTypes.CREATE_SIGNATURE, createSignature);
}
