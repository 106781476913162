import React from 'react';
import { BlueSpinner } from 'src/components/Loaders';
import { MessageScreen } from '../MessageScreen';

interface Props {
  title: string;
  message: string;
}

export const Processing = ({ title, message }: Props) => {
  return (
    <MessageScreen
      title={title}
      info={message}
      menu={<BlueSpinner size={80} />}
    />
  );
};
