import * as scroll from './scroll';

export const isDocumentBottom = (treshold: number = 0) =>
  window.innerHeight + window.pageYOffset >=
  scroll.getWindowHeight() - treshold;

export const getWindowHeight = () => {
  const body = document.body;
  const html = document.documentElement;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html ? html.clientHeight || html.scrollHeight || html.offsetHeight || 0 : 0
  );
};

export const canLoad = (
  isLoading: boolean,
  hasNextPage: boolean,
  treshold: number = 0
) => !isLoading && hasNextPage && scroll.isDocumentBottom(treshold);
