import styled from 'styled-components';

export const Description = styled.p`
  margin: 0.5em 0;
  font-weight: 700;
`;

export const Rules = styled.ul`
  margin: 0.5em 0;
  padding-left: 1.5em;
`;

export const Rule = styled.li`
  font-weight: 400;
`;
