import { ISigningCaseListItem } from 'src/models';
import {
  isSigningCaseReadyToSign,
  isSigningCaseRejected,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import {
  darkBlue,
  bluePrimary,
  darkRedPrimary,
  darkOrangePrimary,
  darkGreenPrimary,
} from 'src/styles';

export const getAgreementColor = (
  signingCaseStatus: ISigningCaseListItem['status'],
  contractingPartyType: ISigningCaseListItem['contractingPartyType']
): string => {
  if (
    isSigningCaseReadyToSign(signingCaseStatus) &&
    contractingPartyType === 'Person'
  ) {
    return bluePrimary;
  } else if (isSigningCaseWaiting(signingCaseStatus)) {
    return darkOrangePrimary;
  } else if (isSigningCaseRejected(signingCaseStatus)) {
    return darkRedPrimary;
  } else if (isSigningCaseSigned(signingCaseStatus)) {
    return darkGreenPrimary;
  }

  return darkBlue;
};
