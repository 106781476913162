import { Status } from './StatusSelect';
import { Timeframe } from './TimeframeSelect';
import * as filter from './actions';
import { ActionType, isActionOf } from 'typesafe-actions';
import { combineReducers } from 'redux';

type Action = ActionType<typeof filter>;

const initialSate = {
  date: new Date(),
  isDateValid: true as boolean,
  status: 'All' as Status,
  timeframe: 'Today' as Timeframe,
};

const date = (state: Date = initialSate.date, action: Action) => {
  if (isActionOf(filter.setDate, action)) {
    if (!action.payload) {
      return state;
    }
    return action.payload;
  } else if (isActionOf(filter.setTimeframe, action)) {
    const today = new Date();
    const timeframe = action.payload;

    if (timeframe === 'Today') {
      return today;
    }
    if (timeframe === 'Month') {
      today.setMonth(today.getMonth() - 1);
      return today;
    }
    if (timeframe === 'Months6') {
      today.setMonth(today.getMonth() - 6);
      return today;
    }
    if (timeframe === 'Year') {
      today.setFullYear(today.getFullYear() - 1);
      return today;
    }
  }

  return state;
};

const status = (state: Status = initialSate.status, action: Action) => {
  if (isActionOf(filter.setStatus, action)) {
    return action.payload;
  }

  return state;
};

const isDateValid = (
  state: boolean = initialSate.isDateValid,
  action: Action
) => {
  if (isActionOf(filter.setDate, action)) {
    return !!action.payload;
  }
  return state;
};

const timeframe = (
  state: Timeframe = initialSate.timeframe,
  action: Action
) => {
  if (isActionOf(filter.setTimeframe, action)) {
    return action.payload;
  }

  return state;
};

const archiveFilter = combineReducers({
  date,
  status,
  timeframe,
  isDateValid,
});

export default archiveFilter;

type State = ReturnType<typeof archiveFilter>;

export const getDate = (state: State) => state.date;

export const getIsDateValid = (state: State) => state.isDateValid;

export const getStatus = (state: State) => state.status;

export const getTimeframe = (state: State) => state.timeframe;
