import { ISigningRulesAndSignatories } from 'src/models';
import { action, ActionType } from 'typesafe-actions';
import ActionTypes from './types';

const Actions = {
  fetchSigningRulesAndSignatoriesFailure: () =>
    action(ActionTypes.FETCH_SIGNING_RULES_AND_SIGNATORIES_FAILURE),
  fetchSigningRulesAndSignatoriesRequest: () =>
    action(ActionTypes.FETCH_SIGNING_RULES_AND_SIGNATORIES_REQUEST),
  fetchSigningRulesAndSignatoriesSuccess: (
    signingRulesAndSignatories: ISigningRulesAndSignatories
  ) =>
    action(
      ActionTypes.FETCH_SIGNING_RULES_AND_SIGNATORIES_SUCCESS,
      signingRulesAndSignatories
    ),
};

export type TActions = ActionType<typeof Actions>;
export default Actions;
