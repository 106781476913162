import { Grid } from '@material-ui/core';
import React from 'react';
import { Card } from '../../Card';
import { GridStyled } from 'src/styles/layout';
import Spinner from '../Spinner';
import { t } from 'i18next';

interface ILoadingCard {
  title?: string;
}

const LoadingCard = ({ title = '' }: ILoadingCard) => {
  return (
    <Card title={t(title)}>
      <GridStyled container={true} justify="center" padding={20}>
        <Grid item={true}>
          <Spinner />
        </Grid>
      </GridStyled>
    </Card>
  );
};

export default LoadingCard;
