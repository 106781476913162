import React, { ComponentProps } from 'react';
import { DialogModal } from './DialogModal';
import { t } from 'i18next';

type Props = ComponentProps<typeof DialogModal>;

const RejectDialog = ({
  onCancel,
  onConfirm,
  title = t('Confirmation'),
}: Props) => {
  const message: string = t(
    'IfYouRejectTheAgreementItWillBeDiscardedAndStoredInTheArchive'
  );
  const warning: string = t('AreYouSureYouWantToRejectTheAgreement');

  const dialogProps = {
    title,
    message,
    warning,
    onCancel,
    onConfirm,
  };

  return <DialogModal {...dialogProps} data-testid="data-testid" />;
};

export default RejectDialog;
