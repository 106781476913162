import React from 'react';
import { Card } from 'src/components';
import { MandateLimits } from 'src/models';
import { SigningRules } from '../SigningRules';
import { t } from 'i18next';

interface ISigningRulesCard {
  limits: MandateLimits;
}

const SigningRulesCard = ({ limits }: ISigningRulesCard) => {
  return (
    <Card
      labelText={t('MandateRules')}
      title={t('MandateRules')}
      type="success"
      data-testid="data-testid"
    >
      <SigningRules limits={limits} />
    </Card>
  );
};

export default SigningRulesCard;
