import { ProductAreasResponse } from 'src/models/Api';
import { createAsyncActionTypes } from 'src/state/common';
import { ActionType, createAsyncAction } from 'typesafe-actions';

export const types = createAsyncActionTypes('FETCH_PRODUCT_AREAS');

export const fetchProductAreas = createAsyncAction(
  types.REQUEST,
  types.SUCCESS,
  types.FAILURE
)<undefined, ProductAreasResponse, undefined>();

export type FetchProductAreas = ActionType<typeof fetchProductAreas>;
