import React, { FC } from 'react';
import { Container, SpinnerPrimary, SpinnerSecondary } from './Spinner.styles';
import { Color } from 'src/styles';

interface Props {
  size?: number;
  color?: Color;
}

const Spinner: FC<Props> = ({ size, color = 'inherit' }) => {
  return (
    <Container>
      <SpinnerPrimary variant="determinate" value={100} size={size} />
      <SpinnerSecondary
        disableShrink={true}
        variant="indeterminate"
        size={size}
        color={color}
      />
    </Container>
  );
};

export default Spinner;
