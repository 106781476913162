import React, { FC, HTMLProps } from 'react';
import moment from 'moment';
import { DeadlineDate } from 'src/components/PortalList/PortalDeadlineDate';

interface Props extends HTMLProps<HTMLElement> {
  date: string;
}

const MandateDate: FC<Props> = ({ date, children, ...props }) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? (
    <DeadlineDate {...props} date={momentDate.format('L')} />
  ) : (
    <span {...props}>{date || '-'}</span>
  );
};

export default MandateDate;
