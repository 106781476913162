import React from 'react';
import DashList from '../DashList';
import {
  ListContainer,
  Section,
  Title,
  TitleContainer,
} from './Details.styles';

interface IDetails {
  title: string;
  content: any[];
}

const Details = ({ content, title }: IDetails) => {
  return (
    <Section component="section" container={true}>
      <TitleContainer item={true} xs={12}>
        <Title>{title}</Title>
      </TitleContainer>
      <ListContainer item={true}>
        <DashList list={content} />
      </ListContainer>
    </Section>
  );
};

export default Details;
