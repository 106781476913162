import { AxiosError } from 'axios';
import { INotification } from 'src/models/Notification';
import { action, ActionType } from 'typesafe-actions';
import ActionTypes from './types';

export const notificationsManager = {
  addNotification: (notification: INotification | AxiosError) =>
    action(ActionTypes.ADD_NOTIFICATION, notification),
  clearNotifications: () => action(ActionTypes.CLEAR_NOTIFICATIONS),
  removeNotification: (text: INotification['text']) =>
    action(ActionTypes.REMOVE_NOTIFICATION, text),
};

export type TActions = ActionType<typeof notificationsManager>;
