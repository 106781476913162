import { CreateMandate } from 'src/state/Mandates/New/CreateMandate';
import ActionTypes from './types';

const initialState = '';

const company = (state: string = initialState, action: any) => {
  if (action.type === ActionTypes.SELECT_COMPANY) {
    return action.payload;
  } else if (action.type === CreateMandate.REQUEST) {
    return initialState;
  }

  return state;
};

export default company;
