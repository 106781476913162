import { IContractingParty } from 'src/models/ContractingParty';
import {
  isSigningCaseRejected,
  isSigningCaseSigned,
} from 'src/utils/SigningCase';
import { Config } from 'src/state';

export const getCardType = (status: IContractingParty['status']) => {
  return isSigningCaseRejected(status)
    ? 'error'
    : isSigningCaseSigned(status)
    ? 'success'
    : Config.isRealkreditDenmark()
    ? 'default'
    : 'primary';
};
