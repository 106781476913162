import { SigningCaseStatusApi } from 'src/models';

export const isArchived = (status: SigningCaseStatusApi) =>
  status === 'Archived';

export const isArchivedSigned = (status: SigningCaseStatusApi) =>
  status === 'ArchivedSigned';

export const isArchivedUnsigned = (status: SigningCaseStatusApi) =>
  status === 'ArchivedUnsigned';

export const isArchivedAny = (status: SigningCaseStatusApi) =>
  isArchived(status) || isArchivedSigned(status) || isArchivedUnsigned(status);
