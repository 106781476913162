import { Countries } from 'src/models';
import { createAsyncAction } from 'typesafe-actions';

const prefix = 'COUNTRIES';

export const fetchCountries = createAsyncAction(
  `${prefix}_REQUEST`,
  `${prefix}_SUCCESS`,
  `${prefix}_FAILURE`
)<undefined, Countries, Error>();
