import React from 'react';
import {
  AgreementsReadyCard,
  AgreementsWaitingCard,
} from '../../AgreementsCard';
import { SigningDocumentCover } from 'src/components/Cover';

export const ForSigning = () => {
  return (
    <>
      <SigningDocumentCover />
      <AgreementsReadyCard />
      <AgreementsWaitingCard />
    </>
  );
};
