import React from 'react';
import { Label } from 'src/components/Input';
import { OnStatusClick, Status } from './interfaces';
import { StatusButton, StatusButtonMenu } from './styles';
import { t } from 'i18next';

interface StatusSelectProps {
  status: Status;
  onClick: (status: Status) => void;
}

export const StatusSelect = ({ onClick, status }: StatusSelectProps) => {
  const ArchiveStatus = 'ArchiveStatus';
  const All = 'All';
  const Signed = 'Signed';
  const NotSigned = 'NotSigned';

  const onStatusClick: OnStatusClick = e => onClick(e.currentTarget.value);

  return (
    <div data-testid="data-testid">
      <Label as="span">{t('Status')}</Label>
      <StatusButtonMenu role="menu">
        <StatusButton
          type="button"
          role="menuitem"
          value={All}
          isSelected={status === All}
          onClick={onStatusClick}
        >
          {t(ArchiveStatus + All)}
        </StatusButton>
        <StatusButton
          type="button"
          role="menuitem"
          value={Signed}
          isSelected={status === Signed}
          onClick={onStatusClick}
        >
          {t(ArchiveStatus + Signed)}
        </StatusButton>
        <StatusButton
          type="button"
          role="menuitem"
          value={NotSigned}
          isSelected={status === NotSigned}
          onClick={onStatusClick}
        >
          {t(ArchiveStatus + NotSigned)}
        </StatusButton>
      </StatusButtonMenu>
    </div>
  );
};
