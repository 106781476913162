import styled from 'styled-components';

interface ISection {
  popout: boolean;
}

export const Section = styled.section<ISection>`
  margin-top: ${props => (props.popout ? '-60px' : '0px')};
`;

export const ListContainer = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
`;

export const EmptyListContainer = styled(ListContainer)`
  padding: 20px;
  text-align: center;
`;

type Theme = 'primary' | 'success' | 'error' | 'default';

interface CardProps {
  type?: Theme;
  theme: any;
}

export const Container = styled.div<CardProps>`
  font-size: inherit;
  display: inline-block;
  position: relative;
  padding: 0;
  width: 100%;
  box-shadow: ${({ theme, type }) =>
    type && type !== 'default'
      ? `0 0 2px 4px ${theme.pallete.backgrounds[type]}`
      : ' 0 2px 4px 0 rgba(57, 67, 68, 0.2)'};
  background-color: white;
  margin: 0 0 1.5em;

  & > div:last-child {
    background-color: white;
  }
`;
