import { combineReducers } from 'redux';
import { Countries } from 'src/models';
import { ActionType, isActionOf } from 'typesafe-actions';
import { fetchCountries } from './actions';

type Action = ActionType<typeof fetchCountries>;

const list = (state: Countries = [], action: Action) => {
  if (isActionOf(fetchCountries.success, action)) {
    return action.payload;
  } else if (
    isActionOf(fetchCountries.failure, action) ||
    isActionOf(fetchCountries.request, action)
  ) {
    return [];
  }

  return state;
};

const isLoading = (state: boolean = false, action: Action) => {
  if (
    isActionOf(fetchCountries.failure, action) ||
    isActionOf(fetchCountries.success, action)
  ) {
    return false;
  } else if (isActionOf(fetchCountries.request, action)) {
    return true;
  }

  return state;
};

const reducer = combineReducers({
  isLoading,
  list,
});

export default reducer;

type State = ReturnType<typeof reducer>;

export const getList = (state: State) => state.list;

export const getIsLoading = (state: State) => state.isLoading;
