import { t } from 'i18next';
import React, { ChangeEvent, Component } from 'react';
import { Input } from 'src/components/Input';
import { Signatory } from 'src/pages/Mandates/models';
import { Config } from 'src/state/Config';
import {
  Button,
  Container,
  GridBottom,
  GridInput,
  Text,
} from './AddSignatoriesInput.styles';

interface IAddSignatoriesInput {
  onAddSignatory: (signatory: Signatory) => void;
}

const initialState: {
  errors: any;
  signatory: Signatory;
} = {
  errors: {
    externalId: '',
    fullName: '',
  },
  signatory: {
    externalId: '',
    fullName: '',
  },
};
type State = Readonly<typeof initialState>;

class AddSignatoriesInput extends Component<IAddSignatoriesInput, State> {
  state = initialState;

  render() {
    return (
      <Container container={true} spacing={24} alignContent="flex-start">
        <GridInput item={true} xs={12} xl={4}>
          <Input
            labelText={t('MandateNameSurname')}
            inputProps={{
              name: 'MandateNameSurname',
              onChange: this.setSignatoryName,
              placeholder: t('MandateNameSurname'),
              value: this.state.signatory.fullName,
            }}
          />
          {this.state.errors.fullName ? (
            <Text>{this.state.errors.fullName}</Text>
          ) : null}
        </GridInput>
        <GridInput item={true} container={true} xs={12} xl={4}>
          <Input
            labelText={t('MandatePersonNumber')}
            inputProps={{
              name: 'MandatePersonNumber',
              onChange: this.setSignatoryNumber,
              placeholder: t('MandatePersonNumberPlaceholder'),
              value: this.state.signatory.externalId,
            }}
          />
          {this.state.errors.externalId ? (
            <Text>{this.state.errors.externalId}</Text>
          ) : null}
        </GridInput>
        <GridBottom item={true} xs={12} xl={4}>
          <Button
            type={'button'}
            onClick={this.onAddSignatory}
            colorType="success"
          >
            {t('MandateAddSignatory')}
          </Button>
        </GridBottom>
      </Container>
    );
  }

  private onAddSignatory = () => {
    if (!this.validateInputs()) {
      this.resetSignatory();
      this.props.onAddSignatory(this.state.signatory);
    }
  };

  private setSignatoryName = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    this.setState(setSignatoryName(target));
  };

  private setSignatoryNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    this.setState(setSignatoryNumber(target));
  };

  private validateInputs = () => {
    this.resetErrors();
    let haveErrors = false;

    Object.keys(this.state.signatory).forEach(input => {
      const value = this.state.signatory[input] as string;

      if (input === 'fullName') {
        if (value.length === 0) {
          this.setState(
            addError(input, t('ErrorSignatoryNameAndSurnameRequired'))
          );
          haveErrors = true;
        }
      } else if (input === 'externalId') {
        const signatoryValidator = getSignatoryIdRegExep(Config);

        if (value.length === 0) {
          this.setState(addError(input, t('ErrorPersonIdEmpty')));
          haveErrors = true;
        } else if (signatoryValidator && !signatoryValidator.test(value)) {
          this.setState(addError(input, t('ErrorPersonIdInvalid')));
          haveErrors = true;
        }
      }
    });

    return haveErrors;
  };

  private resetSignatory = () => this.setState(resetSignatory);
  private resetErrors = () => this.setState(resetErrors);
}

export const resetSignatory = (): State => initialState;
export const resetErrors = (state: State): State => ({
  ...state,
  errors: {},
});

const addError = (input: string, value: string) => {
  return (state: State) => ({
    ...state,
    errors: {
      ...state.errors,
      [input]: value,
    },
  });
};

export const setSignatoryName = (
  target: ChangeEvent<HTMLInputElement>['target']
) => (prevState: State): State => ({
  ...prevState,
  signatory: {
    ...prevState.signatory,
    fullName: target.value,
  },
});

export const setSignatoryNumber = (
  target: ChangeEvent<HTMLInputElement>['target']
) => (prevState: State): State => ({
  ...prevState,
  signatory: {
    ...prevState.signatory,
    externalId: target.value,
  },
});

type GetSignatoryIdRegExep = (config: typeof Config) => RegExp | null;
export const getSignatoryIdRegExep: GetSignatoryIdRegExep = config => {
  return config.isNorwaySite()
    ? /^\d{11}$/
    : config.isDenmarkCountry()
    ? /^\d{10}$/
    : config.isSwedishSite()
    ? /^\d{10}$/
    : config.isFinlandSite()
    ? /^\d{6}[-+A-FU-Y]\d{3}[A-Z0-9]$/
    : null;
};

export default AddSignatoriesInput;
