import { NavLink } from 'react-router-dom';
import { defaultTransition } from 'src/styles/layout';
import { getLinkShadow } from 'src/styles/effects';
import styled from 'styled-components';

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  transition: ${defaultTransition};
`;

export const MenuItem = styled.li`
  position: relative;
  display: inline-block;

  &:active,
  &:hover {
    cursor: pointer;

    &:after {
      display: block;
    }
  }
`;

export const Link = styled(NavLink).attrs(({ theme }) => ({
  activeStyle: {
    boxShadow: getLinkShadow(theme.pallete.primary.main),
    color: theme.pallete.action.hover,
  },
}))`
  display: block;
  padding: 19px 20px;
  color: ${({ theme }) => theme.pallete.primary.main};
  transition: ${defaultTransition};
  height: auto;

  &:hover {
    color: ${({ theme }) => theme.pallete.secondary.main};
    box-shadow: ${({ theme }) => getLinkShadow(theme.pallete.primary.main)};
    text-decoration: none;
  }
`;
