import { ICompanyMandate } from 'src/models';
import { action, ActionType } from 'typesafe-actions';
import { FetchMandatesTypes } from './types';

export const fetchMandates = {
  getFailure: () => action(FetchMandatesTypes.GET_FAILURE),
  getRequest: () => action(FetchMandatesTypes.GET_REQUEST),
  getSuccess: (params: ICompanyMandate[]) =>
    action(FetchMandatesTypes.GET_SUCCESS, params),
};

export type FetchMandates = ActionType<typeof fetchMandates>;
