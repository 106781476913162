import Grid from '@material-ui/core/Grid';
import React, { Component, ComponentProps } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { DayPicker } from 'src/components';

export interface IMandateValidTill extends WithNamespaces {
  dayPickerProps: ComponentProps<typeof DayPicker>;
}

export class MandateValidTill extends Component<IMandateValidTill> {
  public render() {
    const { t, dayPickerProps } = this.props;

    return (
      <Grid
        container={true}
        direction="row"
        alignItems="center"
        wrap="wrap"
        spacing={8}
      >
        <Grid item={true} md={12} lg={'auto'}>
          <span>{t('ValidTill')}</span>
        </Grid>
        <Grid item={true} md={12} lg={'auto'}>
          <DayPicker {...dayPickerProps} />
        </Grid>
      </Grid>
    );
  }
}

export default withNamespaces()(MandateValidTill);
