import axios from 'axios';
import { Config } from 'src/state/Config';
import { queryParameters } from '../constants';

const getCountriesUrl = () => `${Config.host}/Countries`;

export const fetchCountries = () =>
  axios.get(getCountriesUrl(), {
    params: {
      [queryParameters.languageCode]: Config.getLanguageCode(),
    },
  });
