import { ApiTypes } from 'src/models';
import { action } from 'typesafe-actions';
import ActionTypes from './types';

const Actions = {
  signDocument: (props: ApiTypes.IsSignatureSuccessfulParams) =>
    action(ActionTypes.SIGN_DOCUMENT, props),
  signDocumentFailure: () => action(ActionTypes.SIGN_DOCUMENT_FAILURE),
  signDocumentRequest: () => action(ActionTypes.SIGN_DOCUMENT_REQUEST),
  fetchSignDocumentUpdate: () => action(ActionTypes.SIGN_DOCUMENT_UPDATE),
  signDocumentSuccess: (
    props: ApiTypes.IsSignatureSuccessfulResponse['data']
  ) => action(ActionTypes.SIGN_DOCUMENT_SUCCESS, props),
};

export default Actions;
