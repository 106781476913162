import Grid from '@material-ui/core/Grid';
import React, { FC } from 'react';
import {
  HorizontalBreak,
  MandateDetails,
  MandateExpirationDateInput,
} from './components';

const DetailsFormGroup: FC = () => {
  return (
    <Grid container={true}>
      <Grid item={true}>
        <MandateExpirationDateInput />
      </Grid>
      <Grid item={true} xs={12}>
        <HorizontalBreak />
      </Grid>
      <Grid item={true} xs={12}>
        <MandateDetails />
      </Grid>
    </Grid>
  );
};

export default DetailsFormGroup;
