import { IDocumentResponse } from 'src/models';
import { IGetDocumentContentProps } from 'src/models/Api';

export const isPDF = (type: string) => {
  return type ? type.toLocaleLowerCase() === 'pdf' : false;
};

type MimeTypeToFormat = (
  type: IDocumentResponse['mimeType']
) => IGetDocumentContentProps['format'];

export const mimeTypeToFormat: MimeTypeToFormat = type => {
  return type === 'PDF' ? 'PDF' : 'HTML';
};
