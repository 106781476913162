import Grid from '@material-ui/core/Grid';
import { PortalButton, PortalLink } from 'src/components/Buttons';
import { mediaMaxWidth2 } from 'src/styles/layout';
import styled, { css } from 'styled-components';
import { white } from 'src/styles';

export const ActionMenuContainer = styled(Grid)`
  padding: 10px 20px;
`;

export const ActionCookieContainer = styled(Grid)`
  padding: 10px 20px;
  background-color: ${white};
  margin-top: 2rem;
`;

export const MenuButtonStyles = css`
  text-align: center;

  ${mediaMaxWidth2.tabletSmall`
    display: inline-block;
    width: 100%;
    margin-bottom: .8em;
    margin-left: 0;

    &:last-child {
      margin-bottom: 0;
    }
 `}
`;

export const MenuContainer = styled.div`
  text-align: right;

  ${mediaMaxWidth2.tabletSmall`
    text-align: center;
 `}
`;

export const PortalMenuButton = styled(PortalButton)`
  ${MenuButtonStyles}
`;

export const PortalMenuLink = styled(PortalLink)`
  ${MenuButtonStyles};
  display: block;
`;
