import React from 'react';
import { TableCellBig } from 'src/components';
import {
  LineBreakText,
  TableCell,
  TableRow,
} from './MandatesTableLineBreak.styles';
import { t } from 'i18next';

const MandatesTableLineBreak = () => (
  <TableRow>
    <TableCell>
      <LineBreakText>{t('ExpiredAndRevokedMandates')}</LineBreakText>
    </TableCell>
    <TableCellBig />
    <TableCellBig />
  </TableRow>
);

export default MandatesTableLineBreak;
