import React, { HTMLAttributes } from 'react';
import { InfoText } from './PortalDocumentsList.styles';
import SignMenu from './SignMenu';
import { ActionMenu } from 'src/components/Menu';

interface IDocumentListActions extends HTMLAttributes<HTMLElement> {
  canSign: boolean;
  onReject: () => void;
  onSign: () => void;
}

const DocumentListActions = ({
  canSign,
  onReject,
  onSign,
  ...rest
}: IDocumentListActions) => (
  <ActionMenu
    {...rest}
    leftSide={<InfoText />}
    rightSide={
      <SignMenu canSign={canSign} onReject={onReject} onSign={onSign} />
    }
  />
);

export default DocumentListActions;
