import React from 'react';
import { IContractingParty, ISignatory } from 'src/models';
import SigneeTableRow from './PortalSigneeRow';
import { concatRoles, formatDate, getSignatures } from './utils';

export interface ISigneesListBody {
  signatories: ISignatory[];
  signatures: IContractingParty['signatures'];
}

const SigneesListBody = ({ signatories, signatures }: ISigneesListBody) => {
  return (
    <>
      {signatories.map((signatory, index) => {
        const signature = getSignatures(signatory, signatures)[0] || {};
        const roles = concatRoles(signatory.companyRoles);

        return (
          <SigneeTableRow
            key={index}
            actionDateTime={formatDate(signature.actionDateTime)}
            actionRole={signature.actionRole}
            companyRoles={roles}
            fullName={signatory.fullName}
            data-testid="signatory"
          />
        );
      })}
    </>
  );
};

export default SigneesListBody;
