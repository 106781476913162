import React, { Component } from 'react';
import { Trans, withNamespaces, WithNamespaces } from 'react-i18next';
import { Config } from 'src/state/Config';
import {
  LinkButton,
  CookieContainer,
  CookieButton,
  CloseButton,
  CloseIcon,
  SectionInner,
  SectionContainer,
  SectionRow,
  SectionColumn,
  InboxIcon,
  CookieDisclaimerText,
  Link,
} from './Cookie.styles';
import { withRouter, RouteComponentProps } from 'react-router';

type CookieConsentOptions = Readonly<{
  acceptanceValue: string;
  cookieName: string;
  expireTime: number;
  rejectionValue: string;
  safeCookieName: {
    cookiesOn: boolean;
  };
  additionalDeletions: Array<{
    name: string;
    path: string;
    domain: string;
  }>;
}>;

const options: CookieConsentOptions = {
  acceptanceValue: 'yes',
  additionalDeletions: [
    {
      domain: 'publiccontact',
      name: 'JSESSIONID',
      path: '/system',
    },
  ],
  cookieName: 'cookiesOn',
  expireTime: 365,
  rejectionValue: 'no',
  safeCookieName: {
    cookiesOn: true,
  },
};

interface CookieConsentState {
  isActive: boolean;
}

type Cookie = {
  name: string;
  value: string;
  expirationTime: number;
  domain: string;
  path: string;
};

enum CookieStatus {
  NOT_SET = -1,
  REJECTED = 0,
  ACCEPTED = 1,
}

type Props = WithNamespaces & RouteComponentProps;

class CookieConsent extends Component<Props, CookieConsentState> {
  constructor(props) {
    super(props);
    this.state = { isActive: false };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.state.isActive && prevProps.location !== this.props.location) {
      this.hideNotificationBar();
    }
  }

  componentDidMount() {
    if (this.getCookieStatus() === CookieStatus.NOT_SET) {
      this.acceptCookies(true);
    } else if (this.getCookieStatus() === CookieStatus.REJECTED) {
      this.deleteCookies();
    }
  }

  private getCookieStatus(): number {
    const cookieValue = this.getCookieValue(options.cookieName);

    if (cookieValue === '') {
      return CookieStatus.NOT_SET;
    } else if (cookieValue === options.acceptanceValue) {
      return CookieStatus.ACCEPTED;
    }
    return CookieStatus.REJECTED;
  }

  private bakeCookie({ name, value, expirationTime, domain, path }: Cookie) {
    const date: Date = new Date();
    date.setDate(date.getDate() + expirationTime);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};domain=${domain};path=${path}`;
  }

  private getCookieValue(name: string): string {
    const cookie = document.cookie.split(`${name}=`)[1];
    return cookie ? cookie.split('; ')[0] : '';
  }

  acceptCookies = (isActive: boolean) => {
    const { cookieName, acceptanceValue, expireTime } = options;
    this.bakeCookie({
      name: cookieName,
      value: acceptanceValue,
      expirationTime: expireTime,
      domain: getCookieDomain(),
      path: '/',
    });
    this.setState({ isActive });
  };

  rejectCookies = () => {
    const { cookieName, rejectionValue, expireTime } = options;
    this.deleteCookies();
    this.bakeCookie({
      name: cookieName,
      value: rejectionValue,
      expirationTime: expireTime,
      domain: getCookieDomain(),
      path: '/',
    });
    this.hideNotificationBar();
  };

  eraseCookie = ({
    name = '',
    path = '/',
    domain = getCookieDomain(),
    value = '',
    expirationTime = 0,
  }: Partial<Cookie>) => {
    this.bakeCookie({ domain, expirationTime, name, path, value });
  };

  private deleteCookies() {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => this.eraseCookie({ name: cookie.split('=')[0] }));
    this.deleteAdditionalCookies();
    this.clearStorage();
  }

  private deleteAdditionalCookies() {
    options.additionalDeletions.forEach(cookie => {
      this.bakeCookie({
        name: cookie.name,
        value: '',
        expirationTime: 0,
        domain: `${cookie.domain}.${getDomain()}`,
        path: cookie.path,
      });
    });
  }

  private clearStorage() {
    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
    }
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.clear();
    }
  }

  hideNotificationBar = () => this.setState({ isActive: false });

  render() {
    const { t } = this.props;
    return (
      <>
        {!Config.isUkSite() && this.state.isActive && (
          <CookieContainer data-testid="cookie-consent-banner">
            <SectionContainer>
              <SectionInner>
                <SectionRow>
                  <SectionColumn>
                    <InboxIcon>
                      <use xlinkHref="#svg-inbox">
                        <svg
                          viewBox="0 0 20 20"
                          id="svg-inbox"
                          width="100%"
                          height="100%"
                        >
                          <path d="M19 14.4a1.6 1.6 0 0 1-1.6 1.6h-4.452l-2.594 2.595a.502.502 0 0 1-.708 0L7.052 16H2.6A1.6 1.6 0 0 1 1 14.4V2.6A1.6 1.6 0 0 1 2.6 1h14.8A1.6 1.6 0 0 1 19 2.6v11.8zM17.5 0h-15A2.5 2.5 0 0 0 0 2.5v12A2.5 2.5 0 0 0 2.5 17h4.293l2.853 2.854a.502.502 0 0 0 .708 0L13.207 17H17.5a2.5 2.5 0 0 0 2.5-2.5v-12A2.5 2.5 0 0 0 17.5 0zM6 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm4 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                        </svg>
                      </use>
                    </InboxIcon>
                    <CookieDisclaimerText>
                      <Trans
                        i18nKey="CookieConsentDisclaimer"
                        components={[
                          <Link
                            href={t('CookiePolicy')}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Text
                          </Link>,
                          <LinkButton onClick={this.rejectCookies}>
                            Text
                          </LinkButton>,
                        ]}
                      />
                    </CookieDisclaimerText>
                    <CookieButton onClick={() => this.acceptCookies(false)}>
                      OK
                    </CookieButton>
                  </SectionColumn>
                </SectionRow>
              </SectionInner>
              <CloseButton
                onClick={this.hideNotificationBar}
                data-testid="close-button"
              >
                <CloseIcon>
                  <use xlinkHref="#svg-close">
                    <svg
                      viewBox="-1 0 19 18"
                      id="svg-close"
                      width="100%"
                      height="100%"
                    >
                      <path d="M10.192,8.778 L17.262,1.708 C17.654,1.316 17.654,0.684 17.262,0.293 C16.872,-0.097 16.24,-0.097 15.849,0.293 L8.774,7.363 L1.704,0.293 C1.315,-0.097 0.68,-0.097 0.29,0.293 C-0.1,0.683 -0.1,1.316 0.29,1.707 L7.36,8.777 L0.29,15.85 C-0.1,16.24 -0.1,16.87 0.29,17.26 C0.486,17.456 0.74,17.554 0.998,17.554 C1.252,17.554 1.508,17.456 1.704,17.261 L8.774,10.191 L15.846,17.261 C16.041,17.458 16.296,17.556 16.552,17.556 C16.808,17.556 17.064,17.458 17.259,17.263 C17.649,16.873 17.649,16.241 17.259,15.85 L10.189,8.777 L10.192,8.778 Z" />
                    </svg>
                  </use>
                </CloseIcon>
              </CloseButton>
            </SectionContainer>
          </CookieContainer>
        )}
      </>
    );
  }
}

export default withNamespaces()(withRouter(CookieConsent));

// sign.danskebank.fi -> danskebank.fi
export const getDomain = (): string => {
  let domain: string = '';
  const locationParts: string[] = document.location.host.split('.');

  domain = `${locationParts[locationParts.length - 2]}.${
    locationParts[locationParts.length - 1]
  }`;

  if (document.location.host.search(/co\.uk/i) !== -1) {
    domain = `${locationParts[locationParts.length - 3]}.${domain}`;
  }

  if (document.location.host.match(/^localhost/)) {
    domain = 'localhost';
  }

  return domain.toLowerCase();
};

export const getCookieDomain = (): string => {
  const domain = getDomain();
  return domain === 'localhost' ? domain : `.${domain}`;
};
