import { CreateMandate } from 'src/state/Mandates/New/CreateMandate';
import { SELECT_EXPIRATION_DATE, DISABLE_EXPIRATION_DATE } from './actions';

const initialState = new Date();

const reducer = (state: Date = initialState, action: any) => {
  if (action.type === SELECT_EXPIRATION_DATE) {
    return action.payload || null;
  } else if (action.type === CreateMandate.REQUEST) {
    return initialState;
  } else if (action.type === DISABLE_EXPIRATION_DATE) {
    return null;
  }

  return state;
};
export default reducer;

export const isExpirationDateFormatValid = (state = true, action: any) => {
  if (action.type === SELECT_EXPIRATION_DATE) {
    return !!action.payload;
  }

  return state;
};

export const isExpirationDateEnabled = (state = false, action: any) => {
  if (action.type === DISABLE_EXPIRATION_DATE) {
    return false;
  }
  if (action.type === SELECT_EXPIRATION_DATE) {
    return true;
  }
  return state;
};
