import { AxiosError } from 'axios';
import { INotification } from 'src/models/Notification';
import { isForbidden } from 'src/utils/Errors';

export const mapErrorToMessage = (error: AxiosError) => {
  if (isForbidden(error)) {
    return 'UnauthorisedAccess';
  } else {
    return errorTypes[error.message] || 'TechnicalError';
  }
};

export const doesErrorHaveType = (error: AxiosError) => {
  const errorType =
    error.response && error.response.data && error.response.data.type;
  return errorType ? true : false;
};

export const mapErrorTypeToMessage = (error: AxiosError) => {
  const errorType =
    error.response && error.response.data && error.response.data.type;
  return mapErrorToMessage(errorType || '');
};

export const isErrorNotification = (errorType: string) => errorType === 'Error';

/**
 * Map error message to localization string
 */
const errorTypes = {
  'DigitalSigningPortal.Interfaces.Exceptions.DataMismatchException':
    'TheAgreementYouAreTryingToAccessDoesNotExist',
};

export const transformToNotification = (
  notification: AxiosError
): INotification => {
  if (doesErrorHaveType(notification)) {
    return {
      text: mapErrorTypeToMessage(notification),
      type: 'Error',
    };
  } else {
    return {
      text: mapErrorToMessage(notification),
      type: 'Error',
    };
  }
};
