import React from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import Badge from '@material-ui/core/Badge';
import { mediaMaxWidth } from 'src/styles/layout';
import styled from 'styled-components';
import { ITheme } from './IHamburgerMenu';

interface IHamburgerMenu {
  isOpen: boolean;
  toggle: () => void;
  className?: string;
}

export const HamburgerMenu = styled(Dropdown)<IHamburgerMenu>`
  height: 100%;

  ${props => props.theme}
`;

export const HamburgerBadge = styled(({ isOpen, ...other }) => (
  <Badge {...other} />
))`
  & > span {
    background-color: orange;
    padding-right: 4px;
    font-size: 0.55rem;
    top: 12px;
    right: 4px;
    border: 2px solid
      ${({ theme, isOpen }) =>
        isOpen
          ? theme.pallete.backgrounds.hamburgerMenu
          : theme.pallete.backgrounds.header};
    border-radius: 12px;
    min-width: 22px;
    height: 22px;
  }
`;

export const HamburgerDropdown = styled(DropdownToggle).attrs({
  color: 'link',
})<ITheme>`
  display: none;

  ${props => props.theme}
  text-decoration: none;

  ${mediaMaxWidth.large(`
    display: inline-block;
    font-size: 1.6em;
    border: none;
    top: 0px;
    left: 0px;
    padding: 6px 15px;
    border-radius: 0;
    height: 100%;
  `)}

  &:hover {
    ${props => props.theme}
    text-decoration: none;
  }
`;
