import { t, TranslationFunction } from 'i18next';
import { IMandatedSignatory, IMandateLimit, MandateLimits } from 'src/models';
import { join } from 'src/state/Functional';

export const joinMandatedSignatoriesNames = (mandates: IMandatedSignatory[]) =>
  join(mandates, (x: IMandatedSignatory) => x.fullName);

export const joinMandateProductAreas = (
  mandateLimits: MandateLimits,
  separator?: string,
  accumulator?: string
) =>
  join(
    mandateLimits,
    (x: IMandateLimit) => x.productArea,
    separator,
    accumulator
  );

export const displayProductAreas = (
  t: TranslationFunction,
  mandateLimits: MandateLimits,
  requiredSignatories: number,
  mandatedSignatories: number
) => {
  const placeholder = getProductAreaPlaceholder(
    requiredSignatories,
    mandatedSignatories
  );
  const productAreas = joinMandateProductAreas(
    mandateLimits,
    t('ProductSeparator')
  );

  if (mandateLimits.length < 3) {
    return `${productAreas} ${placeholder}`;
  } else {
    const multipleProductAreas = t('DefaultName', {
      number: requiredSignatories,
    });

    return `${multipleProductAreas} ${placeholder}`;
  }
};

/**
 * @returns Placeholders: (singly), ({nr} jointly), (jointly by all)
 */
export const getProductAreaPlaceholder = (
  requiredSignatories: number,
  mandatedSignatories: number
): string => {
  const returnPlaceholder = (placeholderName: string) =>
    t(placeholderName, {
      number: requiredSignatories,
    });

  const jointlyPlaceholders =
    requiredSignatories < mandatedSignatories
      ? returnPlaceholder('JointlyWithNumber')
      : t('JointlyByAllPlaceholder');

  return requiredSignatories === 1
    ? returnPlaceholder('SinglyPlaceholder')
    : jointlyPlaceholders;
};
