import { Grid } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';
import { Input } from 'src/components/Input';
import { t } from 'i18next';

interface IRequiredSignatories {
  requiredSignatories: number;
  onClick: (event: SyntheticEvent<HTMLInputElement>) => void;
  value: string;
}

const RequiredSignatoriesSelect = ({
  requiredSignatories,
  onClick,
  value,
}: IRequiredSignatories) => {
  return (
    <Grid container={true} justify="center" spacing={16}>
      <Grid item={true}>
        <span>{t('MandateRequiredSignatories')}</span>
      </Grid>
      <Grid item={true}>
        <Input
          inputProps={{
            onChange: onClick,
            type: 'select',
            value,
          }}
        >
          {Array(requiredSignatories)
            .fill(0)
            .map((_, k) => (
              <option key={k}>{k + 1}</option>
            ))}
        </Input>
      </Grid>
    </Grid>
  );
};

export default RequiredSignatoriesSelect;
