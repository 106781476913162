import { ActionType, isActionOf } from 'typesafe-actions';
import { AsyncAction } from '../models';
import { AxiosError } from 'axios';

type State = AxiosError | null;
const initialState = null;

export const error = (actions: AsyncAction) => (
  state: State = initialState,
  action: ActionType<any>
) => {
  if (
    isActionOf(actions.request, action) ||
    isActionOf(actions.success, action)
  ) {
    return initialState;
  } else if (isActionOf(actions.failure, action)) {
    return ((action as ActionType<any>).payload as AxiosError) || null;
  }

  return state;
};
