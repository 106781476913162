import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React, { ChangeEvent, Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { expirationDate as expirationDateActions } from 'src/state/Mandates/New';
import * as fromRoot from 'src/state/rootReducer';
import MandateValidTill from '../MandateValidTill';
import { FormControlStyled, Title } from './MandateExpirationDateInput.styles';

export interface Props extends WithNamespaces {
  selectExpirationDate: typeof expirationDateActions.select;
  disableExpirationDate: typeof expirationDateActions.disable;
  date: ReturnType<typeof fromRoot.getNewMandateExpirationDate>;
}

export enum MandateValidity {
  ValidTillRevoked = 'ValidTillRevoked',
  ValidTill = 'ValidTill',
}

interface State {
  mandateValidTill: MandateValidity;
}

export const initializeMandateValidity = (date: Date) =>
  date ? MandateValidity.ValidTill : MandateValidity.ValidTillRevoked;

export class MandateExpirationDateInput extends Component<
  Props,
  Readonly<State>
> {
  readonly state = {
    mandateValidTill: initializeMandateValidity(this.props.date),
  };

  public render() {
    const { t, selectExpirationDate } = this.props;
    const { mandateValidTill } = this.state;

    return (
      <FormControlStyled>
        <Title as="h2">{t('MandateValidTill')}</Title>
        <RadioGroup role="radiogroup">
          <FormControlLabel
            value={MandateValidity.ValidTillRevoked}
            onChange={this.onValidTillRevoked}
            control={
              <Radio
                checked={isValidTillRevoked(mandateValidTill)}
                inputProps={{ role: 'radio' }}
              />
            }
            label={t('ValidTillRevoked')}
          />
          <FormControlLabel
            value={MandateValidity.ValidTill}
            onChange={this.onDateChange.bind(this, this.props.date)}
            control={
              <Radio
                checked={isValidTillDate(mandateValidTill)}
                inputProps={{ role: 'radio' }}
              />
            }
            label={
              <MandateValidTill
                dayPickerProps={{
                  date: this.props.date || '',
                  dayPickerProps: {
                    disabled: [
                      {
                        before: new Date(Date.now()),
                      },
                    ],
                    toMonth: null,
                  },
                  inputProps: {
                    disabled: isValidTillRevoked(this.state.mandateValidTill),
                    'aria-label': t('MandateExpirationDatePicker'),
                  },
                  onClick: selectExpirationDate,
                  label: '',
                }}
              />
            }
          />
        </RadioGroup>
      </FormControlStyled>
    );
  }

  private onDateChange = (date, e: ChangeEvent<any>) => {
    this.setState({ mandateValidTill: e.currentTarget.value });
    this.props.selectExpirationDate(date);
  };

  private onValidTillRevoked = (e: ChangeEvent<any>) => {
    this.setState({ mandateValidTill: e.currentTarget.value });
    this.props.disableExpirationDate();
  };
}

export const isValidTillRevoked = (x: MandateValidity) =>
  x === MandateValidity.ValidTillRevoked;

export const isValidTillDate = (x: MandateValidity) =>
  x === MandateValidity.ValidTill;

const connectedComponent = connect(
  state => ({
    date: fromRoot.getNewMandateExpirationDate(state),
  }),
  {
    selectExpirationDate: expirationDateActions.select,
    disableExpirationDate: expirationDateActions.disable,
  }
)(MandateExpirationDateInput);
export default withNamespaces()(connectedComponent);
