import React from 'react';
import { ISigningCaseListItem } from 'src/models';

export interface IAgreementStatusText {
  status?: ISigningCaseListItem['status'];
  displayedStatusText?: any;
}

const AgreementStatusText = ({ displayedStatusText }: IAgreementStatusText) => {
  return <span>{displayedStatusText}</span>;
};

export default AgreementStatusText;
