import { TableRow } from 'src/components/PortalList/PortalList.styles';
import { transparentBlue } from 'src/styles/colors';
import styled from 'styled-components';
import { mediaMaxWidth2 } from 'src/styles/layout';
import { TableTitle } from 'src/components';

export const MandatesTableRow = styled(TableRow)`
  &:hover {
    background-color: ${transparentBlue};
  }
`;

export const Title = styled(TableTitle)`
  ${mediaMaxWidth2.tabletSmall`
    font-size: 1.2em;
  `}
`;
