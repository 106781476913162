import React, { FC } from 'react';
import SigningDocumentImage from './signing-document.jpg';
import SigningDocumentRdImage from './signing-document-rd.jpg';
import { CoverSection } from './styles';
import { GridProps } from '@material-ui/core/Grid';
import { Config } from 'src/state/Config';

export const SigningDocumentCover: FC<GridProps> = props => (
  <CoverSection style={{ background: getBackground() }} {...props} />
);

export const getBackground = () => {
  return Config.isRealkreditDenmark()
    ? `url(${SigningDocumentRdImage}) no-repeat bottom`
    : `url(${SigningDocumentImage}) no-repeat center`;
};
