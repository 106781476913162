import moment from 'moment';
import { ApiTypes, IDocumentResponse } from 'src/models';
import { DocumentService } from 'src/state/Document';
import { getDocumentContentUrl } from 'src/state/api';

type IsSignatureSuccessfulData = ApiTypes.IsSignatureSuccessfulResponse['data'];
export const isSignatureSuccessful = (
  prevData: IsSignatureSuccessfulData,
  data: IsSignatureSuccessfulData
) => !prevData.success && data.success;

export const isRejected = (prevRejected: boolean, rejected: boolean) =>
  !prevRejected && rejected;

export const getDateShort = (expirationDate: string) =>
  moment(new Date(expirationDate)).format('L');

export const getNextTimeout = (timeout: number) => {
  const milliseconds = 60000;
  return timeout >= milliseconds ? milliseconds : timeout * 2;
};

type OpenDocumentContent = (
  signingCaseId: string,
  contractingPartyId: string,
  documentId: string,
  format: IDocumentResponse['mimeType']
) => void;
export const openDocumentContent: OpenDocumentContent = (
  signingCaseId,
  contractingPartyId,
  documentid,
  format
) => {
  window.open(
    getDocumentContentUrl(
      signingCaseId,
      contractingPartyId,
      documentid,
      DocumentService.mimeTypeToFormat(format)
    ),
    '_blank',
    `resizable=yes, width=600, height=1024`
  );
};
