import { takeLatest } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { revokeMandate } from './actions';
import { ActionType, getType } from 'typesafe-actions';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';

function* revokeMandateSaga({
  payload: { mandateId },
}: ActionType<typeof revokeMandate.request>) {
  try {
    yield call(api.revokeMandate, { mandateId });
    yield put(revokeMandate.success());
    yield put(
      notificationsManager.addNotification({
        text: 'MandateRevokeSuccess',
        type: 'Success',
      })
    );
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
  }
}

export function* watchRevokeMandate() {
  yield takeLatest(getType(revokeMandate.request), revokeMandateSaga);
}
