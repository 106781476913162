import { ApiTypes } from 'src/models';
import actions from './actions';
import ActionTypes from './types';
import {
  Actions as signingCaseActions,
  ActionTypes as SigningCaseActions,
} from '../../SigningCaseDetails';
import { ActionType } from 'typesafe-actions';

const { resetOpenedSigningCase } = signingCaseActions;
type Data = Readonly<ApiTypes.IsSignatureSuccessfulResponse['data']>;
type Actions =
  | ActionType<typeof actions>
  | ActionType<typeof resetOpenedSigningCase>;

interface IState {
  data: Data;
  isLoading: boolean;
}

const initialState = {
  data: {
    signatureText: '',
    success: false,
  },
  isLoading: false,
};

const reducer = (state: IState = initialState, action: Actions) => {
  if (action.type === ActionTypes.SIGN_DOCUMENT_REQUEST) {
    return {
      data: { ...initialState.data },
      isLoading: true,
    };
  } else if (action.type === ActionTypes.SIGN_DOCUMENT_UPDATE) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (action.type === ActionTypes.SIGN_DOCUMENT_SUCCESS) {
    return {
      data: action.payload,
      isLoading: false,
    };
  } else if (
    action.type === ActionTypes.SIGN_DOCUMENT_FAILURE ||
    action.type === SigningCaseActions.RESET_OPENED_SIGNING_CASE
  ) {
    return initialState;
  }

  return state;
};

export default reducer;
