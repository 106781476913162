import { action, ActionType } from 'typesafe-actions';
import { prefix } from '../types';

export const SELECT_EXPIRATION_DATE = `${prefix}/SELECT_EXPIRATION_DATE`;
export const DISABLE_EXPIRATION_DATE = `${prefix}/DISABLE_EXPIRATION_DATE`;

export const expirationDate = {
  select: (props: Date | null) => action(SELECT_EXPIRATION_DATE, props),
  disable: () => action(DISABLE_EXPIRATION_DATE),
};

export type ExpirationDate = ActionType<typeof expirationDate>;
