import React from 'react';
import { DocumentName, DocumentNameSubtitle } from './DocumentTitle.styles';
import { t } from 'i18next';

interface Props {
  name: string;
  hash?: string;
  isBold?: boolean;
}

const DocumentTitle = ({ hash, name, isBold = false }: Props) => {
  const Title = (
    <DocumentName data-testid="document-name" isBold={isBold}>
      {name}
    </DocumentName>
  );

  if (hash) {
    return (
      <>
        {Title}
        <DocumentNameSubtitle data-testid="document-subtitle">
          {`${t('DocumentKey')}: ${hash}`}
        </DocumentNameSubtitle>
      </>
    );
  } else {
    return Title;
  }
};

export default DocumentTitle;
