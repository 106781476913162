import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';
import { TableHead, TableHeaderCell } from 'src/components';
import { t } from 'i18next';

interface ISigneesList {
  showHeader?: boolean;
  showRoleHeader?: boolean;
  render?: (props?: any) => JSX.Element;
  children?: ReactNode;
}

const SigneesListFrame = ({
  showHeader = true,
  showRoleHeader = true,
  render,
  children,
}: ISigneesList) => {
  return (
    <Table className="m-0">
      {showHeader ? (
        <TableHead>
          <tr>
            <TableHeaderCell>
              {showRoleHeader ? t('NameRole') : t('Name')}
            </TableHeaderCell>
            <TableHeaderCell>{t('Status')}</TableHeaderCell>
          </tr>
        </TableHead>
      ) : null}
      <tbody>{render ? render() : children}</tbody>
    </Table>
  );
};

export default SigneesListFrame;
