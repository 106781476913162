import { css } from 'styled-components';
import refsignFontEot from 'src/assets/fonts/322030_2_0.eot';
import refsignFontWoff from 'src/assets/fonts/322030_2_0.woff';
import dansketextRegularEot from 'src/assets/fonts/dansketext-regular.eot';
import dansketextRegularWoff from 'src/assets/fonts/dansketext-regular.woff';
import dansketextSemiboldEot from 'src/assets/fonts/dansketext-semibold.eot';
import dansketextSemiboldWoff from 'src/assets/fonts/dansketext-semibold.woff';
import dansketextSemibolditalicEot from 'src/assets/fonts/dansketext-semibolditalic.eot';
import dansketextSemibolditalicWoff from 'src/assets/fonts/dansketext-semibolditalic.woff';
import DanskeHumanMediumItalicEot from 'src/assets/fonts/DanskeHuman-MediumItalic.eot';
import DanskeHumanMediumItalicWoff from 'src/assets/fonts/DanskeHuman-MediumItalic.woff';
import DanskeHumanMediumItalicSvg from 'src/assets/fonts/DanskeHuman-MediumItalic.svg';
import danskeheadlineWebfontEot from 'src/assets/fonts/danskeheadline-webfont.eot';
import danskeheadlineWebfontWoff from 'src/assets/fonts/danskeheadline-webfont.woff';
import danskeheadlineWebfontTtf from 'src/assets/fonts/danskeheadline-webfont.ttf';
import danskeheadlineWebfontSvg from 'src/assets/fonts/danskeheadline-webfont.svg';
import danskeNarrowEot from 'src/assets/fonts/DanskeNarrow.eot';
import danskeNarrowWoff from 'src/assets/fonts/DanskeNarrow.woff';
import danskeNarrowTtf from 'src/assets/fonts/DanskeNarrow.ttf';
import danskeNarrowSvg from 'src/assets/fonts/DanskeNarrow.svg';
import danskeheadlineboldWebfontEot from 'src/assets/fonts/danskeheadlinebold-webfont.eot';
import danskeheadlineboldWebfontWoff from 'src/assets/fonts/danskeheadlinebold-webfont.woff';
import danskeheadlineboldWebfontTtf from 'src/assets/fonts/danskeheadlinebold-webfont.ttf';
import danskeheadlineboldWebfontSvg from 'src/assets/fonts/danskeheadlinebold-webfont.svg';
import danskeNarrowSemiboldEot from 'src/assets/fonts/DanskeNarrowSemibold.eot';
import danskeNarrowSemiboldWoff from 'src/assets/fonts/DanskeNarrowSemibold.woff';
import danskeNarrowSemiboldTtf from 'src/assets/fonts/DanskeNarrowSemibold.ttf';
import danskeNarrowSemiboldSvg from 'src/assets/fonts/DanskeNarrowSemibold.svg';
import danskeNarrowItalicEot from 'src/assets/fonts/DanskeNarrow-Italic.eot';
import danskeNarrowItalicWoff from 'src/assets/fonts/DanskeNarrow-Italic.woff';
import danskeNarrowItalicTtf from 'src/assets/fonts/DanskeNarrow-Italic.ttf';
import danskeNarrowItalicSvg from 'src/assets/fonts/DanskeNarrow-Italic.svg';

export default css`
  @font-face {
    font-family: 'Refsign';
    src: url(${refsignFontEot});
    src: url(${refsignFontEot}?#iefix) format('embedded-opentype'),
      url(${refsignFontWoff}) format('woff');
    font-weight: 400 !important;
    font-style: normal;
  }

  @font-face {
    font-family: 'Danske';
    src: url(${dansketextRegularEot});
    src: url(${dansketextRegularEot}?#iefix) format('embedded-opentype'),
      url(${dansketextRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Danske';
    src: url(${dansketextSemiboldEot});
    src: url(${dansketextSemiboldEot}?#iefix) format('embedded-opentype'),
      url(${dansketextSemiboldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Danske';
    src: url(${dansketextSemibolditalicEot});
    src: url(${dansketextSemibolditalicEot}?#iefix) format('embedded-opentype'),
      url(${dansketextSemibolditalicWoff}) format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'DanskeHuman';
    src: url(${DanskeHumanMediumItalicEot});
    src: url(${DanskeHumanMediumItalicEot}?#iefix) format('embedded-opentype'),
      url(${DanskeHumanMediumItalicWoff}) format('woff'),
      url(${DanskeHumanMediumItalicSvg}) format('svg');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'DanskeHuman';
    src: url(${DanskeHumanMediumItalicEot});
    src: url(${DanskeHumanMediumItalicEot}?#iefix) format('embedded-opentype'),
      url(${DanskeHumanMediumItalicWoff}) format('woff'),
      url(${DanskeHumanMediumItalicSvg}) format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DanskeHeadline';
    src: url(${danskeheadlineWebfontEot});
    src: url(${danskeheadlineWebfontEot}?#iefix) format('embedded-opentype'),
      url(${danskeheadlineWebfontWoff}) format('woff'),
      url(${danskeheadlineWebfontTtf}) format('truetype'),
      url(${danskeheadlineWebfontSvg}) format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DanskeHeadline';
    src: url(${danskeheadlineboldWebfontEot});
    src: url(${danskeheadlineboldWebfontEot}?#iefix) format('embedded-opentype'),
      url(${danskeheadlineboldWebfontWoff}) format('woff'),
      url(${danskeheadlineboldWebfontTtf}) format('truetype'),
      url(${danskeheadlineboldWebfontSvg}) format('svg');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'DanskeNarrow';
    src: url(${danskeNarrowEot});
    src: url(${danskeNarrowEot}?#iefix) format('embedded-opentype'),
      url(${danskeNarrowWoff}) format('woff'),
      url(${danskeNarrowTtf}) format('truetype'),
      url(${danskeNarrowSvg}#DanskeNarrow) format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'DanskeNarrow';
    src: url(${danskeNarrowSemiboldEot});
    src: url(${danskeNarrowSemiboldEot}?#iefix) format('embedded-opentype'),
      url(${danskeNarrowSemiboldWoff}) format('woff'),
      url(${danskeNarrowSemiboldTtf}) format('truetype'),
      url(${danskeNarrowSemiboldSvg}#DanskeNarrowSemibold) format('svg');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'DanskeNarrow';
    src: url(${danskeNarrowItalicEot});
    src: url(${danskeNarrowItalicEot}?#iefix) format('embedded-opentype'),
      url(${danskeNarrowItalicWoff}) format('woff'),
      url(${danskeNarrowItalicTtf}) format('truetype'),
      url(${danskeNarrowItalicSvg}#DanskeNarrow-Italic) format('svg');
    font-weight: 400;
    font-style: italic;
  }
`;
