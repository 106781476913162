import React from 'react';
import {
  AgreementStatusIcon,
  AgreementStatusText,
  IAgreementStatusIcon,
} from 'src/components/PortalList/PortalAgreementStatus';
import { StatusSubtitle } from './styles';

export const SigningCaseStatus = ({
  color,
  status,
  className,
  displayedStatusText,
}: IAgreementStatusIcon) => (
  <span className={className}>
    <AgreementStatusIcon status={status} color={color} />
    <StatusSubtitle>
      <AgreementStatusText
        status={status}
        displayedStatusText={displayedStatusText}
      />
    </StatusSubtitle>
  </span>
);
