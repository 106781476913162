import { createMuiTheme } from '@material-ui/core/styles';
import {
  darkBlue,
  bluePrimary,
  darkGreenPrimary,
  backgroundColor,
  darkRedPrimary,
  white,
  lightGreen,
  lightBlue,
  makeTransparent,
  darkOrangePrimary,
  rdBlack,
  rdRed,
} from './colors';
import { Config } from '../state/Config';
import { DefaultTheme } from 'styled-components';

export const materialTheme = createMuiTheme({
  /* tslint:disable object-literal-sort-keys */
  breakpoints: {
    values: {
      xs: 320,
      sm: 480,
      md: 601,
      lg: 768,
      xl: 970,
    },
    /* tslint:enable */
  },
  palette: {
    primary: {
      main: darkBlue,
    },
    secondary: {
      main: darkBlue,
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export const defaultTheme: any = {
  pallete: {
    primary: {
      main: darkBlue,
      contrastText: white,
    },
    secondary: {
      main: bluePrimary,
      contrastText: white,
    },
    headers: {
      main: darkBlue,
    },
    action: {
      hover: bluePrimary,
      active: white,
      filterActive: lightBlue,
      timeframeActive: makeTransparent(bluePrimary),
    },
    buttons: {
      primary: bluePrimary,
      secondary: backgroundColor,
      error: darkRedPrimary,
      success: darkGreenPrimary,
      secondaryText: darkBlue,
    },
    backgrounds: {
      hamburgerMenu: darkBlue,
      success: lightGreen,
      error: '#F9DAD8',
      primary: lightBlue,
      header: white,
      mandate: lightGreen,
      modalHeader: darkOrangePrimary,
    },
    borders: {
      signingRules: bluePrimary,
      filter: bluePrimary,
      filterBottom: bluePrimary,
    },
  },
  typography: {
    fontFamily: `"Danske", "Tahoma", Sans-Serif`,
    fontWeight: 400,
    header: {
      fontFamily: 'DanskeHuman',
    },
    loaderMessage: {
      fontSize: '2em',
    },
    dialogHeader: {
      fontWeight: 400,
    },
  },
};

export const realkreditDenmark: any = {
  pallete: {
    primary: {
      main: rdBlack,
      contrastText: white,
    },
    secondary: {
      main: rdRed,
      contrastText: white,
    },
    headers: {
      main: rdRed,
    },
    action: {
      hover: rdRed,
      active: white,
      filterActive: '#e0e0e0',
      timeframeActive: makeTransparent(rdBlack),
    },
    buttons: {
      primary: rdRed,
      secondary: backgroundColor,
      error: rdRed,
      success: rdRed,
      secondaryText: darkBlue,
    },
    backgrounds: {
      hamburgerMenu: '#8c8c8c',
      success: lightGreen,
      error: white,
      primary: white,
      mandate: white,
      header: '#f0f0f0',
      modalHeader: rdRed,
    },
    borders: {
      signingRules: '#737373',
      filter: rdBlack,
      filterBottom: '#c4c4c4',
    },
  },
  typography: {
    fontFamily: `"Trebuchet MS", "Tahoma", Sans-Serif`,
    fontWeight: 400,
    header: {
      fontFamily: 'Refsign',
    },
    loaderMessage: {
      fontSize: '3em',
    },
    dialogHeader: {
      fontWeight: 700,
    },
  },
};

export const getPrimaryColor = (theme: any) => theme.pallete.primary.main;
export const getSecondaryColor = (theme: any) => theme.pallete.secondary.main;

export type Color = 'primary' | 'secondary' | 'inherit';
export const getThemeColor = (color: Color, theme: any): string => {
  return color === 'primary'
    ? getPrimaryColor(theme)
    : color === 'secondary'
    ? getSecondaryColor(theme)
    : 'inherit';
};

export const getLoaderMessageFontSize = theme =>
  theme.typography.loaderMessage.fontSize;

export const getHeaderFontFamily = theme => theme.typography.header.fontFamily;
export const getHeaderColor = theme => theme.pallete.headers.main;

type CreateTheme = (config: typeof Config) => DefaultTheme;
export const getTheme: CreateTheme = config => {
  if (config.isRealkreditDenmark()) {
    return realkreditDenmark;
  }

  return defaultTheme;
};
