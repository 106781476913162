import React from 'react';
import { Table, TableHead, TableHeaderCell } from 'src/components';
import { IMandatedSignatory } from 'src/models';
import { SignatoryTableRow } from './components';
import { t } from 'i18next';

interface ISignatories {
  signatories: IMandatedSignatory[];
}

const Signatories = ({ signatories }: ISignatories) => {
  return (
    <Table>
      <TableHead>
        <tr>
          <TableHeaderCell>{t('MandateSignatories')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>
        {signatories.map((signatory, index) => (
          <SignatoryTableRow key={index} signatory={signatory} />
        ))}
      </tbody>
    </Table>
  );
};

export default Signatories;
