import React, { FC } from 'react';
import { ISigningMandateDetails } from 'src/models';
import MandatesTableRow from '../MandatesTableRow';
import { MandatesListType } from 'src/pages/Mandates/models';

export interface IMandatesList {
  list: ISigningMandateDetails[];
  type: MandatesListType;
}

const MandatesList: FC<IMandatesList> = props => {
  const { list, children, ...rest } = props;

  return (
    <>
      {list.map((mandate, index) => (
        <MandatesTableRow key={index} mandate={mandate} {...rest} />
      ))}
    </>
  );
};

export default MandatesList;
