import moment from 'moment';
import { call, put, takeEvery } from 'redux-saga/effects';
import { SigningCaseStatusApi } from 'src/models';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import Actions from './actions';
import ActionTypes from './types';

type TfetchSigningCases = ReturnType<typeof Actions['fetchSigningCases']>;

type TransformStatus = (status: SigningCaseStatusApi) => SigningCaseStatusApi;
export const transformStatus: TransformStatus = status =>
  status.match(/^Archived/) ? 'Archived' : status;

function* fetchSigningCases(action: TfetchSigningCases) {
  const status = transformStatus(action.payload.status);

  try {
    yield put(Actions.fetchSigningCasesRequest(status));
    const signingCases = yield call(api.fetchSigningCase, action.payload);
    const hasNextPage = action.payload.pageSize
      ? action.payload.pageSize <= signingCases.data.length
      : false;

    yield put(
      Actions.fetchSigningCasesSuccess({
        hasNextPage,
        list: signingCases.data.map(x => ({
          ...x,
          date: moment(x.date).format('L'),
        })),
        page: action.payload.pageNo || 1,
        status,
        isLoading: false,
      })
    );
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
    yield put(Actions.fetchSigningCaseListFailure(status));
  }
}

export function* watchFetchSigningCasesList() {
  yield takeEvery(ActionTypes.FETCH_SIGNING_CASES, fetchSigningCases);
}
