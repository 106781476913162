import emptyDocImage from 'src/assets/images/icons/document.svg';
import pdfImage from 'src/assets/images/icons/pdf.svg';
import styled from 'styled-components';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  type: string;
}

const getFileImgSource = (type: Props['type']): string =>
  type && type.toLocaleLowerCase() === 'pdf' ? pdfImage : emptyDocImage;

export const FileIcon = styled.img.attrs((props: Props) => ({
  alt: 'File icon',
  src: getFileImgSource(props.type),
}))<Props>`
  width: 40px;
  height: 40px;
`;
