import { IContractingParty, IDocumentResponse } from 'src/models';

type TDocuments = IContractingParty['documents'];
export const isDocumentSeen = (isRead: IDocumentResponse['isRead']): boolean =>
  isRead;

export const getSeenDocuments = (documents: TDocuments): TDocuments =>
  documents.filter(document => isDocumentSeen(document.isRead));

export const areDocumentsSeen = (documents: TDocuments): boolean =>
  documents.length === getSeenDocuments(documents).length;
