import styled, { css } from 'styled-components';
import { darkGrey, interactiveLink, white } from 'src/styles/colors';
import { PortalButton } from 'src/components/Buttons';

export const CookieButton = styled(PortalButton)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px 32px;
  min-height: 48px;

  @media only screen and (max-width: 47.9375em) {
    position: relative;
    margin-top: 15px;
    min-width: 100px;
  }
`;

export const CookieContainer = styled.div`
  min-height: 100px;
  background-color: ${darkGrey};
  opacity: 0.95;
  z-index: 1010;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: ${white};
  display: table;
  table-layout: fixed;
`;

export const SectionContainer = styled.section`
  padding: 35px 0;
  margin: auto;
  position: relative;
  max-width: 1440px;
  display: table-cell;
  vertical-align: middle;

  & > svg {
    width: 1.75rem !important;
    height: 1.75rem !important;
    min-width: 1.75rem;
    min-height: 1.75rem;
    position: absolute;
    left: 0;
    top: 2px;
    vertical-align: text-top;
    display: inline-block;
    fill: currentColor;
  }
`;

export const SectionInner = styled.div`
  padding: 0;
  position: relative;
  margin: auto;

  @media print, screen and (min-width: 48em) {
    padding-left: 3px;
    padding-right: 3px;
    margin: auto;
    max-width: 83.33333%;
  }

  @media screen and (min-width: 90.0625em) {
    margin: auto;
    max-width: 1200px;
  }
`;

export const SectionRow = styled.div`
  width: auto;
  max-width: none;
  margin-right: auto;
  margin-left: auto;
`;

export const SectionColumn = styled.div`
  padding: 0;
  width: 100%;
  float: left;
  text-align: center;
`;

export const CookieDisclaimerText = styled.p`
  font-size: 0.875rem;
  padding: 0 125px 0 63px;
  margin: 0;
  font-weight: 400;
  line-height: 1.6;

  & > a {
    color: ${interactiveLink};
    font-weight: bold;
  }

  & > a:hover {
    color: ${interactiveLink};
    font-weight: bold;
    text-decoration: underline;
  }

  & > svg {
    fill: ${white};
  }

  @media only screen and (max-width: 47.9375em) {
    font-size: 0.75rem;
    padding: 0 48px;
    text-align: center;
  }

  @media print, screen and (min-width: 48em) {
    font-size: 1rem;
    line-height: 1.375rem;
    padding: 0 125px 0 63px;
    text-align: left;
  }
`;

export const CloseButton = styled.a`
  width: 0.9375rem;
  height: 0.9375rem;
  line-height: 0.9375rem;
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 1.125rem;
  color: ${white};
  cursor: pointer;

  @media only screen and (max-width: 47.9375em) {
    width: 0.875rem;
    height: 0.875rem;
    top: 15px;
    right: 15px;
    line-height: 0.875rem;
  }
`;

export const CloseIcon = styled.svg`
  width: 0.9375rem;
  height: 0.9375rem;
  position: absolute;
  left: 0;
  top: 2px;
  vertical-align: text-top;
  display: inline-block;
  fill: ${white};

  @media only screen and (max-width: 47.9375em) {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

export const InboxIcon = styled.svg`
  width: 1.75rem !important;
  height: 1.75rem !important;
  min-width: 1.75rem;
  min-height: 1.75rem;
  position: absolute;
  left: 0;
  top: 2px;
  vertical-align: text-top;
  display: inline-block;
  fill: currentColor;

  @media only screen and (max-width: 47.9375em) {
    display: none;
  }
`;

const linkStyles = css`
  color: ${interactiveLink};
  transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;

  &:hover {
    color: ${interactiveLink};
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const Link = styled.a`
  ${linkStyles}
`;

export const LinkButton = styled.button`
  ${linkStyles};
  border: none;
  background: transparent;
  padding: 0;
  font-weight: bold;
`;
