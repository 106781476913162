import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as fromRoot from 'src/state/rootReducer';
import { logOut as logOutAction } from 'src/state/UserSession';
import {
  setCurrentDate,
  refreshSession as refreshSessionAction,
} from 'src/state/UserSession';
import moment from 'moment';
import SessionTimeoutDialog from 'src/components/Modals/SessionTimeoutDialog';

interface Props {
  currentDate?: moment.Moment;
  userSessionExpirationDate?: moment.Moment;
  setCurrentDate: typeof setCurrentDate;
  refreshSession: typeof refreshSessionAction.request;
  logOut: typeof logOutAction;
}

const timeTreshold = 60;
const timeTresholdOffset = 15;
let timeoutId = 0;

const SessionTimeout = ({
  currentDate,
  userSessionExpirationDate,
  setCurrentDate,
  refreshSession,
  logOut,
}: Props) => {
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);
  const [timeout, setTimeoutState] = useState(
    timeTreshold - timeTresholdOffset
  );

  const cancel = () => {
    logOut();
    setShowTimeoutDialog(false);
  };

  const refresh = () => {
    refreshSession();
    setShowTimeoutDialog(false);
  };

  useEffect(() => {
    if (currentDate && userSessionExpirationDate) {
      const secondsTillExpirationDate = userSessionExpirationDate.diff(
        currentDate,
        'seconds'
      );

      if (secondsTillExpirationDate > timeTreshold) {
        clearTimeout(timeoutId);
        setTimeoutState(timeTreshold - timeTresholdOffset);
        const secondsTillTimeTreshold =
          secondsTillExpirationDate - timeTreshold;
        const newTimeoutId = setTimeout(() => {
          const nextCurrentDate = currentDate
            .clone()
            .add(secondsTillTimeTreshold, 'seconds');
          setCurrentDate(nextCurrentDate);
        }, secondsTillTimeTreshold * 1000);
        timeoutId = newTimeoutId;
      } else {
        const newTimeout = secondsTillExpirationDate - timeTresholdOffset;
        setTimeoutState(newTimeout > 0 ? newTimeout : 0);
        setShowTimeoutDialog(true);
      }
    }
  }, [
    currentDate,
    userSessionExpirationDate,
    setCurrentDate,
    setShowTimeoutDialog,
  ]);

  if (showTimeoutDialog) {
    return (
      <SessionTimeoutDialog
        timeout={timeout}
        onCancel={cancel}
        onConfirm={refresh}
        onCountdownComplete={cancel}
      />
    );
  } else {
    return null;
  }
};

const ExtendedComponent = connect(
  state => ({
    currentDate: fromRoot.getCurrentDate(state),
    userSessionExpirationDate: fromRoot.getUserSessionExpirationDate(state),
  }),
  {
    setCurrentDate,
    refreshSession: refreshSessionAction.request,
    logOut: logOutAction,
  }
)(SessionTimeout);

export { ExtendedComponent as SessionTimeout };
