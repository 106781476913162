import { call, put, takeLatest } from 'redux-saga/effects';
import * as Api from 'src/state/api';
import { createMandate, CreateMandate } from './actions';

function* createMandateSaga(
  action: ReturnType<typeof createMandate['request']>
): Generator {
  try {
    const response: any = yield call(Api.createMandate, action.payload);
    yield put(createMandate.success(response.data));
  } catch (e) {
    yield put(createMandate.failure(e));
  }
}

export function* watchCreateMandate(): Generator {
  yield takeLatest(CreateMandate.REQUEST, createMandateSaga);
}
