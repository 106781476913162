import React from 'react';
import { MessageScreen } from '../../components';
import { Card, CardBorderTop } from '../../components/Card';
import { SigningDocumentCover } from '../../components/Cover';

interface Props {
  title: string;
  info?: string;
}

const TechnicalError = (props: Props) => {
  return (
    <>
      <SigningDocumentCover />
      <Card popout={true} showNotifications={false}>
        <CardBorderTop />
        <MessageScreen {...props} />
      </Card>
    </>
  );
};

export default TechnicalError;
