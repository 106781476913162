import React, { MouseEvent, HTMLProps } from 'react';
import moment from 'moment';
import { Card, CardHeader } from 'src/components';
import { MandatedSignatories } from 'src/models';
import Signatories from '../Signatories';
import Validity from '../Validity';
import { PortalButton } from 'src/components/Buttons';
import { Grid } from '@material-ui/core';
import { ActionMenu } from 'src/components/Menu';
import { t } from 'i18next';

interface Props extends HTMLProps<HTMLElement> {
  creationDate: string;
  expirationDate: string;
  title: string;
  subtitle: string;
  mandatedSignatories: MandatedSignatories;
  isRevokable: boolean;
  onRevoke: (e: MouseEvent<HTMLButtonElement>) => void;
}

const MandatesCard = ({
  creationDate,
  expirationDate,
  mandatedSignatories,
  subtitle,
  title,
  onRevoke,
  isRevokable,
}: Props) => {
  return (
    <Card>
      <CardHeader
        title={title}
        subtitle={subtitle}
        StatusComponent={Validity}
        statusComponentProps={{
          from: moment(creationDate).format('L'),
          to: moment(expirationDate).format('L'),
        }}
      />
      <Signatories signatories={mandatedSignatories} />
      <ActionMenu>
        <Grid container justify="flex-end">
          <Grid item xs={12} md={'auto'}>
            <PortalButton
              colorType="error"
              disabled={!isRevokable}
              fullWidth
              onClick={onRevoke}
            >
              {t('RevokeMandate')}
            </PortalButton>
          </Grid>
        </Grid>
      </ActionMenu>
    </Card>
  );
};

export default MandatesCard;
