import React from 'react';
import { ISigningRules } from './ISigningRules';
import { SigningRulesContainer } from './SigningRules.styles';
import SigningRulesList from './SigningRulesList';

const SigningRules = ({ rules, className }: ISigningRules) => {
  return rules.length ? (
    <SigningRulesContainer className={className}>
      <SigningRulesList rules={rules} />
    </SigningRulesContainer>
  ) : null;
};

export default SigningRules;
