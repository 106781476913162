import { DeadlineDate } from 'src/components/PortalList/PortalDeadlineDate';
import {
  Subtitle,
  TableRow,
} from 'src/components/PortalList/PortalList.styles';
import { ISigningCaseListItem } from 'src/models';
import { lightBlue, transparentBlue } from 'src/styles/colors';
import { mediaMaxWidth, mediaMaxWidth2 } from 'src/styles/layout';
import { isSigningCaseReadyToSign } from 'src/utils/SigningCase';
import styled, { CSSObject } from 'styled-components';
export { TableTitle } from './TableTitle';

export const Table = styled.table`
  margin: 0;
  table-layout: fixed;
  width: 100%;
`;

export const StatusSubtitle = styled(Subtitle)`
  ${mediaMaxWidth2.tabletSmall`
    display: none;
  `}
`;

export const TableHeaderGeneric = styled.th`
  padding: 20px 5px 15px;
  border: none;
  font-weight: 700;
`;

export const TableHeaderCell = styled(TableHeaderGeneric)`
  &:first-child {
    width: 50%;
    padding-left: 20px;
  }

  &:last-child:not(:first-child) {
    padding-right: 20px;
    text-align: right;
  }
`;

interface ITableRow {
  contractingPartyType: ISigningCaseListItem['contractingPartyType'];
  documentStatus: ISigningCaseListItem['status'];
}

const isCompanyDocumentNotSigned = (
  contractingPartyType: ISigningCaseListItem['contractingPartyType'],
  documentStatus: ISigningCaseListItem['status']
): boolean => {
  return (
    contractingPartyType === 'Company' &&
    isSigningCaseReadyToSign(documentStatus)
  );
};

const getBackgroundColor = (props: ITableRow) => {
  if (
    isCompanyDocumentNotSigned(props.contractingPartyType, props.documentStatus)
  ) {
    return '#EFF6F9';
  } else {
    return 'white';
  }
};

const getBackgroundHoverColor = (props: ITableRow) => {
  if (
    isCompanyDocumentNotSigned(props.contractingPartyType, props.documentStatus)
  ) {
    return lightBlue;
  } else {
    return transparentBlue;
  }
};

export const getTableBorder = (color: string): string =>
  `inset 15px 0px 0px -7px ${color}`;

export const AgreementTableRow = styled(TableRow)`
  background-color: ${getBackgroundColor};

  &:hover {
    background-color: ${getBackgroundHoverColor};
  }
`;

export const TableHead = styled.thead`
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);

  ${mediaMaxWidth2.tabletSmall`
    display: none;
    border-bottom: 1px solid rgba(0,0,0,0.6);
  `}
`;

export const TableCellFirstPadding = `15px 5px 15px 20px`;
export const TableCellLastPadding = `15px 20px 15px 5px`;

interface TableCellProps {
  align?: CSSObject['textAlign'];
  cursor?: string;
}

export const TableCell = styled.td<TableCellProps>`
  cursor: ${({ cursor }) => cursor || 'pointer'};
  text-align: ${({ align }) => align};
  padding: 0;
  border: none;
`;

export const TableCellBig = styled(TableCell)`
  padding: 15px 5px;
  ${mediaMaxWidth2.tabletSmall`
    display: none;
  `}
`;

export const TableCellTitle = styled(TableCell)`
  box-shadow: ${({ color }) => getTableBorder(color || 'inherit')};
  padding: 15px 5px 15px 20px;
  ${mediaMaxWidth2.tabletSmall`
    display: block;
  `}
`;

export const TableCellMobile = styled(TableCell)`
  display: none;
  padding-left: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  ${mediaMaxWidth2.tabletSmall`
    display: block;
    box-shadow: ${(props: any) => getTableBorder(props.color || 'inherit')};
  `}
`;

export const TableCellFirst = styled(TableCell)`
  padding: ${TableCellFirstPadding};
`;

interface TableCellLastProps {
  hide?: boolean;
}

export const TableCellLast = styled(TableCell)<TableCellLastProps>`
  padding: ${TableCellLastPadding};
  ${({ hide }) =>
    hide &&
    mediaMaxWidth2.tabletSmall`
    display: none;
  `}
`;

export const AgreementDeadlineMobile = styled(DeadlineDate)`
  display: inline-block;
  width: 50%;
  ${mediaMaxWidth.tabletSmall(`
    font-size: 0.8em;
  `)}
`;
