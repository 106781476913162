import { css } from 'styled-components';
import dansketextRegularEot from '../assets/fonts/dansketext-regular.eot';
import dansketextRegularWoff from '../assets/fonts/dansketext-regular.woff';
import dansketextSemiboldEot from '../assets/fonts/dansketext-semibold.eot';
import dansketextSemiboldWoff from '../assets/fonts/dansketext-semibold.woff';
import dansketextSemiboldItalicEot from '../assets/fonts/dansketext-semibolditalic.eot';
import dansketextSemiboldItalicWoff from '../assets/fonts/dansketext-semibolditalic.woff';
import danskeHumanMediumItalicEot from '../assets/fonts/DanskeHuman-MediumItalic.eot';
import danskeHumanMediumItalicWoff from '../assets/fonts/DanskeHuman-MediumItalic.woff';
import danskeHumanMediumItalicSvg from '../assets/fonts/DanskeHuman-MediumItalic.svg';

export default css`
  @font-face {
    font-family: 'Danske';
    src: url(${dansketextRegularEot});
    src: url(${dansketextRegularEot}?#iefix) format('embedded-opentype'),
      url(${dansketextRegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Danske';
    src: url(${dansketextSemiboldEot});
    src: url(${dansketextSemiboldEot}?#iefix) format('embedded-opentype'),
      url(${dansketextSemiboldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Danske';
    src: url(${dansketextSemiboldItalicEot});
    src: url(${dansketextSemiboldItalicEot}?#iefix) format('embedded-opentype'),
      url(${dansketextSemiboldItalicWoff}) format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'DanskeHuman';
    src: url(${danskeHumanMediumItalicEot});
    src: url(${danskeHumanMediumItalicEot}?#iefix) format('embedded-opentype'),
      url(fonts/DanskeHuman-MediumItalic.woff) format('woff'),
      url(fonts/DanskeHuman-MediumItalic.svg) format('svg');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'DanskeHuman';
    src: url(${danskeHumanMediumItalicEot});
    src: url(${danskeHumanMediumItalicEot}?#iefix) format('embedded-opentype'),
      url(${danskeHumanMediumItalicWoff}) format('woff'),
      url(${danskeHumanMediumItalicSvg}) format('svg');
    font-weight: 400;
    font-style: normal;
  }
`;
