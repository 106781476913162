import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReturnButton } from 'src/components/Buttons';
import { LoadingCard } from 'src/components/Loaders';
import { ProductAreas, fetchMandate, revokeMandate } from 'src/state/Mandates';
import * as fromRoot from 'src/state/rootReducer';
import { MandateCard, SigningRulesCard } from './components';
import { Card } from 'src/components';
import { SigningDocumentCover } from 'src/components/Cover';
import RejectDialog from 'src/components/Modals/RejectDialog/RejectDialog';

type MandateRoute = RouteComponentProps<{
  id: string;
}>;
interface IDetails extends MandateRoute, WithNamespaces {
  mandate: ReturnType<typeof fromRoot.getMandate>;
  isLoading: boolean;
  fetchData: typeof fetchMandate.request;
  revoke: typeof revokeMandate.request;
}

const initialState = {
  showDialog: false,
};

type State = Readonly<typeof initialState>;

class Details extends Component<IDetails, State> {
  state = initialState;

  componentDidMount() {
    if (!this.props.mandate) {
      const { id } = this.props.match.params;
      this.props.fetchData({ id });
    }
  }

  public render() {
    const { mandate, isLoading, t } = this.props;
    const productAreas =
      mandate &&
      ProductAreas.displayProductAreas(
        t,
        mandate.mandateLimits,
        mandate.requiredMandatedSignatoriesCount,
        mandate.mandatedSignatories.length
      );

    return (
      <>
        {this.isDialogOpen() && (
          <RejectDialog
            title={t('MandateConfirmation')}
            onCancel={this.closeDialog}
            onConfirm={this.revoke}
          >
            <p>
              <span>{t('MandateRevokeConfirmLabel')} - </span>
              <strong>{productAreas}?</strong>
            </p>
          </RejectDialog>
        )}
        <SigningDocumentCover />
        {mandate ? (
          <>
            <MandateCard
              creationDate={mandate.creationDate}
              expirationDate={mandate.expirationDate}
              mandatedSignatories={mandate.mandatedSignatories}
              title={productAreas!}
              subtitle={ProductAreas.joinMandatedSignatoriesNames(
                mandate.mandatedSignatories
              )}
              isRevokable={this.isRevokable()}
              onRevoke={this.openDialog}
            />
            {mandate.mandateLimits.length ? (
              <SigningRulesCard limits={mandate.mandateLimits} />
            ) : null}
          </>
        ) : isLoading ? (
          <LoadingCard />
        ) : (
          <Card showNotifications={true} />
        )}
        <ReturnButton
          linkProps={{
            to: '/Mandates',
          }}
        >
          {t('Back')}
        </ReturnButton>
      </>
    );
  }

  private openDialog = () => {
    this.setState({ showDialog: true });
  };

  private closeDialog = () => {
    this.setState({ showDialog: false });
  };

  private isDialogOpen = () => this.state.showDialog;

  private revoke = () => {
    this.props.revoke({ mandateId: this.props.mandate!.id });
    this.props.history.push('/Mandates');
  };

  private isRevokable = () => {
    const mandate = this.props.mandate;

    return mandate
      ? mandate.isOnlyDigitalMandate &&
          (mandate.state === 'Active' || mandate.state === 'New')
      : false;
  };
}

const mapStateToProps = (state, props: MandateRoute) => ({
  mandate: fromRoot.getMandate(state, props.match.params.id),
  isLoading: fromRoot.getIsMandateLoading(state),
});

const connectedComponent = connect(
  mapStateToProps,
  {
    fetchData: fetchMandate.request,
    revoke: revokeMandate.request,
  }
)(Details);

const ExtendedComponent = withRouter(withNamespaces()(connectedComponent));
export { ExtendedComponent as Details };
export default Details;
