import React, { Component, HTMLAttributes } from 'react';
import { TNavigationLinks } from 'src/models/NavigationLink';
import { ThemeProvider } from 'styled-components';
import { darkBlue } from '../../styles/colors';
import DropdownMenu from '../DropdownMenu';
import HamburgerButton from './HamburgerButton';
import { HamburgerMenu } from './HamburgerMenu.styles';
import { withTheme, DefaultTheme } from 'styled-components';
import { LanguageMenuLink } from 'src/routes';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props extends HTMLAttributes<HTMLElement>, WithNamespaces {
  links: TNavigationLinks;
  awaitingSigningCasesCount: number;
  theme: DefaultTheme;
  languageCode: string;
  languageMenuLinks: LanguageMenuLink[];
}

interface ITheme {
  backgroundColor: string;
  color: string;
}

interface IThemeProvider {
  dark: ITheme;
  light: ITheme;
}

interface InitialState {
  isMenuOpen: boolean;
  selectedTheme: keyof IThemeProvider;
  theme: IThemeProvider;
}

const getInitialState = (theme: any): InitialState => {
  return {
    isMenuOpen: false,
    selectedTheme: 'light',
    theme: {
      dark: {
        backgroundColor: theme.pallete!.backgrounds.hamburgerMenu,
        color: 'white',
      },
      light: {
        backgroundColor: theme.pallete!.backgrounds.header,
        color: darkBlue,
      },
    },
  };
};

type TState = Readonly<ReturnType<typeof getInitialState>>;

class PortalHamburgerMenu extends Component<Props, TState> {
  public readonly state: TState = getInitialState(this.props.theme);

  public render() {
    const {
      awaitingSigningCasesCount,
      links,
      languageCode,
      languageMenuLinks,
      t,
      ...rest
    } = this.props;
    return (
      <HamburgerMenu
        isOpen={this.state.isMenuOpen}
        toggle={this.toggleMenu}
        {...rest}
      >
        <ThemeProvider theme={this.state.theme[this.state.selectedTheme]}>
          <HamburgerButton
            awaitingSigningCasesCount={awaitingSigningCasesCount}
            isOpen={this.state.isMenuOpen}
            aria-label={t('MainNavigationButtonLabel')}
          />
        </ThemeProvider>
        <DropdownMenu
          links={this.props.links}
          show={this.state.isMenuOpen}
          onClick={this.toggleMenu}
          awaitingSigningCasesCount={awaitingSigningCasesCount}
          languageCode={languageCode}
          languageMenuLinks={languageMenuLinks}
          data-testid="navigation-mobile"
        />
      </HamburgerMenu>
    );
  }

  private toggleMenu = () => {
    this.setState(toggleMenu);
  };
}

const toggleMenu = (prevState: TState): TState => ({
  isMenuOpen: !prevState.isMenuOpen,
  selectedTheme: selectTheme(prevState.selectedTheme),
  theme: prevState.theme,
});

const selectTheme = (
  theme: InitialState['selectedTheme']
): InitialState['selectedTheme'] => {
  return theme === 'light' ? 'dark' : theme === 'dark' ? 'light' : theme;
};

export default withTheme(withNamespaces()(PortalHamburgerMenu));
