import axios, { AxiosResponse } from 'axios';
import { ApiTypes, ISigningCaseListItem } from 'src/models';
import { Config } from 'src/state/Config';
import { IContractingParty, ISigningCase } from 'src/models';
import { queryParameters } from '../constants';

type SigningCaseResponse = AxiosResponse<ISigningCaseListItem>;

interface ISigningCaseDate {
  dateFrom?: string;
  dateTo?: string;
}

type GetSigningCase = (
  params: ApiTypes.IGetSigningCase
) => Promise<SigningCaseResponse | ApiTypes.DefaultError>;

const getSigningCaseUrl = () => `${Config.host}/SigningCase`;
// Get Signing cases with given filter defined in signing case request.
export const fetchSigningCase: GetSigningCase = params => {
  const date: ISigningCaseDate = {};

  if (params.dateFrom) {
    date.dateFrom = params.dateFrom.toISOString();
  }

  if (params.dateTo) {
    date.dateTo = params.dateTo.toISOString();
  }

  return axios.get(getSigningCaseUrl(), {
    params: {
      ...params,
      ...date,
    },
  });
};

export const reject: ApiTypes.RejectSigningCase = ({
  signingCaseId,
  contractingPartyId,
}) =>
  axios.put(
    `${getSigningCaseUrl()}/${signingCaseId}/contractingParty/${contractingPartyId}/Signature/Rejector`
  );

export const getSigningCase = (
  signingCaseId: ISigningCase['id'],
  contractingPartyId: IContractingParty['id']
) =>
  axios.get(
    `${getSigningCaseUrl()}/${signingCaseId}/contractingParty/${contractingPartyId}`,
    {
      params: {
        [queryParameters.languageCode]: Config.getLanguageCode(),
      },
    }
  );

export const getSigningRulesAndSignatories = (
  signingCaseId: ISigningCase['id'],
  contractingPartyId: IContractingParty['id']
) =>
  axios.get(
    `${getSigningCaseUrl()}/${signingCaseId}/` +
      `contractingParty/${contractingPartyId}/SigningRulesAndSignatories`,
    {
      params: {
        [queryParameters.languageCode]: Config.getLanguageCode(),
      },
    }
  );
