import React from 'react';
import { ITimeframeMenu, TimeframeClickType } from './interfaces';
import { TimeframeButton } from './styles';
import { t } from 'i18next';

export const TimeframeSelect = ({ timeframe, onClick }: ITimeframeMenu) => {
  const today = 'Today';
  const month = 'Month';
  const month6 = 'Months6';
  const year = 'Year';

  const onTimeframeClick: TimeframeClickType = e =>
    onClick(e.currentTarget.value);

  return (
    <div role="menu">
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={today === timeframe}
        onClick={onTimeframeClick}
        value={today}
      >
        {t('Now')}
      </TimeframeButton>
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={month === timeframe}
        onClick={onTimeframeClick}
        value={month}
      >
        {t('LastMonth')}
      </TimeframeButton>
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={month6 === timeframe}
        onClick={onTimeframeClick}
        value={month6}
      >
        {t('Last6Months')}
      </TimeframeButton>
      <TimeframeButton
        type="button"
        role="menuitem"
        isSelected={year === timeframe}
        onClick={onTimeframeClick}
        value={year}
      >
        {t('Last12Months')}
      </TimeframeButton>
    </div>
  );
};
