import { combineReducers } from 'redux';
import * as CreateSignature from './CreateSignature';
import * as IsSignatureSuccessful from './IsSignatureSuccessful';
import * as RejectSigningCase from './Reject';

const reducer = combineReducers<any, any>({
  isSignatureSuccessful: IsSignatureSuccessful.reducer,
  reject: RejectSigningCase.reducer,
  signature: CreateSignature.reducer,
});

const fromSignature = CreateSignature.fromSignature;

export const getSignature = state => state.signature;

export const getIsSignatureSuccessful = state => state.isSignatureSuccessful;

export const getIsSignatureCreated = state =>
  fromSignature.getIsSignatureCreated(getSignature(state));

export { CreateSignature };
export { IsSignatureSuccessful };
export { RejectSigningCase };

export default reducer;
