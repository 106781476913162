import styled from 'styled-components';

interface Props {
  color?: string;
}

export const Background = styled.div<Props>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 1010;
  background-color: ${props =>
    props.color === 'dark' ? 'rgba(0,0,0,.7)' : props.color};
  overflow-y: auto;
  overflow-x: hidden;
`;
