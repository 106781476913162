import { TNavigationLinks } from 'src/models/NavigationLink';
import { Config } from './state/Config';

interface ParamNames {
  signingCaseId: string;
  contractingPartyId: string;
  documentId: string;
  languageCode: string;
  mandateId: string;
}

export enum ParamValues {
  signingCaseId = 'caseId',
  contractingPartyId = 'partyId',
  documentId = 'documentId',
  languageCode = 'languageCode',
  mandateId = 'id',
}

export const paramNames: ParamNames = {
  signingCaseId: 'caseId',
  contractingPartyId: 'partyId',
  documentId: 'documentId',
  languageCode: 'languageCode',
  mandateId: 'id',
} as const;

// Add a ':' to each parameter name. That is how the react router define parameters in the URL.
export const params = Object.entries(paramNames).reduce(
  (acc, [param, paramName]) => ({ ...acc, [param]: `:${paramName}` }),
  {} as ParamNames
);

export const archive = '/Archive';
export const home = `/`;
export const onboarding = '/Onboarding';
export const mandate = `/Mandates/${params.mandateId}`;
export const mandates = '/Mandates';
export const newView = '/New';
export const newMandate = `${mandates}${newView}`;
export const signingCase = '/Case';
export const contractingPartyV2 = '/Party';
export const signingCaseView =
  `${signingCase}/${params.signingCaseId}` +
  `${contractingPartyV2}/${params.contractingPartyId}`;
export const archivedSigningCaseView = `${archive}${signingCaseView}`;

export const parseLanguageCode = (path: string): string => {
  const pathParams = path.split('/');
  return pathParams.length > 0 ? pathParams[1] : '';
};

type ReplacePathLanguageCode = (
  location: string,
  currentLanguageCode: string,
  newLanguageCode: string
) => string;
export const replacePathLanguageCode: ReplacePathLanguageCode = (
  location,
  currentLanguageCode,
  newLanguageCode
) =>
  location === '/'
    ? '/' + newLanguageCode
    : location.replace(currentLanguageCode, newLanguageCode);

type RouteName =
  | 'home'
  | 'onboarding'
  | 'archive'
  | 'mandate'
  | 'mandates'
  | 'newView'
  | 'signingCase'
  | 'contractingParty'
  | 'signingCaseView'
  | 'archivedSigningCase';

const routes = {
  home,
  onboarding,
  archive,
  mandate,
  mandates,
  newView,
  newMandate,
  signingCase,
  contractingParty: contractingPartyV2,
  signingCaseView,
  archivedSigningCase: archivedSigningCaseView,
};

export const getRoute = (routeName: RouteName) => {
  const route = routes[routeName];
  return `/${Config.getLanguageCode()}${route === '/' ? '' : route}`;
};

export const isHomeRoute = (path: string) => {
  return (
    (path.slice(-1) === '/' ? path.slice(0, -1) : path) === getRoute('home')
  );
};

type GetNavigationLinks = (
  isUserOnboarded: boolean,
  isDanicaDKSite: boolean,
  isNordaniaDenmarkSite: boolean
) => TNavigationLinks;
export const getNavigationLinks: GetNavigationLinks = (
  isUserOnboarded,
  isDanicaDKSite,
  isNordaniaDenmarkSite
) => {
  const links = [
    {
      title: 'MenuItemForSigning',
      to: getRoute('home'),
    },
    {
      title: 'Archive',
      to: getRoute('archive'),
    },
  ];
  if (isUserOnboarded && !isDanicaDKSite && !isNordaniaDenmarkSite) {
    return [
      ...links,
      {
        title: 'Mandates',
        to: getRoute('mandates'),
      },
    ];
  } else {
    return links;
  }
};

export interface LanguageMenuLink {
  path: string;
  languageCode: string;
}
type GetLanguageMenuLinks = (
  path: string,
  languageCode: string,
  supportedLanguageCodes: string[]
) => LanguageMenuLink[];
export const createLanguageMenuLinks: GetLanguageMenuLinks = (
  path,
  languageCode,
  supportedLanguageCodes
) => {
  return languageCode
    ? supportedLanguageCodes.map(supportedLanguageCode => ({
        path: replacePathLanguageCode(
          path,
          languageCode,
          supportedLanguageCode
        ),
        languageCode: supportedLanguageCode,
      }))
    : [];
};
