import React, { FC, ComponentProps } from 'react';
import { Color } from 'src/styles';
import { TextGeneric } from './Text.styles';

interface Props extends ComponentProps<any> {
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  colorType?: Color;
}

export const Text: FC<Props> = ({
  colorType = 'primary',
  component = 'span',
  ...props
}) => {
  return <TextGeneric colorType={colorType} as={component} {...props} />;
};
