import { ISigningCaseListItem } from 'src/models';

export const isSigningCaseReadyToSign = (
  documentStatus: ISigningCaseListItem['status']
): boolean =>
  documentStatus === 'NotSigned' || documentStatus === 'PartiallySigned';

export const isSigningCaseWaiting = (
  documentStatus: ISigningCaseListItem['status']
): boolean => {
  return (
    documentStatus === 'Manual' ||
    documentStatus === 'SignedByCurrentContractingParty' ||
    isSigningCaseUpdating(documentStatus)
  );
};

export const isSigningCaseUpdating = (
  documentStatus: ISigningCaseListItem['status']
): boolean => documentStatus === 'Updating';

export const isSigningCaseSigned = (
  documentStatus: ISigningCaseListItem['status']
): boolean => {
  return documentStatus === 'Signed';
};

export const isSigningCaseRejected = (
  documentStatus: ISigningCaseListItem['status']
): boolean => {
  return (
    documentStatus === 'Expired' ||
    documentStatus === 'Cancelled' ||
    documentStatus === 'CancelledBySignatory'
  );
};

export const isSigningCaseArchived = (
  documentStatus: ISigningCaseListItem['status']
): boolean => {
  return (
    isSigningCaseRejected(documentStatus) || isSigningCaseSigned(documentStatus)
  );
};
