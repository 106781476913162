import styled from 'styled-components';

interface IframeType {
  isIframeLoaded: boolean;
}

export const Iframe = styled.iframe<IframeType>`
  width: 100%;
  visibility: ${props => (props.isIframeLoaded ? 'visible' : 'hidden')};
`;
