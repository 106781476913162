import React from 'react';
import { Li, Ul } from './DashList.styles';

interface IDashList {
  list: any[];
}

const DashList = ({ list }: IDashList) => {
  return (
    <Ul>
      {list.map((item, i) => (
        <Li key={i}>{item}</Li>
      ))}
    </Ul>
  );
};

export default DashList;
