import { MuiThemeProvider } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.css';
import i18n from 'i18next';
import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from './root.styles';
import Layout from './pages/Layout';
import RootSwitch from './RootSwitch';
import store from './state/store';
import { materialTheme, getTheme } from './styles';
import { ThemeProvider } from 'styled-components';
import { Config } from './state';

class Root extends Component {
  private readonly theme = getTheme(Config);

  componentDidMount() {
    addLinkElementsReferringToFavicon();
    setLangAttributeForHtml();
  }

  public render() {
    return (
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <MuiThemeProvider theme={materialTheme}>
              <ThemeProvider theme={this.theme}>
                <>
                  <GlobalStyles
                    isRealkreditDenmark={Config.isRealkreditDenmark()}
                  />
                  <Layout>
                    <RootSwitch />
                  </Layout>
                </>
              </ThemeProvider>
            </MuiThemeProvider>
          </BrowserRouter>
        </I18nextProvider>
      </Provider>
    );
  }
}

export default Root;

export const addLinkElementsReferringToFavicon = () => {
  if (Config.isRealkreditDenmark()) {
    var link: any = document.createElement('link');
    link.rel = 'shortcut icon';
    link.type = 'image/x-icon';
    link.href = `${process.env.PUBLIC_URL}/favicon-rd-32x32.ico`;
    link.sizes = '16x16 32x32';
    document.head.appendChild(link);
  } else {
    ['16x16', '32x32'].forEach(size => {
      var link: any = document.createElement('link');
      link.rel = 'shortcut icon';
      link.type = 'image/png';
      link.href = `${process.env.PUBLIC_URL}/favicon-${size}.png`;
      link.sizes = size;
      document.head.appendChild(link);
    });
  }
};

export const setLangAttributeForHtml = () => {
  const htmlElement = document.getElementsByTagName('html')[0];
  if (htmlElement) {
    htmlElement.lang = Config.getLanguageCode();
  }
};
