import { TRootState } from 'src/state/types';

export const getReject = (state: TRootState) => state.signing.reject;

export const getRejectData = (state: TRootState) => getReject(state).data;

export const isRejecting = (state: TRootState) => {
  const hasData = getReject(state).data;
  const isLoading = getReject(state).isLoading;
  const isRejecting = !hasData && isLoading;
  return isRejecting;
};
