enum ActionTypes {
  FETCH_SIGNING_CASES = 'SigningCasesList/FETCH_SIGNING_CASES',
  FETCH_SIGNING_CASES_REQUEST = 'SigningCasesList/FETCH_SIGNING_CASE_LIST_REQUEST',
  FETCH_SIGNING_CASE_LIST_FAILURES = 'SigningCasesList/FETCH_SIGNING_CASE_LIST_FAILURE',
  FETCH_SIGNING_CASE_LIST_SUCCESS = 'SigningCasesList/FETCH_SIGNING_CASE_LIST_SUCCESS',
  GO_DETAILS = 'SigningCasesList/GO_DETAILS',
  RESET_SIGNING_CASE_LIST = 'SigningCasesList/RESET_SIGNING_CASE_LIST',
}

export default ActionTypes;
