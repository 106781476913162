import { curryRight, flowRight } from 'lodash';
import {
  ICompanyRoleCode,
  ISignatory,
  ISigningRule,
  ISigningRulesAndSignatories,
} from 'src/models';
import { ISigningRulesAndSignatoriesState } from './ISigningRulesAndSignatories';

/**
 * Interpolate text inside curly braces
 * '{CEO}' => 'CEO'
 * @param s
 * @param replacer Used to process the text inside the curly braces
 */
type TcallbackFn = (s: string) => string;
export const interpolateCurlyBraces = (
  s: string,
  replacer: TcallbackFn
): string => s.replace(/\{[^}]+\}/g, text => replacer(removeCurlyBraces(text)));

export const removeCurlyBraces = (s: string) => s.replace(/{|}/g, '');

export const getCompanyRoles = (signatory: ISignatory) =>
  signatory.companyRoles;

export const getCompanyRole = (companyRole: ICompanyRoleCode) =>
  companyRole.roleCode;

export const getSigningRule = (signingRule: ISigningRule) => signingRule.rule;

export const createSigningRule = (rule: string) => ({ rule });

export const createCompanyRole = (role: string) => ({ roleCode: role });

export const translateSigningRules = (
  signingRules: ISigningRulesAndSignatoriesState['signingRules'],
  translateFn: TcallbackFn
) => {
  const interpolate = curryRight(interpolateCurlyBraces)(translateFn);
  return signingRules.map(
    flowRight(
      createSigningRule,
      interpolate,
      getSigningRule
    )
  );
};

export const translateCompanyRoles = (
  companyRoles: ISignatory['companyRoles'],
  translateFn: TcallbackFn
) => {
  const interpolate = curryRight(interpolateCurlyBraces)(translateFn);
  return companyRoles.map(
    flowRight(
      createCompanyRole,
      interpolate,
      getCompanyRole
    )
  );
};

export const translateSignatories = (
  signatories: ISigningRulesAndSignatoriesState['signatories'],
  translateFn: TcallbackFn
) =>
  signatories.map(signatory => {
    const companyRoles = getCompanyRoles(signatory);

    return {
      ...signatory,
      companyRoles: translateCompanyRoles(companyRoles, translateFn),
    };
  });

export const translateSigningRulesAndSignatories = (
  { signatories = [], signingRules = [] }: ISigningRulesAndSignatories,
  translateFn: TcallbackFn
) => {
  return {
    signatories: translateSignatories(signatories, translateFn),
    signingRules: translateSigningRules(signingRules, translateFn),
  };
};
