import React, { HTMLAttributes } from 'react';
import { darkRedPrimary } from 'src/styles/colors';
import { Container, TagText } from './Tag.styles';
import { t } from 'i18next';

interface Props {
  text: string;
}

export const ErrorTag = ({
  className,
  text,
}: Props & HTMLAttributes<HTMLDivElement>) => (
  <Container className={className} color={darkRedPrimary}>
    <TagText uppercase={true}>{t(text)}</TagText>
  </Container>
);
