// @ts-nocheck
import 'moment/locale/en-gb';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { DayPicker as ReactDayPicker } from 'react-day-picker';
import FocusTrap from 'focus-trap-react';
import { usePopper } from 'react-popper';
import { Caption } from './Caption';
import { format, isValid, parse } from 'date-fns';
import { Label } from 'src/components/Input';
import enGB from 'date-fns/locale/en-GB';
import fi from 'date-fns/locale/fi';
import sv from 'date-fns/locale/sv';

import 'react-day-picker/dist/style.css';
import './styles.css';

export const getLocale = local => {
  let locale;
  switch (local) {
    case 'fi':
      locale = fi;
      break;
    case 'sv':
      locale = sv;
      break;
    case 'en-gb':
    default:
      locale = enGB;
  }
  return locale;
};

export const DayPicker = ({
  date,
  label,
  inputProps = {},
  onClick,
  dayPickerProps = {},
}) => {
  const [internalDate, setInternalDate] = useState(date || new Date());
  const [inputValue, setInputValue] = useState<string>('');
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const popperRef = useRef(null);
  const inputRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);
  const dateLocale: string = moment.locale();
  const locale = getLocale(dateLocale);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChange = e => {
    const { value } = e.currentTarget;
    setInputValue(value);
    const date = parse(value, 'y-MM-dd', new Date());

    if (isValid(date)) {
      setInternalDate(date);
    } else {
      setInternalDate(undefined);
    }
  };

  const handleKeyPress = e => {
    e.preventDefault();
    if (e.key !== 'Enter') {
      return;
    }
    setIsPopperOpen(true);
  };

  const handleInputClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const handleDaySelect = (date: Date, day, { disabled }) => {
    if (disabled) {
      return;
    }

    setInternalDate(date);
    if (date) {
      setInputValue(format(date, 'y-MM-dd'));
      onClick(date);
      closePopper();
    } else {
      setInputValue('');
    }
  };

  return (
    <>
      <div ref={popperRef}>
        {label && <Label htmlFor="dayPicker">{label}</Label>}
        <div className="DayPickerWrapper">
          <input
            ref={inputRef}
            id="myInput"
            type="text"
            placeholder={format(new Date(), 'y-MM-dd')}
            aria-label="Pick a date"
            value={inputValue}
            onKeyPress={handleKeyPress}
            onChange={handleInputChange}
            onClick={handleInputClick}
            className="DayPickerInput input-reset pa2 ma2 bg-white black ba"
            {...inputProps}
          />
        </div>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
          }}
          onKeyPress={e => {
            console.log(e.key);
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="DayPicker dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <ReactDayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={internalDate}
              selected={internalDate}
              onSelect={handleDaySelect}
              components={{ CaptionLabel: Caption }}
              locale={locale}
              {...dayPickerProps}
            />
          </div>
        </FocusTrap>
      )}
    </>
  );
};
