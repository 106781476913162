import React from 'react';
import { Spinner } from '../../Loaders';
import { GridStyled } from 'src/styles/layout';

export const CardLoader = props => {
  return (
    <GridStyled container padding={20} justify="center" {...props}>
      <GridStyled item>
        <Spinner />
      </GridStyled>
    </GridStyled>
  );
};
