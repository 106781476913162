import { TableHeaderCell as th } from 'src/components';
import styled from 'styled-components';
import { TableTitle } from 'src/components';

export const TableHeaderCell = styled(th)`
  &:first-child {
    width: 60%;
    padding-left: 20px !important;
  }
`;

export const SignatoryTitle = styled(TableTitle)`
  display: inline-block;
  font-weight: 400;
  width: 90%;
`;
