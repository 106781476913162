import axios from 'axios';
import {
  responseInterceptorErr,
  responseInterceptorSuccess,
} from './services/response';

export const response = axios.interceptors.response.use(
  responseInterceptorSuccess,
  responseInterceptorErr
);
