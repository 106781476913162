import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import { fetchMandate } from './actions';
import { getType } from 'typesafe-actions';

type Props = ReturnType<typeof fetchMandate.request>;

function* fetchMandateSaga(props: Props) {
  try {
    const mandate = yield call(api.fetchMandate, props.payload.id);
    yield put(fetchMandate.success(mandate.data));
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
    yield put(fetchMandate.failure());
  }
}

export function* watchfetchMandate() {
  yield takeEvery(getType(fetchMandate.request), fetchMandateSaga);
}
