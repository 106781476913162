export const languageCodeByCountry = {
  denmark: 'da',
  england: 'en',
  finland: 'fi',
  norway: 'no',
  sweden: 'sv',
  realkreditDenmark: 'da',
  danicaDK: 'da',
  nordaniaDenmark: 'da',
} as const;

const languageCodes = Object.values(languageCodeByCountry);

export const getLanguageCode = (localeFileName: string) =>
  localeFileName.split('-')[0];

type IsLocaleFileNameValid = (
  language: string,
  expectedLanguage: string
) => boolean;
export const isLanguageFileNameValid: IsLocaleFileNameValid = (
  localeFileName,
  expectedLanguage
) => {
  return getLanguageCode(localeFileName) === expectedLanguage;
};

type IsCountryCodeValid = (localeFileName: string) => boolean;
export const isCountryCodeValid: IsCountryCodeValid = localeFileName => {
  const codes = localeFileName.split('-');
  return (
    codes.filter(code => isLanguageCodeValid(code)).length === codes.length
  );
};

export const isLanguageCodeValid = (languageCode: string) =>
  languageCodes.includes(languageCode as any);
