import React from 'react';
import { DateComponent } from 'src/components/Tag/DateComponent/DateComponent';
import { ITag } from './interfaces';
import { TagContainer } from './Tag.styles';

export const Tag = ({ color, className, date, status, ...rest }: ITag) => {
  return (
    <TagContainer className={className} color={color} {...rest}>
      <DateComponent date={date} status={status} />
    </TagContainer>
  );
};
