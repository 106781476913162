import React, { LabelHTMLAttributes, FC } from 'react';
import { LabelStyled } from './LabelBold.styles';

interface ILabelBold extends LabelHTMLAttributes<any> {
  className?: string;
}

const LabelBold: FC<ILabelBold> = props => {
  const { className, children, ...labelProps } = props;

  return (
    <LabelStyled className={className} {...labelProps}>
      {children}
    </LabelStyled>
  );
};

export default LabelBold;
