import React, { HTMLAttributes, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { TNavigationLinks } from 'src/models';
import { CollapseStyled, Item, Menu, ItemIcon, ItemText } from './styles';
import { renderSigningCasesCounter } from '../NavList';
import { ExpandMore, ExpandLess } from 'src/components/Icons';
import { ItemElement } from './ItemElement';
import { lightWhite } from 'src/styles/colors';
import { LanguageMenuLink, isHomeRoute } from 'src/routes';

interface Props extends HTMLAttributes<HTMLUListElement> {
  awaitingSigningCasesCount: number;
  show: boolean;
  links?: TNavigationLinks;
  languageCode: string;
  languageMenuLinks: LanguageMenuLink[];
  onClick: () => void;
}

const DropdownMenu = ({
  show = false,
  links = [],
  onClick,
  awaitingSigningCasesCount,
  languageCode,
  languageMenuLinks,
  ...rest
}: Props) => {
  const [isLanguageMenuOpened, openLanguageMenu] = useState(false);

  return (
    <Menu show={show} {...rest} role="menu">
      {links.map((link, index) => (
        <Item key={index} role="none">
          <ItemElement
            to={link.to}
            role="menuitem"
            exact
            onClick={onClick}
            as={NavLink}
            activeStyle={{
              backgroundColor: lightWhite,
            }}
          >
            {link.title}
            {isHomeRoute(link.to) &&
              renderSigningCasesCounter(awaitingSigningCasesCount)}
          </ItemElement>
        </Item>
      ))}
      {languageMenuLinks.length > 1 && (
        <Item role="none">
          <ItemElement
            as="button"
            role="menuitem"
            onClick={() => openLanguageMenu(!isLanguageMenuOpened)}
          >
            <ItemText>{languageCode.toUpperCase()}</ItemText>
            <ItemIcon>
              {isLanguageMenuOpened ? <ExpandLess /> : <ExpandMore />}
            </ItemIcon>
          </ItemElement>
        </Item>
      )}
      {languageMenuLinks.length > 1 && (
        <CollapseStyled
          in={isLanguageMenuOpened}
          timeout="auto"
          component={props => <ul {...props} role="menu" />}
        >
          {languageMenuLinks
            .filter(menuLink => menuLink.languageCode !== languageCode)
            .map(({ languageCode, path }) => (
              <Item key={languageCode} role="none">
                <ItemElement as="a" href={path} role="menuitem">
                  {languageCode.toUpperCase()}
                </ItemElement>
              </Item>
            ))}
        </CollapseStyled>
      )}
    </Menu>
  );
};

export default DropdownMenu;
