import styled from 'styled-components';
import { darkBlue } from 'src/styles/colors';

export const FooterContainer = styled.footer`
  width: 100%;
  margin-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 16px;
`;

export const Text = styled.div`
  opacity: 0.7;
  font-size: 0.7em;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
`;

export const TelNumber = styled.a`
  color: ${darkBlue};
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.pallete.action.hover};
  }
`;

export const PhoneNumbersWrapper = styled.div`
  display: inline-flex;

  span,
  a {
    margin-right: 2px;
  }
`;
