import { ISigningCase } from 'src/models';
import {
  isSigningCaseRejected,
  isSigningCaseReadyToSign,
  isSigningCaseSigned,
  isSigningCaseWaiting,
} from 'src/utils/SigningCase';
import {
  bluePrimary,
  darkGreenPrimary,
  darkOrangePrimary,
  darkRedPrimary,
} from 'src/styles';

export const getSigningCaseHeaderColor = (status: ISigningCase['status']) => {
  if (isSigningCaseReadyToSign(status)) {
    return bluePrimary;
  } else if (isSigningCaseRejected(status)) {
    return darkRedPrimary;
  } else if (isSigningCaseSigned(status)) {
    return darkGreenPrimary;
  } else if (isSigningCaseWaiting(status)) {
    return darkOrangePrimary;
  }

  return bluePrimary;
};
