import { createAsyncAction } from 'typesafe-actions';
import { createAsyncActionTypes } from 'src/state/common';

const types = createAsyncActionTypes('REVOKE_MANDATE');

export const revokeMandate = createAsyncAction(
  types.REQUEST,
  types.SUCCESS,
  types.FAILURE
)<{ mandateId: string }, undefined, Error>();
