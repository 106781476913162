import {
  ActionTypes as SigningCaseDetailsActionTypes,
  TActions as SigningCaseDetailsTypes,
} from 'src/state/SigningCaseDetails';
import { IsSignatureSuccessful, RejectSigningCase } from '../Signing';
import { TActions } from './actions';
import { initialState, IState, ListState, listState } from './models';
import { getListState, getSigningCaseViewPath } from './services';
import ActionTypes from './types';
import { ActionType } from 'typesafe-actions';

type Action =
  | TActions
  | SigningCaseDetailsTypes
  | ActionType<typeof IsSignatureSuccessful.Actions>
  | ActionType<typeof RejectSigningCase.Actions>;

const details = (state: IState = initialState, action: Action) => {
  if (action.type === ActionTypes.GO_DETAILS) {
    const { history, signignCaseListItem, index, status } = action.payload;
    const { id, internalContractingPartyId } = signignCaseListItem;
    const signingCaseViewPath = getSigningCaseViewPath(
      history.location.pathname,
      id,
      internalContractingPartyId
    );

    history.push(signingCaseViewPath);

    return {
      ...state,
      openedSigningCase: {
        index,
        status,
      },
    };
  } else if (action.type === ActionTypes.FETCH_SIGNING_CASES_REQUEST) {
    const data = state[action.payload];

    return {
      ...state,
      [action.payload]: {
        ...data,
        isLoading: true,
      },
    };
  } else if (action.type === ActionTypes.FETCH_SIGNING_CASE_LIST_SUCCESS) {
    const { status, ...signingCase } = action.payload;
    const prevState = state[status] as ListState;
    const list = getListState(
      prevState.list,
      signingCase.list,
      signingCase.page || 0,
      status
    );

    return {
      ...state,
      [status]: {
        hasNextPage: signingCase.hasNextPage,
        isLoading: false,
        list,
        page: signingCase.page,
      },
    };
  } else if (action.type === ActionTypes.FETCH_SIGNING_CASE_LIST_FAILURES) {
    return {
      ...state,
      [action.payload]: listState,
    };
  } else if (
    action.type === SigningCaseDetailsActionTypes.RESET_OPENED_SIGNING_CASE
  ) {
    return {
      ...state,
      openedSigningCase: null,
    };
  } else if (action.type === ActionTypes.RESET_SIGNING_CASE_LIST) {
    return {
      ...state,
      [action.payload]: listState,
    };
  } else if (
    IsSignatureSuccessful.ActionTypes.SIGN_DOCUMENT_SUCCESS === action.type ||
    RejectSigningCase.ActionTypes.REJECT_SUCCESS === action.type
  ) {
    return {
      ...state,
      Archived: listState,
    };
  }

  return state;
};

export default details;
