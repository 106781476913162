import { TRootState } from 'src/state/types';
import { isSigningCaseArchived } from 'src/utils/SigningCase';
import { ISigningCaseListItem, ISignatureResponse } from 'src/models';

export const getSigningRules = (state: TRootState) =>
  state.signingRulesAndSignatories.signingRules;

export const getSignatories = (state: TRootState) =>
  state.signingRulesAndSignatories.signatories;

export const getSignatoriesV2 = (
  state: TRootState,
  status: ISigningCaseListItem['status'],
  signatures: ISignatureResponse[]
) => {
  if (isSigningCaseArchived(status)) {
    return (
      state.signingRulesAndSignatories.signatories &&
      state.signingRulesAndSignatories.signatories.filter(
        signatory => !!signatures.find(x => x.externalId === signatory.externalId)
      )
    );
  }
  return (
    state.signingRulesAndSignatories &&
    state.signingRulesAndSignatories.signatories
  );
};

export const getIsLoading = (state: TRootState) =>
  state.signingRulesAndSignatories.isLoading;
