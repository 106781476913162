import { CreateMandate } from './actions';

const mandate = (state: any, action: any) => {
  if (action.type === CreateMandate.REQUEST) {
    return {
      isLoading: true,
    };
  } else if (action.type === CreateMandate.FAILURE) {
    return {
      error: action.payload,
      isLoading: false,
    };
  } else if (action.type === CreateMandate.SUCCESS) {
    return {
      data: action.payload,
      isLoading: false,
    };
  }

  return {
    error: null,
    isLoading: state ? state.isLoading : false,
  };
};

export default mandate;
