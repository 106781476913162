import styled from 'styled-components';
import { IModalBody } from './interfaces';

export const ModalBody = styled.div<IModalBody>`
  padding: 15px;
  font-size: 1em;
  background-color: white;
  text-align: ${props => props.textAlign || 'unset'};
  word-break: break-word;
`;
