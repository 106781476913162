import React, { FC } from 'react';
import Circle from '../Circle';
import { StepContainer } from './Step.styles';
import { ILineStep } from '../types';

const Step: FC<ILineStep> = props => {
  const { activeStep, beforeActive, afterActive, children, ...rest } = props;

  return (
    <StepContainer
      beforeActive={beforeActive}
      afterActive={afterActive}
      {...rest}
    >
      <Circle activeStep={activeStep} active={beforeActive}>
        {props.children}
      </Circle>
    </StepContainer>
  );
};

export default Step;
