import { createGlobalStyle } from 'styled-components';
import { lightGrayText } from 'src/styles';

export const CookieConsentStyle = createGlobalStyle`
  label {
    span:first-child {
      padding: 5px;
    }

    span:last-child {
      font-size: 13px;
      line-height: 1.8;
      color: ${lightGrayText};
      cursor: pointer;
    }
  }
`;
