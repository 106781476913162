import React, { Component, createRef, RefObject } from 'react';
import { Background } from '../../Background';
import { Iframe, IframeModal as Modal, CloseBtn } from '../components';
import { GridStyled } from './styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface IModal {
  url: string;
  id: string;
  onCancel: () => void;
  onSign: () => void;
}

const initialState = {
  // Only show iframe content when it is loaded
  isIframeLoaded: false,
};

type State = Readonly<typeof initialState>;
class IframeModal extends Component<IModal, State> {
  state = initialState;
  iframe = createRef<HTMLIFrameElement>();
  modalRef = createRef<HTMLDivElement>();

  componentDidMount() {
    window.addEventListener('message', this.handleIframeMessages);
    disableBodyScroll(this.modalRef.current);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleIframeMessages);
    enableBodyScroll(this.modalRef.current);
  }

  render() {
    return (
      <>
        <Background
          color="dark"
          role="presentation"
          onClick={this.props.onCancel}
        />
        <Modal role="presentation" ref={this.modalRef}>
          <GridStyled container justify="flex-end">
            <CloseBtn onClick={this.props.onCancel} />
          </GridStyled>
          <Iframe
            isIframeLoaded={this.state.isIframeLoaded}
            src={this.props.url}
            ref={this.iframe as RefObject<HTMLIFrameElement> & string}
            role="document"
            scrolling="yes"
            height="95%"
            width="100%"
            frameBorder="0"
          />
        </Modal>
      </>
    );
  }

  private handleIframeMessages = (event: MessageEvent) => {
    const iframe = this.iframe.current;

    if (iframe && iframe.contentWindow) {
      switch (event.data) {
        case 'ready':
          iframe.contentWindow.postMessage(
            { signatureId: this.props.id },
            this.props.url
          );
          this.onIframeLoad();
          break;
        case 'cancel':
          this.props.onCancel();
          break;
        case 'ok':
          this.props.onSign();
          break;
      }
    }
  };

  private onIframeLoad = () => {
    this.setState({
      isIframeLoaded: true,
    });
  };
}

export default IframeModal;
