import Grid from '@material-ui/core/Grid';
import React, { ComponentProps } from 'react';
import {
  Body,
  Button,
  Header,
  Menu,
  ModalContainer,
  Title,
} from '../components';
import { t } from 'i18next';

type TBodyProps = ComponentProps<typeof Body>;
interface InfoType {
  onClose?: () => void;
  title: string;
  bodyProps?: TBodyProps;
}

const Info = (props: InfoType) => {
  const { onClose, title } = props;
  const bodyPropsDefault: TBodyProps = {
    textAlign: 'justify',
  };
  const bodyProps: TBodyProps = {
    ...bodyPropsDefault,
    ...props.bodyProps,
  };

  return (
    <ModalContainer maxWidth={560}>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <Header textAlign="left">
            <Title>{title}</Title>
          </Header>
        </Grid>
        <Grid item={true} xs={12}>
          <Body {...bodyProps} />
        </Grid>
        <Grid item={true} xs={12}>
          <Menu>
            <Button onClick={onClose}>{t('Close')}</Button>
          </Menu>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

export default Info;
