import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Logo = styled.img`
  width: 100%;
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
`;
