import React, { HTMLAttributes } from 'react';
import {
  FooterContainer,
  PhoneNumbersWrapper,
  TelNumber,
  Text,
} from './Footer.styles';
import { t } from 'i18next';

interface Props extends HTMLAttributes<HTMLElement> {
  hasTwoPhoneNumbers: boolean;
  userId?: string;
}

export const Footer = ({ hasTwoPhoneNumbers, userId, ...rest }: Props) => {
  const privateContact = t('ContactNumberPrivate');
  const businessContact = t('ContactNumberBusiness');
  const contactNumber = t('ContactNumber');

  return (
    <FooterContainer {...rest}>
      <Text>
        {!hasTwoPhoneNumbers && (
          <PhoneNumbersWrapper>
            <span aria-label={t('ContactsCopyright')}>
              {t('ContactsCopyright')}
            </span>
            <TelNumber
              key="contact-number"
              aria-label={contactNumber}
              href={`tel:${contactNumber}`}
            >
              {contactNumber}
            </TelNumber>
          </PhoneNumbersWrapper>
        )}
        {hasTwoPhoneNumbers && (
          <PhoneNumbersWrapper>
            <span aria-label={t('ContactsCopyrightPrivate')}>
              {t('ContactsCopyrightPrivate')}
            </span>
            <TelNumber
              aria-label={privateContact}
              key="private-number"
              href={`tel:${privateContact}`}
            >
              {privateContact}
            </TelNumber>
            <span aria-label={t('ContactsCopyrightBusiness')}>
              {t('ContactsCopyrightBusiness')}
            </span>
            <TelNumber
              aria-label={businessContact}
              key="business-number"
              href={`tel:${businessContact}`}
            >
              {businessContact}
            </TelNumber>
          </PhoneNumbersWrapper>
        )}
        {userId && ` - ${t('UserId', { userId: userId })}`}
      </Text>
    </FooterContainer>
  );
};

export default Footer;
