import { combineReducers } from 'redux';
import { ActionType, isActionOf } from 'typesafe-actions';
import { error, isLoading } from '../Async';
import { createCustomer, onboardCustomer } from './actions';

const isCreated = (
  state: boolean = false,
  action: ActionType<typeof createCustomer>
) => {
  if (isActionOf(createCustomer.success, action)) {
    return true;
  } else if (
    isActionOf(createCustomer.failure, action) ||
    isActionOf(createCustomer.request, action)
  ) {
    return false;
  }

  return state;
};

const shouldOnboardCustomer = (
  state: boolean = false,
  action: ActionType<typeof onboardCustomer> & ActionType<typeof createCustomer>
) => {
  if (isActionOf(onboardCustomer, action)) {
    return true;
  } else if (isActionOf(createCustomer.success, action)) {
    return false;
  }

  return state;
};

const customerCreation = combineReducers({
  error: error(createCustomer),
  isCreated,
  shouldOnboardCustomer,
  isLoading: isLoading(createCustomer),
});

export default customerCreation;

type State = Readonly<ReturnType<typeof customerCreation>>;

export const getIsLoading = (state: State) => state.isLoading;

export const getIsCreated = (state: State) => state.isCreated;

export const getShouldOnboardCustomer = (state: State) =>
  state.shouldOnboardCustomer;

export const getError = (state: State) => state.error;
