interface IPrefixes {
  FAILURE: string;
  REQUEST: string;
  SUCCESS: string;
}

export const createAsyncActionTypes = (
  name: string,
  separator: string = '_'
) => {
  const prefixes = ['FAILURE', 'REQUEST', 'SUCCESS'];

  return prefixes.reduce(
    (acc, prefix) => {
      const type = `${name}${separator}${prefix}`;

      return {
        ...acc,
        [prefix]: type,
      };
    },
    {} as IPrefixes
  );
};
