import React from 'react';
import { SigningDocumentCover } from 'src/components';
import { AgreementsArchiveCard } from '../../AgreementsCard';

const Archive = () => {
  return (
    <>
      <SigningDocumentCover container={true} />
      <AgreementsArchiveCard />
    </>
  );
};

export default Archive;
