import { createGlobalStyle } from 'styled-components';
import iconFont from './styles/icon-fonts';
import font from './styles/fonts';
import refSignFonts from './styles/refsign-fonts';

interface Props {
  isRealkreditDenmark: boolean;
}

export const GlobalStyles = createGlobalStyle<Props>`
    ${({ isRealkreditDenmark }) => (isRealkreditDenmark ? refSignFonts : font)};
    ${iconFont}
    body {
        margin: 0;
        padding: 0;
        font-family: ${({ theme }: any) => theme.typography.fontFamily};
        font-weight: ${({ theme }: any) => theme.typography.fontWeight};
        color: ${({ theme }: any) => theme.pallete.primary.main};
        line-height: 1;
        background-color: #f4f4f4;
    }

    * {
        box-sizing: border-box;
    }

    body, html, #root {
        height: 100%;
    }

    .customer-service-link, .telephone-link {
      color: ${({ theme }: any) => theme.pallete.primary.main};
      text-decoration: underline;
    }

    .customer-service-link:hover, .telephone-link:hover {
      color: ${({ theme }: any) => theme.pallete.primary.main};
    }
`;
