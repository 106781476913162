import { RouteComponentProps } from 'react-router-dom';
import {
  ApiTypes,
  ISigningCaseListItem,
  SigningCaseStatusApi,
  TSigningCaseStatusApi,
} from 'src/models';
import { action, ActionType } from 'typesafe-actions';
import { SingingCaseList } from './models';
import ActionTypes from './types';

export interface IfetchSigningCasesSuccess extends SingingCaseList {
  status: SigningCaseStatusApi;
}

interface IGoSigningCaseDetails {
  history: RouteComponentProps<any>['history'];
  location: RouteComponentProps<any>['location'];
  signignCaseListItem: ISigningCaseListItem;
  status: SigningCaseStatusApi;
  index: number;
}

const Actions = {
  fetchSigningCaseListFailure: (status: TSigningCaseStatusApi) =>
    action(ActionTypes.FETCH_SIGNING_CASE_LIST_FAILURES, status),
  fetchSigningCases: (status: ApiTypes.IGetSigningCase) =>
    action(ActionTypes.FETCH_SIGNING_CASES, status),
  fetchSigningCasesRequest: (status: TSigningCaseStatusApi) =>
    action(ActionTypes.FETCH_SIGNING_CASES_REQUEST, status),
  fetchSigningCasesSuccess: (params: IfetchSigningCasesSuccess) =>
    action(ActionTypes.FETCH_SIGNING_CASE_LIST_SUCCESS, params),
  goSigningCaseDetails: (params: IGoSigningCaseDetails) =>
    action(ActionTypes.GO_DETAILS, params),
  resetSigningCase: (params: SigningCaseStatusApi) =>
    action(ActionTypes.RESET_SIGNING_CASE_LIST, params),
};

export type TActions = ActionType<typeof Actions>;
export default Actions;
