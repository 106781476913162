import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import RejectActions from './actions';
import ActionTypes from './types';

type Props = ReturnType<typeof RejectActions.reject>;
function* rejectSigningCase(props: Props) {
  try {
    yield put(RejectActions.rejectRequest());

    yield call(api.reject, props.payload);

    yield put(RejectActions.rejectSuccess(true));
  } catch (e) {
    yield put(RejectActions.rejectFailure());
    yield put(notificationsManager.addNotification(e));
  }
}

export function* watch() {
  yield takeEvery(ActionTypes.REJECT, rejectSigningCase);
}
