import { TableCellFirst } from 'src/components';
import { TableRow as Row } from 'src/components/PortalList/PortalList.styles';
import { lightBlue } from 'src/styles/colors';
import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';

export const TableRow = styled(Row)`
  &:hover {
    background-color: ${lightBlue};
  }
`;

export const TableCell = styled(TableCellFirst)`
  padding: 20px 5px 5px 15px;
`;

export const LineBreakText = styled.div`
  font-weight: 700;
  font-size: 0.9em;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);

  ${mediaMaxWidth2.tabletSmall`
    font-size: 1.2em;
  `}
`;
