import { ActionType, isActionOf } from 'typesafe-actions';
import { AsyncAction } from '../models';

export const success = <State, Payload = State>(
  initialState: State,
  actions: AsyncAction,
  payload?: Payload
) => (state = initialState, action: ActionType<any>) => {
  if (isActionOf(actions.success, action)) {
    return (payload as Payload) || (action.payload as State);
  } else if (
    isActionOf(actions.failure, action) ||
    isActionOf(actions.request, action)
  ) {
    return initialState;
  }

  return state;
};
