import spinnerImg from 'src/assets/images/icons/loader-still.png';
import styled from 'styled-components';

interface IProps {
  size?: number;
}

export const BlueSpinner = styled.div<IProps>`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .rotating {
    animation: rotating 1s linear infinite;
  }

  width: ${props => (props.size ? props.size : 40)}px;
  height: ${props => (props.size ? props.size : 40)}px;
  display: block;
  position: relative;
  background: url(${spinnerImg}) no-repeat center;
  background-size: contain;
  margin: 0 auto;
  animation: rotating 1s infinite linear;
`;
