import { action, ActionType } from 'typesafe-actions';
import { prefix } from '../types';

export const SELECT = `${prefix}/SELECT`;

export const requiredSignatories = {
  select: (props: string) => action(SELECT, props),
};

export type RequiredSignatories = ActionType<typeof requiredSignatories>;
