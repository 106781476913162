import { ICompanyMandate, ISigningMandateDetails } from 'src/models';
import { FetchMandates } from './actions';
import { FetchMandatesTypes } from './types';

interface ICompanyMandates {
  isLoading: boolean;
  data: ICompanyMandate[];
}

const initialState: ICompanyMandates = {
  data: [],
  isLoading: false,
};

type State = Readonly<typeof initialState>;

const mandates = (state: State = initialState, action: FetchMandates) => {
  if (action.type === FetchMandatesTypes.GET_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (action.type === FetchMandatesTypes.GET_SUCCESS) {
    return {
      data: action.payload,
      isLoading: false,
    };
  } else if (action.type === FetchMandatesTypes.GET_FAILURE) {
    return initialState;
  }

  return state;
};

export default mandates;

export const getIsLoading = (state: State) => state.isLoading;

export const getMandates = (state: State) => state.data;

export const getMandate = (state: State, id: string) => {
  let foundMandate: ISigningMandateDetails | undefined;

  getMandates(state).find(mandate => {
    const found = findCompanyMandate(mandate, id);
    foundMandate = found ? found : foundMandate;
    return found ? true : false;
  });

  return foundMandate;
};

export const findMandate = (list: ISigningMandateDetails[], id: string) => {
  return list.find(x => x.id === id);
};

export const findCompanyMandate = (
  companyMandate: ICompanyMandate,
  id: string
) => {
  return (
    findMandate(companyMandate.activeMandates, id) ||
    findMandate(companyMandate.inactiveMandates, id)
  );
};
