import axios, { AxiosPromise } from 'axios';
import { Config } from 'src/state/Config';

interface CreateCustomerParams {
  citizenships: string[];
}

type CreateCustomer = (params: CreateCustomerParams) => AxiosPromise;

export const createCustomer: CreateCustomer = params => {
  return axios.put(`${Config.host}/CustomerCreation/Legitimize`, params);
};
