import React from 'react';
import { IOtherContractingPartiesList as IContractingParties } from './ISigneesList';
import { OtherContractingPartiesListBody } from './OtherContractingPartiesListBody';
import { List, Title } from './OtherContractingParties.styles';
import SigneesListFrame from './SigneesListFrame';
import { ISigningCaseStatus } from 'src/models';
import { t } from 'i18next';

interface IOtherContractingPartiesList extends IContractingParties {
  status?: keyof ISigningCaseStatus;
  showHeader?: boolean;
}

const OtherContractingPartiesList = ({
  showHeader,
  contractingParties,
  status = 'NotSigned',
}: IOtherContractingPartiesList) => {
  if (contractingParties && contractingParties.length) {
    return (
      <List status={status} data-testid="other-contracting-parties">
        <Title>{t('ContractingParties')}</Title>
        <SigneesListFrame
          render={renderSigneesListBody({ contractingParties })}
          showHeader={showHeader}
        />
      </List>
    );
  } else {
    return null;
  }
};

const renderSigneesListBody = (props: IContractingParties) => () => (
  <OtherContractingPartiesListBody {...props} />
);

export default OtherContractingPartiesList;
