import Grid, { GridProps } from '@material-ui/core/Grid';
import React from 'react';

interface IGridWrapper extends GridProps {
  border?: boolean;
}

const GridWrapper = (props: IGridWrapper) => {
  const { border, children, ...gridProps } = props;

  return <Grid {...gridProps}>{children}</Grid>;
};

export default GridWrapper;
