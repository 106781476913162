import { Reducer } from 'redux';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
  ActionType,
  createStandardAction,
  createAsyncAction,
  getType,
} from 'typesafe-actions';
import moment from 'moment';
import {
  refreshSession as refreshRequest,
  logOut as logOutRequest,
} from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';

const refreshSession = createAsyncAction(
  'USER_SESSION_REFRESH_REQUEST',
  'USER_SESSION_REFRESH_SUCCESS',
  'USER_SESSION_REFRESH_ERROR'
)<undefined, undefined, undefined>();
const setCurrentDate = createStandardAction('USER_SESSION_SET_CURRENT_DATE')<
  moment.Moment
>();
const setExpirationDate = createStandardAction(
  'USER_SESSION_SET_EXPIRATION_DATE'
)<moment.Moment>();
const actions = {
  setCurrentDate,
  setExpirationDate,
  ...refreshSession,
};
const logOut = createStandardAction('USER_SESSION_LOG_OUT')();

interface State {
  currentDate?: moment.Moment;
  expirationDate?: moment.Moment;
}

const reducer: Reducer<State, ActionType<typeof actions>> = (
  state = {},
  action
) => {
  if (action.type === getType(setCurrentDate)) {
    return { ...state, currentDate: action.payload };
  } else if (action.type === getType(setExpirationDate)) {
    return { ...state, expirationDate: action.payload };
  }

  return state;
};

const getCurrentDate = (state: State) => state.currentDate;
const getUserSessionExpirationDate = (state: State) => state.expirationDate;

const fromUserSession = {
  getCurrentDate,
  getUserSessionExpirationDate,
};

function* refreshSessionSaga(): Generator {
  try {
    yield call(refreshRequest);
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
  }
}

function* watchRefreshSession(): Generator {
  yield takeLatest(getType(refreshSession.request), refreshSessionSaga);
}

function* logOutSaga(): Generator {
  try {
    const response: any = yield call(logOutRequest);
    window.location.assign(response.data);
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
  }
}

function* watchLogOut() {
  yield takeLatest(getType(logOut), logOutSaga);
}

export {
  fromUserSession,
  setCurrentDate,
  setExpirationDate,
  refreshSession,
  watchRefreshSession,
  logOut,
  watchLogOut,
};
export default reducer;
