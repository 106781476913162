import React, { HTMLAttributes } from 'react';
import { ListContainer } from '../styles';
import { Title as TitleText } from './Title.styles';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  labelText?: string;
}

export const Title = ({ labelText, ...props }: Props) => {
  return (
    <ListContainer>
      <TitleText aria-label={labelText} {...props} />
    </ListContainer>
  );
};
