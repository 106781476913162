import React from 'react';
import { Grid } from '@material-ui/core';
import { IconStyled, MessageContainer, Time } from './styles';
import Countdown from 'react-countdown';
import { t } from 'i18next';

interface IMessage {
  timeout: number;
  onCountdownComplete: () => void;
}

const Message = ({ timeout, onCountdownComplete }: IMessage) => {
  return (
    <Grid container wrap="wrap" spacing={16}>
      <Grid item xs={3} sm={3}>
        <IconStyled type="clock" aria-label="" />
      </Grid>
      <Grid item xs={9} sm={9}>
        <MessageContainer>
          <div>
            <p>{t('SessionExpirationTimeoutMessage')}</p>
          </div>
          <div>
            <Time>
              <Countdown
                date={Date.now() + timeout * 1000}
                renderer={x => x.seconds}
                onComplete={onCountdownComplete}
              />{' '}
              {t('sec')}
            </Time>
          </div>
          <div>
            <p>{t('SessionExpirationMessage')}</p>
          </div>
        </MessageContainer>
      </Grid>
    </Grid>
  );
};

export default Message;
