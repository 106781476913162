import styled from 'styled-components';
import { white } from 'src/styles';

interface TagTextProps {
  uppercase?: boolean;
}

export const TagText = styled.span<TagTextProps>`
  color: ${white};
  font-family: 'Danske', 'Tahoma', Sans-Serif;
  font-size: 0.9em;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`;

export const DateText = styled.span`
  margin-left: 0.5em;
`;

interface TagContainerProps {
  color?: string;
}

export const TagContainer = styled.div<TagContainerProps>`
  background-color: ${props => props.color};
`;

export const Container = styled.div<TagContainerProps>`
  position: absolute;
  background-color: ${props => props.color};
  top: -3px;
  right: 0;
  padding: 0px 6px 3px;
  border-bottom-left-radius: 3px;
`;
