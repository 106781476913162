import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Color, getThemeColor } from 'src/styles';

export const Title = styled.h1`
  font-family: 'DanskeHuman', 'Danske', Sans-Serif;
  font-size: 2em;
  text-align: center;
  margin: 0;
`;

interface ISubtitleProps {
  fontStyle?: CSSProperties['fontStyle'];
}

export const Subtitle = styled.span<ISubtitleProps>`
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 0.8em;
  font-style: ${props => props.fontStyle || 'inherit'};
`;

interface TextProps {
  colorType: Color;
}

export const TextGeneric = styled.span<TextProps>`
  color: ${({ colorType, theme }) => getThemeColor(colorType, theme)};
`;
