import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';
import { IModal } from './interfaces';

export const Menu = styled.div`
  padding: 20px;
  background-color: white;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid white;
`;

export const DefaultModal = styled.div`
  position: fixed;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Modal = styled(DefaultModal)<IModal>`
  border-radius: 5px;
  border: 10px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  width: ${(props: IModal) =>
    props.width ? props.width.toString().concat('px') : '100%'};
  max-width: ${(props: IModal) =>
    props.maxWidth ? props.maxWidth.toString().concat('px') : 'auto'};

  ${mediaMaxWidth2.mobile`
    width: 100%;
    height: 100%;
    background-color: white;
    border: none;
    border-radius: 0;
  `}
`;

export const IframeModal = styled(DefaultModal)`
  width: 95%;
  height: 100%;
  max-height: 90%;
  border: 1px solid black;
  overflow: auto;
  padding: 10px 15px 15px 15px;
  z-index: 1010;
`;
