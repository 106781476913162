import { AxiosError } from 'axios';
import { INotification, INotifications } from 'src/models';
import { deleteProperty } from 'src/utils/functional';
import { TActions } from './actions';
import ActionTypes from './types';
import { transformToNotification } from './utils';

const notificationsReducer = (state: INotifications = {}, action: TActions) => {
  if (action.type === ActionTypes.ADD_NOTIFICATION) {
    if (!action.payload) {
      return state;
    }

    if ((action.payload as INotification).type) {
      const notification = action.payload as INotification;

      return {
        ...state,
        [notification.text]: notification,
      };
    } else {
      const notification = transformToNotification(
        action.payload as AxiosError
      );

      return {
        ...state,
        [notification.text]: notification,
      };
    }
  } else if (action.type === ActionTypes.CLEAR_NOTIFICATIONS) {
    if (state.ProofOfIdMissing) {
      return {
        ProofOfIdMissing: state.ProofOfIdMissing,
      };
    }
    return {};
  } else if (action.type === ActionTypes.REMOVE_NOTIFICATION) {
    return deleteProperty(state, action.payload);
  }

  return state;
};

export default notificationsReducer;

type GetNotificationsTypes = (state: INotifications) => INotification[];
export const getNotificationsTypes: GetNotificationsTypes = state => {
  return Object.values(state);
};
