/**
 * @returns concat array elements by given separator
 */
export const join = <T>(
  list: T[],
  getter: (x: T) => string,
  separator: string = ',',
  accumulator: string = ''
) =>
  list.reduce(
    (s, x) =>
      s.length ? s.concat(`${separator} ${getter(x)}`) : s.concat(getter(x)),
    accumulator
  );
