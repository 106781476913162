import axios from 'axios';
import { ApiTypes } from 'src/models';
import { Config } from 'src/state/Config';
import { queryParameters } from '../constants';

export const fetchProductAreas: ApiTypes.FetchProductAreas = async () =>
  axios.get(`${Config.host}/ProductAreas`, {
    params: {
      [queryParameters.languageCode]: Config.getLanguageCode(),
    },
  });
