import React, { KeyboardEvent, MouseEvent, useEffect, useState } from 'react';
import { DeadlineDate } from 'src/components/PortalList/PortalDeadlineDate';
import { ISigningCaseListItem } from 'src/models';
import {
  AgreementDeadlineMobile,
  TableCellMobile,
  TableCellTitle,
  TableCellBig,
  TableCellLast,
} from 'src/components';
import { Row, Title, AgreementStatus, AgreementStatusMobile } from './styles';
import { getTextFromAgreementStatus } from 'src/utils/getTextFromAgreementStatus';
import { getAgreementColor } from 'src/utils/getAgreementColor';

type TableRowEventType = MouseEvent<HTMLTableRowElement>;
type TableRowKeyboardEventType = KeyboardEvent<HTMLTableRowElement>;

interface Props {
  document: ISigningCaseListItem;
  onClick?: (e: TableRowEventType, document: ISigningCaseListItem) => void;
  onKeyDown?: (
    e: TableRowKeyboardEventType,
    document: ISigningCaseListItem
  ) => void;
}

export const SigningCasesListItem = ({
  document,
  onClick,
  onKeyDown,
}: Props) => {
  const color = getAgreementColor(
    document.status,
    document.contractingPartyType
  );

  const onSigningCaseClick = (e: TableRowEventType) => {
    if (onClick) {
      onClick(e, document);
    }
  };

  const onSigningCaseKeyboardClick = (e: TableRowKeyboardEventType) => {
    if (onKeyDown) {
      onKeyDown(e, document);
    }
  };

  const [caseStatus, setCaseStatus] = useState(document.status);

  useEffect(() => {
    setCaseStatus(document.status);
  }, [document.status]);

  return (
    <Row
      contractingPartyType={document.contractingPartyType}
      documentStatus={document.status}
      tabIndex={0}
      onClick={onSigningCaseClick}
      onKeyDown={onSigningCaseKeyboardClick}
    >
      <TableCellTitle color={color}>
        <Title title={document.name} subtitle={document.contractingPartyName} />
      </TableCellTitle>
      <TableCellBig>
        <DeadlineDate date={document.date} />
      </TableCellBig>
      <TableCellLast align="right" hide={true}>
        <AgreementStatus
          status={document.status}
          color={color}
          displayedStatusText={getTextFromAgreementStatus(caseStatus)}
        />
      </TableCellLast>
      <TableCellMobile color={color}>
        <AgreementDeadlineMobile date={document.date} />
        <AgreementStatusMobile status={document.status} color={color} />
      </TableCellMobile>
    </Row>
  );
};
