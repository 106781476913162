import { Icon } from 'src/components/Icons';
import styled from 'styled-components';
import { darkBlue, white } from 'src/styles';

export const IconStyled = styled(Icon)`
  font-size: 50px;
`;

export const MessageContainer = styled.div`
  text-align: left;
`;

export const Time = styled.p`
  font-size: 24px;
`;

export const Content = styled.div`
  padding: 15px;
  background-color: ${white};
`;

const borderRadius = '3px';

export const FooterWrapper = styled(Content)`
  border-bottom-left-radius: ${borderRadius};
  border-bottom-right-radius: ${borderRadius};
`;

export const Header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.pallete.backgrounds.modalHeader};
  padding: 15px;
  border-bottom: 1px solid ${darkBlue};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const TitleContainer = styled.span`
  font-size: 1.2em;
  font-weight: ${({ theme }) => theme.typography.dialogHeader.fontWeight};
  color: ${white};
  display: flex;
`;
