import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import { createAsyncActionTypes } from '../common';

const types = createAsyncActionTypes('CREATE_CUSTOMER');

export const onboardCustomer = createStandardAction('ONBOARD_CUSTOMER')();

export const createCustomer = createAsyncAction(
  types.REQUEST,
  types.SUCCESS,
  types.FAILURE
)<string[], undefined, Error>();
