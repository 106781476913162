import { ApiTypes } from 'src/models';
import { action } from 'typesafe-actions';
import ActionTypes from './types';

const Actions = {
  reject: (props: ApiTypes.ISignProps) => action(ActionTypes.REJECT, props),
  rejectFailure: () => action(ActionTypes.REJECT_FAILURE),
  rejectRequest: () => action(ActionTypes.REJECT_REQUEST),
  rejectSuccess: (props: boolean) => action(ActionTypes.REJECT_SUCCESS, props),
};

export default Actions;
