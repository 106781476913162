import { HTMLAttributes } from 'react';
import styled from 'styled-components';

export type TDirection = 'left' | 'right';
export interface IArrow extends HTMLAttributes<HTMLSpanElement> {
  direction?: TDirection;
}

const ArrowIcon = styled.span<IArrow>`
  &:after {
    display: inline-block;
    font-family: 'DanskeIcons';
    font-size: 1.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\\e801';

    transform: ${props =>
      props.direction === 'left' ? 'rotate(180deg)' : 'unset'};
  }
`;

ArrowIcon.defaultProps = {
  direction: 'right',
};

export default ArrowIcon;
