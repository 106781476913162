import { FormControl } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  padding: 13px;
`;

export const FormControlStyled = styled(FormControl)`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
