import React from 'react';
import { LinkProps } from 'react-router-dom';
import { GridStyled, PortalLink } from './styles';
import { t } from 'i18next';

export const NoAgreementsMessage = ({ to }: LinkProps<any>) => {
  const text = t('SeeTheArchiveToFindOtherAgreements', { returnObjects: true });
  const parts = text.split('{link}');

  return (
    <GridStyled container data-testid="no-signing-cases">
      <span>{t('ThereAreNoPendingAgreementsThatRequireYourSignature')}</span>
      <div>
        {parts[0]}
        <PortalLink to={to} data-testid="archive-link">
          {t('Archive')}
        </PortalLink>
        {parts[1]}
      </div>
    </GridStyled>
  );
};
