import React, { ComponentProps, FC } from 'react';
import { ButtonContainer } from './ReturnButton.styles';
import { PortalLink } from '../PortalButton';
import { t } from 'i18next';

interface IReturnButton {
  linkProps: ComponentProps<typeof PortalLink>;
}

const ReturnButton: FC<IReturnButton> = ({
  linkProps: { colorType = 'secondary', ...rest },
  children,
}) => {
  return (
    <ButtonContainer>
      <PortalLink colorType={colorType} {...rest}>
        {children || t('ReturnToAgreements')}
      </PortalLink>
    </ButtonContainer>
  );
};

export default ReturnButton;
