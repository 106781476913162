import { Input } from 'reactstrap';
import styled from 'styled-components';

interface InputProps {
  hasErrors?: boolean;
}

export const InputStyled = styled(Input)<InputProps>`
  border-color: ${props => (props.hasErrors ? 'red' : 'lightgrey')};
`;
