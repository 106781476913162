import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchProductAreas } from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import { getType } from 'typesafe-actions';
import { fetchProductAreas as fetchProductAreasActions } from './actions';

function* fetchProductAreasSaga(): Generator {
  try {
    const response: any = yield call(fetchProductAreas);

    yield put(fetchProductAreasActions.success(response.data));
  } catch (e) {
    yield put(fetchProductAreasActions.failure());
    yield put(notificationsManager.addNotification(e));
  }
}

export function* watchFetchProductAreas(): Generator {
  yield takeLatest(
    getType(fetchProductAreasActions.request),
    fetchProductAreasSaga
  );
}
