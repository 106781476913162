import { revokeMandate } from './actions';
import { isLoading, success } from 'src/state/Async';
import { combineReducers } from 'redux';

export const mandateRevocation = combineReducers({
  isLoading: isLoading(revokeMandate),
  isRevoked: success(false, revokeMandate, true),
});

export default mandateRevocation;

type State = ReturnType<typeof mandateRevocation>;

export const isRevoked = (state: State) => state.isRevoked;

export const isRevoking = (state: State) => state.isLoading;
