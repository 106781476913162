import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as api from '../api';
import { fetchUserData } from '../UserData';
import { createCustomer } from './actions';

type CreateCustomerProps = ReturnType<typeof createCustomer.request>;

function* createCustomerSaga(props: CreateCustomerProps) {
  try {
    yield call(api.createCustomer, { citizenships: props.payload });
    yield put(createCustomer.success());
    yield put(fetchUserData.request());
  } catch (e) {
    yield put(createCustomer.failure(e));
  }
}

export function* watchCreateCustomer() {
  yield takeLatest(getType(createCustomer.request), createCustomerSaga);
}
