import React, { ComponentProps, FC } from 'react';
import { Button, LinkStyled } from './styles';
import { LinkProps } from 'react-router-dom';

type Props = LinkProps<any> &
  ComponentProps<typeof Button> & {
    title?: string;
  };

const LinkWrapper: FC<Props> = ({ colorType, title, fullWidth, ...rest }) => (
  <LinkStyled {...(rest as LinkProps<any>)} />
);

const LinkComponent = Button.withComponent(LinkWrapper);

export const PortalLink: FC<Props> = props => {
  const {
    title,
    children,
    colorType = 'primary',
    ref,
    ...buttonsProps
  } = props;

  return (
    <LinkComponent colorType={colorType} {...buttonsProps}>
      {title || children || null}
    </LinkComponent>
  );
};
