import styled from 'styled-components';
import { Text } from './Text';
import { getThemeColor } from 'src/styles';

export const LinkStyled = styled(Text)`
  font-weight: bold;
  &:hover {
    color: ${({ colorType = 'primary', theme }) =>
      getThemeColor(colorType, theme)};
  }
`;
