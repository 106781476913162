import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { darkBlue } from '../../styles/colors';

export const Container = styled(Grid)`
  background-color: ${darkBlue};
`;

export const Description = styled.p`
  color: white;
`;
