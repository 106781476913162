import { IProductArea } from 'src/models';
import { getType, isActionOf } from 'typesafe-actions';
import { FetchProductAreas, fetchProductAreas } from './actions';
import { Reducer } from 'redux';

interface State {
  isLoading: boolean;
  list?: IProductArea[];
}

const initialState: State = {
  isLoading: false,
};

const reducer: Reducer<State, FetchProductAreas> = (state, action) => {
  if (action.type === getType(fetchProductAreas.request)) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (isActionOf(fetchProductAreas.success, action)) {
    return {
      isLoading: false,
      list: action.payload,
    };
  } else if (action.type === getType(fetchProductAreas.failure)) {
    return initialState;
  }

  return state || initialState;
};

export default reducer;

export const getProductAreasList = (state: State) => state.list;
export const getIsProductAreasLoading = (state: State) => state.isLoading;
