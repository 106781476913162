import styled from 'styled-components';

export const Ul = styled.ul`
  list-style-type: none;
  padding-left: 15px;
  margin: 0;
`;

export const Li = styled.li`
  &::before {
    content: '-';
    padding-right: 5px;
  }
`;
