import React from 'react';
import { ISigningRules } from '../ISigningRules';
import { Description, Rule, Rules } from './SigningRulesList.styles';
import { t } from 'i18next';

interface ISigningRulesList extends ISigningRules {}

const SigningRulesList = ({ className, rules }: ISigningRulesList) => {
  if (rules.length) {
    return (
      <div className={className}>
        <Description>
          {t(
            'TheSigningOfThisAgreementIsCompleteWhenTheSignaturesMatchOneOfThe' +
              'FollowingRules'
          )}
        </Description>
        <Rules>
          {rules.map((signingRule, index) => (
            <Rule key={index}>{signingRule.rule}</Rule>
          ))}
        </Rules>
      </div>
    );
  } else {
    return null;
  }
};

export default SigningRulesList;
