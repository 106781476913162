import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  z-index: 1010;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
`;
