import styled from 'styled-components';
import { LanguageLink } from '../LanguageLink';
import { getLinkShadow } from 'src/styles/effects';

interface LinkProps {
  isActive: boolean;
}

export const Link = styled(LanguageLink)<LinkProps>`
  padding: 19px 8px;
  transition: all 0.2s ease-out;
  box-shadow: ${({ theme, isActive }) =>
    isActive ? getLinkShadow(theme.pallete.primary.main) : 'none'};
  color: ${({ theme, isActive }) =>
    isActive ? theme.pallete.action.hover : 'inherit'};

  &:hover {
    box-shadow: ${({ theme }) => getLinkShadow(theme.pallete.primary.main)};
  }
`;
