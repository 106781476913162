import { INewSignatory } from 'src/pages/Mandates/models';

type SignatoriesTransform = (
  signatories: INewSignatory[],
  signatory: INewSignatory
) => INewSignatory[];

export const updateBySignatoryId: SignatoriesTransform = (
  signatories,
  signatory
) =>
  signatories.map(x =>
    x.externalId === signatory.externalId
      ? { ...signatory, isLoading: false }
      : x
  );

export const updateSignatory: SignatoriesTransform = (signatories, signatory) =>
  signatories.map(x => {
    if (
      x.externalId === signatory.externalId &&
      x.fullName === signatory.fullName
    ) {
      return { ...signatory, isLoading: false };
    } else {
      return x;
    }
  });

type RemoveDuplicates = (signatories: INewSignatory[]) => INewSignatory[];
export const removeDuplicates: RemoveDuplicates = signatories =>
  signatories.reduce(
    (acc, x) => {
      if (acc.some(y => isSignatoriesEqual(x, y))) {
        return acc;
      }

      return [...acc, x];
    },
    [] as INewSignatory[]
  );

export const isSignatoryValid = (x: INewSignatory) => !x.error;
export const isSignatoriesEqual = (x: INewSignatory, y: INewSignatory) =>
  x.externalId === y.externalId &&
  x.fullName === y.fullName &&
  x.error === y.error;
