import React, { FC, ComponentProps } from 'react';
import { Config } from 'src/state';
import {
  DanskeBankLogo,
  RealkreditDenmarkLogo,
  DanicaDKLogo,
  NordaniaDenmarkLogo,
} from './components';

type Props = ComponentProps<typeof DanskeBankLogo>;

export const Logo: FC<Props> = props => {
  return Config.isDanicaDKSite() ? (
    <DanicaDKLogo {...props} />
  ) : Config.isRealkreditDenmark() ? (
    <RealkreditDenmarkLogo {...props} />
  ) : Config.isNordaniaDenmarkSite() ? (
    <NordaniaDenmarkLogo {...props} />
  ) : (
    <DanskeBankLogo {...props} />
  );
};
