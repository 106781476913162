import { IFileConfig, Language } from 'src/models';
import { languageCodeByCountry, getLanguageCode } from '../../LocaleManager';

export { languageCodeByCountry };

export const siteNames = {
  denmark: 'Denmark',
  england: 'UnitedKingdom',
  finland: 'Finland',
  realkreditDenmark: 'RealkreditDenmark',
  sweden: 'Sweden',
  norway: 'Norway',
  danicaDK: 'DanicaDK',
  nordaniaDenmark: 'NordaniaDenmark',
} as const;

const languageCodeBySiteName = Object.keys(siteNames).reduce(
  (codes, site) => ({
    ...codes,
    [siteNames[site]]: languageCodeByCountry[site],
  }),
  {}
);

const marketCodes = {
  [siteNames.england]: 'uk',
  [siteNames.realkreditDenmark]: 'rd',
  [siteNames.danicaDK]: 'dani',
  [siteNames.nordaniaDenmark]: 'nd',
} as const;

const supportedLanguageCodesBySiteName = {
  [siteNames.denmark]: [
    languageCodeByCountry.denmark,
    languageCodeByCountry.england,
  ],
  [siteNames.england]: [languageCodeByCountry.england],
  [siteNames.finland]: [
    languageCodeByCountry.finland,
    languageCodeByCountry.england,
    languageCodeByCountry.sweden,
  ],
  [siteNames.realkreditDenmark]: [
    languageCodeByCountry.realkreditDenmark,
    languageCodeByCountry.england,
  ],
  [siteNames.sweden]: [
    languageCodeByCountry.sweden,
    languageCodeByCountry.england,
  ],
  [siteNames.norway]: [
    languageCodeByCountry.norway,
    languageCodeByCountry.england,
  ],
  [siteNames.danicaDK]: [
    languageCodeByCountry.denmark,
    languageCodeByCountry.england,
  ],
  [siteNames.nordaniaDenmark]: [
    languageCodeByCountry.denmark,
    languageCodeByCountry.england,
  ],
};

export const Config = ({
  language = 'en' as string,
  host = 'https://syst-sign-api.danskebank.co.uk/api',
  site = 'UnitedKingdom' as IFileConfig['site'],
} = {}) => ({
  host,
  site,
  language,
  init(config) {
    this.host = config.host;
    this.site = config.site;

    if (this.isLanguageCodeValid(config.language)) {
      this.setLanguage(config.language);
    } else {
      this.setDefaultLanguage();
    }
  },
  isEnglishLanguage() {
    return validateLanguage(this.language, languageCodeByCountry.england);
  },
  isUkSite() {
    return this.site === siteNames.england;
  },
  isNorwaySite() {
    return this.site === siteNames.norway;
  },
  isNorwegianLanguage() {
    return validateLanguage(this.language, languageCodeByCountry.norway);
  },
  isRealkreditDenmark() {
    return this.site === siteNames.realkreditDenmark;
  },
  isDenmark() {
    return this.site === siteNames.denmark;
  },
  isDenmarkCountry() {
    return this.isRealkreditDenmark() || this.isDenmark();
  },
  isDanishLanguage() {
    return validateLanguage(this.language, languageCodeByCountry.denmark);
  },
  isSwedishSite() {
    return this.site === siteNames.sweden;
  },
  isSwedishLanguage() {
    return validateLanguage(this.language, languageCodeByCountry.sweden);
  },
  isFinlandSite() {
    return this.site === siteNames.finland;
  },
  isDanicaDKSite() {
    return this.site === siteNames.danicaDK;
  },

  isNordaniaDenmarkSite() {
    return this.site === siteNames.nordaniaDenmark;
  },

  isFinnishLanguage() {
    return validateLanguage(this.language, languageCodeByCountry.finland);
  },
  getLanguageCode() {
    return getLanguageCode(this.language);
  },
  isLanguageCodeValid(language: string) {
    const languages = supportedLanguageCodesBySiteName[this.site] as any;
    return languages ? languages.includes(language) : false;
  },
  setLanguage(languageCode: string) {
    if (!this.isLanguageCodeValid(languageCode)) return;

    if (marketCodes[this.site]) {
      this.language = `${languageCode}-${marketCodes[this.site]}` as Language;
    } else if (languageCodeBySiteName[this.site] !== languageCode) {
      this.language = `${languageCode}-${
        languageCodeBySiteName[this.site]
      }` as Language;
    } else {
      this.language = languageCode as Language;
    }
  },
  setDefaultLanguage() {
    this.setLanguage(languageCodeBySiteName[this.site]);
  },
  getSupportedLanguageCodes() {
    return supportedLanguageCodesBySiteName[this.site];
  },
});

const validateLanguage = (language: string, expectedLanguage: string) => {
  const languageRegex = new RegExp(
    `^${expectedLanguage}|${expectedLanguage}-[a-z]{2}$`
  );
  return language.search(languageRegex) >= 0;
};
