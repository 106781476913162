import { darkBlue } from 'src/styles/colors';
import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.pallete.backgrounds.modalHeader};
  padding: 15px;
  border-bottom: 1px solid ${darkBlue};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const TitleContainer = styled.span`
  display: inline-block;
  font-size: 1.2em;
  font-weight: ${({ theme }) => theme.typography.dialogHeader.fontWeight};
  color: ${darkBlue};
  width: 80%;
  text-align: left;
`;

export const CloseBtnContainer = styled.span`
  display: inline-block;
  width: 20%;
  text-align: right;
  font-size: 1.4em;
`;
