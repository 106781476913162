import { IValidateIdentityProps } from 'src/models/Api';
import { INewSignatory, IupdateSignatory } from 'src/pages/Mandates/models';
import { action, ActionType } from 'typesafe-actions';
import ActionTypes from './types';

export const signatoriesActions = {
  addSignatory: (props: IValidateIdentityProps) =>
    action(ActionTypes.ADD_SIGNATORY, props),
  addSignatoryFailure: (props: INewSignatory) =>
    action(ActionTypes.ADD_SIGNATORY_FAILURE, props),
  addSignatorySuccess: (props: INewSignatory) =>
    action(ActionTypes.ADD_SIGNATORY_SUCCESS, props),
  removeSignatory: (id: number) => action(ActionTypes.REMOVE_SIGNATORY, id),
  updateSignatory: (props: IupdateSignatory) =>
    action(ActionTypes.UPDATE_SIGNATORY, props),
  validateSignatory: (props: IValidateIdentityProps) =>
    action(ActionTypes.VALIDATE_SIGNATORY, props),
};

export type Signatories = ActionType<typeof signatoriesActions>;
