import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const Section = styled(Grid)`
  font-size: 1em;
`;

export const TitleContainer = styled(Grid)`
  background-color: #ebf7fb;
  padding: 5px;
`;

export const ListContainer = styled(Grid)`
  padding: 15px 0;
`;

export const Title = styled.span`
  font-size: 1.2em;
  font-weight: bold;
`;
