import React, { Component } from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import {
  ErrorNotification,
  SuccessNotification,
  WarningNotification,
} from 'src/components/Notifications';
import { notificationsManager } from 'src/state/Notifications';
import * as fromRoot from 'src/state/rootReducer';

interface INotificationsComponent extends WithNamespaces {
  notifications: ReturnType<typeof fromRoot.getNotificationsTypes>;
  remove: typeof notificationsManager.removeNotification;
}

class Notifications extends Component<INotificationsComponent> {
  public render() {
    const { notifications, t } = this.props;

    if (!notifications.length) {
      return null;
    }

    return (
      <div>
        {notifications.map(notification =>
          notification.type === 'Error' ? (
            <ErrorNotification
              key={notification.text}
              text={t(notification.text)}
              onClose={this.onClose(notification.text)}
            />
          ) : notification.type === 'Success' ? (
            <SuccessNotification
              key={notification.text}
              text={t(notification.text)}
              onClose={this.onClose(notification.text)}
            />
          ) : notification.type === 'Warning' ? (
            <WarningNotification
              key={notification.text}
              text={
                <div
                  dangerouslySetInnerHTML={{ __html: t(notification.text) }}
                />
              }
              onClose={this.onClose(notification.text)}
            />
          ) : null
        )}
      </div>
    );
  }

  private onClose = (text: string) => () => {
    this.props.remove(text);
  };
}

const connectedComponent = connect(
  state => ({
    notifications: fromRoot.getNotificationsTypes(state),
  }),
  {
    remove: notificationsManager.removeNotification,
  }
)(Notifications);

export default withNamespaces()(connectedComponent);
