import { darkBlue } from 'src/styles/colors';
import styled from 'styled-components';

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.6em;
  font-size: inherit;
  color: ${darkBlue};
  font-weight: 700;
`;

export default Label;
