import { Grid } from '@material-ui/core';
import React, { Component, SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { INewSignatory, Signatory } from 'src/pages/Mandates/models';
import { NewSigningMandate } from 'src/state/Mandates';
import { requiredSignatories as requiredSignatoriesActions } from 'src/state/Mandates/New/RequiredSignatories';
import { notificationsManager } from 'src/state/Notifications';
import * as fromRoot from 'src/state/rootReducer';
import { TRootState } from 'src/state/types';
import SignatoriesList from '../SignatoriesList';
import { AddSignatoriesInput, RequiredSignatoriesSelect } from './components';
import { HorizontalLine } from './SignatoriesFormGroup.styles';

const signatoriesActions = NewSigningMandate.signatoriesActions;

interface ISignatoriesFormGroup {
  notification: typeof notificationsManager;
  signatories: ReturnType<typeof fromRoot.getSignatories>;
  validSignatories: ReturnType<typeof fromRoot.getValidSignatories>;
  signatoriesActions: typeof signatoriesActions;
  requiredSignatories: typeof requiredSignatoriesActions;
  selectedRequiredSignatories: ReturnType<
    typeof fromRoot.getRequiredSignatories
  >;
}

class SignatoriesFormGroup extends Component<ISignatoriesFormGroup> {
  componentDidUpdate() {
    const {
      requiredSignatories,
      validSignatories,
      selectedRequiredSignatories,
    } = this.props;

    if (!selectedRequiredSignatories && validSignatories.length) {
      requiredSignatories.select('1');
    }
  }

  public render() {
    const {
      signatoriesActions,
      signatories,
      selectedRequiredSignatories,
      validSignatories,
    } = this.props;

    return (
      <FormGroup>
        <Grid container={true} direction="column" spacing={8} wrap="nowrap">
          <Grid item={true}>
            <AddSignatoriesInput onAddSignatory={this.addSignatory} />
          </Grid>
          {signatories.length ? null : (
            <Grid item={true}>
              <HorizontalLine />
            </Grid>
          )}
          <Grid item={true}>
            <SignatoriesList
              signatories={signatories}
              removeSignatory={signatoriesActions.removeSignatory}
              updateSignatory={signatoriesActions.updateSignatory}
              refreshSignatory={signatoriesActions.validateSignatory}
            />
          </Grid>
          {validSignatories.length ? (
            <Grid container={true} item={true} justify="center" spacing={8}>
              <Grid item={true}>
                <RequiredSignatoriesSelect
                  onClick={this.onRequiredSignatoriesSelect}
                  value={selectedRequiredSignatories}
                  requiredSignatories={validSignatories.length}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </FormGroup>
    );
  }

  private onRequiredSignatoriesSelect = (
    e: SyntheticEvent<HTMLInputElement>
  ) => {
    this.props.requiredSignatories.select(e.currentTarget.value);
  };

  private addSignatory = (signatory: Signatory) => {
    if (doesSignatoryExist(this.props.signatories, signatory)) {
      this.props.notification.addNotification({
        text: 'ErrorSameSignatory',
        type: 'Warning',
      });
    } else {
      this.props.signatoriesActions.addSignatory(signatory);
    }
  };
}

const doesSignatoryExist = (
  signatories: INewSignatory[],
  signatory: Signatory
) =>
  signatories
    .filter(s => !s.error)
    .find(s => s.externalId === signatory.externalId);

const mapStateToProps = (state: TRootState) => ({
  selectedRequiredSignatories: fromRoot.getRequiredSignatories(state),
  signatories: fromRoot.getSignatories(state),
  validSignatories: fromRoot.getValidSignatories(state),
});

const mapDispatchToProps = dispatch => ({
  notification: bindActionCreators(notificationsManager, dispatch),
  requiredSignatories: bindActionCreators(requiredSignatoriesActions, dispatch),
  signatoriesActions: bindActionCreators(signatoriesActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignatoriesFormGroup);
