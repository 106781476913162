import styled from 'styled-components';

const textOverflowElipsis = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Title = styled.span`
  display: block;
  width: 100%;
  font-weight: 700;
  ${textOverflowElipsis};
`;

export const Subtitle = styled.span`
  display: block;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8em;
  margin-top: 0.2em;
  ${textOverflowElipsis}
`;
