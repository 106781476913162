import React from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Subtitle } from './styles';
import { IconStyled } from './styles';
import { grey, darkBlue } from 'src/styles/colors';
import { Link } from 'src/components/Text';
import { t } from 'i18next';

interface Props extends GridProps {
  isRead: boolean;
  canDownload?: boolean;
  showText?: boolean;
  downloadLink: string;
}

export const DocumentStatus = ({
  canDownload = false,
  isRead,
  showText = true,
  downloadLink,
}: Props) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs="auto">
        {isRead && !canDownload ? (
          <IconStyled
            data-testid="ok-icon"
            type="ok"
            aria-label=""
            color={grey}
          />
        ) : canDownload ? (
          <Link href={downloadLink} onClick={e => e.stopPropagation()}>
            <IconStyled
              data-testid="download-icon"
              type="download"
              aria-label={t('Download')}
            />
          </Link>
        ) : (
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            data-testid="external-link"
          />
        )}
      </Grid>
      {showText && (
        <Grid item>
          {canDownload ? (
            <Link href={downloadLink} onClick={e => e.stopPropagation()}>
              {t('Download')}
            </Link>
          ) : isRead ? (
            <Subtitle>{t('Seen')}</Subtitle>
          ) : (
            <Subtitle style={{ color: darkBlue }}>{t('Open')}</Subtitle>
          )}
        </Grid>
      )}
    </Grid>
  );
};
