import { applyMiddleware, createStore } from 'redux';
import immutableStateInvariant from 'redux-immutable-state-invariant';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { watchFetchCountries } from 'src/state/Countries';
import {
  watchfetchMandate,
  watchFetchMandates,
  watchRevokeMandate,
} from 'src/state/Mandates';
import { watchCreateMandate } from 'src/state/Mandates/New/CreateMandate';
import {
  watchAddSignatory,
  watchValidateIdentity,
} from 'src/state/Mandates/New/Signatories';
import { watchFetchProductAreas } from 'src/state/ProductAreas';
import {
  watchFetchSigningCase,
  watchMarkDocumentRead,
} from 'src/state/SigningCaseDetails';
import { watchFetchSigningCasesList } from 'src/state/SigningCasesList';
import { watchCreateCustomer } from './CustomerCreation';
import { watchGetIdentity } from './Identity';
import rootReducer from './rootReducer';
import {
  CreateSignature,
  IsSignatureSuccessful,
  RejectSigningCase,
} from './Signing';
import { watchFetchUserData } from './UserData';
import { watchRefreshSession, watchLogOut } from './UserSession';

const sagaMiddleware = createSagaMiddleware();
const commonMiddleware = [sagaMiddleware];

const middleware =
  process.env.NODE_ENV === 'development'
    ? [...commonMiddleware, immutableStateInvariant(), logger]
    : commonMiddleware;

const store = createStore(rootReducer, applyMiddleware(...middleware));

function* rootSaga() {
  yield all([
    watchAddSignatory(),
    watchCreateCustomer(),
    watchFetchCountries(),
    watchFetchSigningCase(),
    watchFetchSigningCasesList(),
    watchFetchProductAreas(),
    watchFetchUserData(),
    watchGetIdentity(),
    watchMarkDocumentRead(),
    watchValidateIdentity(),
    watchCreateMandate(),
    watchFetchMandates(),
    watchfetchMandate(),
    watchRevokeMandate(),
    watchLogOut(),
    watchRefreshSession(),
    CreateSignature.watch(),
    IsSignatureSuccessful.watch(),
    RejectSigningCase.watch(),
  ]);
}

sagaMiddleware.run(rootSaga);

export default store;
