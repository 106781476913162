import { Grid } from '@material-ui/core';
import React, { FC, HTMLAttributes } from 'react';
import { Spinner } from '../Loaders';
import { Config } from 'src/state/Config';
import { LoadingMessage } from './styles';

interface Props {
  text?: string;
  spinnerSize?: number;
}

export const LoadingScreen: FC<Props & HTMLAttributes<HTMLElement>> = ({
  spinnerSize = 50,
  text = 'Loading',
  children,
  ...props
}) => {
  const color = Config.isRealkreditDenmark() ? 'secondary' : 'primary';

  return (
    <Grid
      container
      alignContent="center"
      alignItems="center"
      direction="column"
      {...props}
    >
      <Grid item xs="auto">
        <Spinner color={color} size={spinnerSize} />
      </Grid>
      <Grid item xs="auto">
        <LoadingMessage>{text}</LoadingMessage>
      </Grid>
    </Grid>
  );
};
