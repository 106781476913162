import { call, put, takeEvery } from 'redux-saga/effects';
import * as Api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import { fetchMandates } from './actions';
import { FetchMandatesTypes } from './types';

function* fetchMandatesSaga() {
  try {
    const signingMandates = yield call(Api.fetchMandates);
    yield put(fetchMandates.getSuccess(signingMandates.data));
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
    yield put(fetchMandates.getFailure());
  }
}

export function* watchFetchMandates() {
  yield takeEvery(FetchMandatesTypes.GET_REQUEST, fetchMandatesSaga);
}
