import { CreateMandate } from 'src/state/Mandates/New/CreateMandate';
import ActionTypes from './types';

interface IState {
  [index: string]: boolean;
}

const initialState = {};

const productAreas = (state: IState = initialState, action: any) => {
  if (action.type === ActionTypes.SET_PRODUCT_AREA) {
    const { index, value } = action.payload;
    return {
      ...state,
      [index]: value,
    };
  } else if (action.type === CreateMandate.REQUEST) {
    return initialState;
  }

  return state;
};

export const getProductAreasFormItem = (
  state: ReturnType<typeof productAreas>,
  index: string
) => state[index];

export default productAreas;
