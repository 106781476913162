import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios, { AxiosResponse } from 'axios';
import i18n from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { IFileConfig } from 'src/models';
import Root from 'src/Root';
import { Config } from 'src/state/Config';
import moment from 'moment';
import { LocaleManager } from 'src/state/LocaleManager';
import registerServiceWorker from './registerServiceWorker';
import './state/interceptors';

export const getLanguageCodeParam = (path: string) => {
  const pathParams = path.split('/');
  return pathParams.length > 0 ? pathParams[1] : '';
};

export const processConfig = (configResp: AxiosResponse<IFileConfig>) => {
  Config.init(configResp.data);
  const languageCodeParam = getLanguageCodeParam(window.location.pathname);
  Config.setLanguage(languageCodeParam);
  const localeManager = new LocaleManager(i18n, moment, Config);
  localeManager.initI18n(startApp);
  axios.defaults.headers['Accept-Language'] = Config.getLanguageCode();
  axios.defaults.headers['X-Requested-with'] = 'XMLHttpRequest';
  axios.defaults.headers['referrer-policy'] = 'no-referrer-when-downgrade';
  axios.defaults.withCredentials = true;
};

try {
  const configUrl = window.location.hostname;
  const suffix = process.env.REACT_APP_MOCK ? '-mock' : '';

  axios
    .get(`${process.env.PUBLIC_URL}/${configUrl + suffix}.json`)
    .then(processConfig);
} catch (e) {
  throw new Error(`Config file ${window.location.hostname}.json was not found`);
}

const startApp = () => {
  ReactDOM.render(<Root />, document.getElementById('root') as HTMLElement);
  registerServiceWorker();
};
