import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { ModalInfo } from 'src/components/Modals';
import { productAreasSelect } from 'src/state/Mandates/New/ProductAreas';
import { fetchProductAreas } from 'src/state/ProductAreas';
import * as fromRoot from 'src/state/rootReducer';
import { TRootState } from 'src/state/types';
import { ProductAreas, Title } from './components';

const initialState = {
  isDescriptionOpened: false,
  openedProductAreaIndex: -1,
};
type TState = Readonly<typeof initialState>;
interface IProductAreaFormGroup extends WithNamespaces {
  productAreas: ReturnType<typeof fromRoot.getProductAreasList>;
  fetchData: typeof fetchProductAreas['request'];
  set: typeof productAreasSelect['set'];
  selectedProductAreas: ReturnType<typeof fromRoot.getSelectedProductAreas>;
}

class ProductAreaFormGroup extends Component<IProductAreaFormGroup, TState> {
  public state: TState = initialState;

  componentDidMount() {
    if (this.hideComponent()) {
      this.props.fetchData();
    }
  }

  public render() {
    const { t, productAreas, selectedProductAreas } = this.props;
    const { isDescriptionOpened, openedProductAreaIndex } = this.state;

    if (this.hideComponent()) {
      return null;
    }

    const productArea = productAreas![openedProductAreaIndex];

    return (
      <FormGroup>
        <Grid container={true}>
          <Title as={'h2'}>{t('MandateSelectArea')}</Title>
        </Grid>
        <ProductAreas
          productAreas={productAreas!}
          showDescription={this.toggleModal}
          selected={selectedProductAreas}
          onSelect={this.setProductArea}
        />
        {isDescriptionOpened ? (
          <ModalInfo
            onClose={this.closeDescription}
            title={productArea.name}
            bodyProps={{
              message: productArea.description,
            }}
          />
        ) : null}
      </FormGroup>
    );
  }

  private toggleModal = (index: number) => this.setState(toggleModal(index));

  private closeDescription = () => this.toggleModal(-1);

  private hideComponent = () => !this.props.productAreas;

  private setProductArea = (index: string, value: any) => {
    return this.props.set({ index, value: value.target.checked });
  };
}

const toggleModal = (index: number) => (prevState: TState): TState => ({
  isDescriptionOpened: !prevState.isDescriptionOpened,
  openedProductAreaIndex: index,
});

const mapStateToProps = (state: TRootState) => ({
  productAreas: fromRoot.getProductAreasList(state),
  selectedProductAreas: fromRoot.getSelectedProductAreas(state),
});

const connectedComponent = connect(
  mapStateToProps,
  {
    fetchData: fetchProductAreas.request,
    set: productAreasSelect.set,
  }
)(ProductAreaFormGroup);
export default withNamespaces()(connectedComponent);
