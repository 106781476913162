import i18n from 'i18next';
import momentLibrary from 'moment';
import { Config } from 'src/state/Config';

export * from './services';

type Moment = typeof momentLibrary;
type LocalizationFile = { [key: string]: string };

export class LocaleManager {
  i18next: i18n.i18n;
  moment: Moment;
  config: typeof Config;
  fallbackLanguage = 'en';
  defaultNamespace = 'common';

  constructor(
    i18next: i18n.i18n,
    momentLibrary: Moment,
    config: typeof Config
  ) {
    this.i18next = i18next;
    this.moment = momentLibrary;
    this.config = config;
  }

  initI18n(callback: any) {
    this.i18next.init(
      {
        resources: {},
        lng: this.config.language,
        fallbackLng: this.fallbackLanguage,
        defaultNS: this.defaultNamespace,
        fallbackNS: this.defaultNamespace,
        lowerCaseLng: true,
      },
      () =>
        this.addResourceBundles()
          .then(this.configureLocale)
          .then(callback)
          .catch(e => {
            throw e;
          })
    );
  }

  private configureLocale = () => {
    if (this.config.isNorwegianLanguage()) {
      return this.loadLocale('nb');
    } else if (this.config.isDanishLanguage() || this.config.isDanicaDKSite()) {
      return this.loadLocale('da');
    } else if (this.config.isFinnishLanguage()) {
      return this.loadLocale('fi');
    } else if (this.config.isSwedishLanguage()) {
      return this.loadLocale('sv');
    }

    this.moment.locale('en-gb');
    return Promise.resolve();
  };

  private loadLocale = (locale: string): Promise<any> => {
    this.moment.locale(locale);
    return import(`moment/locale/${locale}`);
  };

  private addResourceBundles = () => {
    return this.loadTranslationFiles()
      .then(files => {
        this.i18next.addResourceBundle(
          this.i18next.options.lng!,
          this.defaultNamespace,
          files[0]
        );
        this.i18next.addResourceBundle(
          this.fallbackLanguage,
          this.defaultNamespace,
          files[1]
        );
        return;
      })
      .catch(e => {
        throw e;
      });
  };

  /**
   * Loads json file with key value pairs
   * @param lng language
   * @param ns language namespace
   */
  private loadLanguageNamespace(lng: string) {
    return import(`../../locale/${lng}.json`);
  }

  private loadTranslationFiles() {
    return Promise.all([
      this.loadLanguageNamespace(this.config.language),
      this.loadLanguageNamespace(this.fallbackLanguage),
    ]);
  }

  private loadTranslationFilesV2(
    languages: string[]
  ): Promise<LocalizationFile[]> {
    return Promise.all(
      languages.map(language => this.loadLanguageNamespace(language))
    );
  }

  private addResourceBundlesV2(fileNames: string[]) {
    return this.loadTranslationFilesV2(fileNames).then(files => {
      files.forEach(file => {
        this.i18next.addResourceBundle(
          this.i18next.options.lng!,
          this.defaultNamespace,
          file
        );
      });
    });
  }
}
