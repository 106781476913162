import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';

export const ProgressStepsContainer = styled.div`
  list-style: none;
  padding: 15px;
  margin: 25px 0 0;
  text-align: center;

  ${mediaMaxWidth2.tabletSmall`
    font-size: 0.6em;
  `}

  ${mediaMaxWidth2.mobileSmall`
    font-size: 0.54em;
  `}
`;
