import { createStandardAction } from 'typesafe-actions';
import { Status } from './StatusSelect';
import { Timeframe } from './TimeframeSelect';

const filterPrefix = 'ARCHIVE_FILTER';

export const applyFilter = createStandardAction(`${filterPrefix}/APPLY`)();

export const setDate = createStandardAction(`${filterPrefix}/SET_DATE`)<Date>();

export const setStatus = createStandardAction(`${filterPrefix}/SET_STATUS`)<
  Status
>();

export const setTimeframe = createStandardAction(
  `${filterPrefix}/SET_TIMEFRAME`
)<Timeframe>();
