import axios from 'axios';
import { ApiTypes } from 'src/models';
import { Config } from 'src/state/Config';

const getIdentityUrl = () => `${Config.host}/Identity`;

export const getIdentity: ApiTypes.GetIdentity = () =>
  axios.get(`${getIdentityUrl()}/Details`);

export const validateIdentity: ApiTypes.ValidateIdentity = props =>
  axios.post(getIdentityUrl(), props);
