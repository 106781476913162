import { ApiTypes } from 'src/models';
import { TActions } from './actions';
import ActionTypes from './types';

export interface IState {
  data: ApiTypes.ISignature;
  isLoading: boolean;
}

const initialState: IState = {
  data: { url: '', id: '' },
  isLoading: false,
};

type State = Readonly<typeof initialState>;

const reducer = (state: State, action: TActions) => {
  if (action.type === ActionTypes.CREATE_SIGNATURE_REQUEST) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (action.type === ActionTypes.CREATE_SIGNATURE_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isLoading: false,
    };
  } else if (action.type === ActionTypes.CREATE_SIGNATURE_FAILURE) {
    return {
      ...state,
      ...initialState,
    };
  }

  return initialState;
};

export default reducer;

export const getData = (state: State) => state.data;

export const getIsLoading = (state: State) => state.isLoading;

export const getId = (state: State) => getData(state).id;

export const getUrl = (state: State) => getData(state).url;

export const getIsSignatureCreated = (state: State) =>
  !!(getId(state) && getUrl(state));
