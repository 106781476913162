import styled from 'styled-components';
import { DocumentsListInfoText } from './PortalDocumentsListInfoText';
import { darkGrayText } from 'src/styles';

export const InfoText = styled(DocumentsListInfoText)`
  display: block;
  font-size: 0.8em;
  margin: 0 0 1em;
  color: ${darkGrayText};
`;
