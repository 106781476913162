import React from 'react';

import { TNavigationLinks } from 'src/models';
import { Link, Menu, MenuItem } from './styles';
import { isHomeRoute } from 'src/routes';

interface Props {
  list: TNavigationLinks;
  awaitingSigningCasesCount: number;
}

export const NavList = ({ list, awaitingSigningCasesCount }: Props) => {
  return (
    <Menu role="menu">
      {list.map((link, index) => (
        <MenuItem key={index} role="none">
          <Link to={link.to} exact={isHomeRoute(link.to)} role="menuitem">
            {link.title}
            {isHomeRoute(link.to) &&
              renderSigningCasesCounter(awaitingSigningCasesCount)}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );
};

export const renderSigningCasesCounter = signingCasesCount =>
  signingCasesCount
    ? ' [' + (signingCasesCount > 9 ? '9+' : signingCasesCount) + ']'
    : '';
