import React, { FC } from 'react';
import { TableCellMobile as CellMobile } from 'src/components';
import { getTableRowColor } from '../../services';
import { MandatesListType } from 'src/pages/Mandates/models';

interface Props {
  type: MandatesListType;
}

const TableCellMobile: FC<Props> = props => {
  const color = getTableRowColor(props.type);

  return <CellMobile color={color}>{props.children}</CellMobile>;
};

export default TableCellMobile;
