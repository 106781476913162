import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from './styles';
import { LanguageMenuLink } from 'src/routes';

interface Props {
  languageCode: string;
  languageMenuLinks: LanguageMenuLink[];
}

export const LanguageMenu = ({ languageCode, languageMenuLinks }: Props) => {
  return (
    <Grid container role="menu">
      {languageMenuLinks.map((languageMenuLink, index) => (
        <Grid
          item
          key={`${languageMenuLink.languageCode}-${index}`}
          role="none"
        >
          <Link
            href={languageMenuLink.path}
            isActive={languageMenuLink.languageCode === languageCode}
            role="menuitem"
          >
            {languageMenuLink.languageCode.toUpperCase()}
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};
