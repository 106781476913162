import React, { MouseEvent } from 'react';
import { ISigningCaseListItem } from 'src/models';
import { SigningCasesListItem } from './SigningCasesListItem';
import { Table, TableHead, TableHeaderCell } from 'src/components';
import { t } from 'i18next';

type ListType = 'Archived' | 'NotSigned' | 'Waiting';

interface Props {
  documents: ISigningCaseListItem[];
  type?: ListType;
  onClick?: (document: ISigningCaseListItem, index: number) => void;
}

export const SigningCasesList = ({ documents, type, onClick }: Props) => {
  const onSigningCaseClick = (index: number) => (
    e: MouseEvent,
    document: ISigningCaseListItem
  ) => {
    if (onClick) {
      onClick(document, index);
    }
  };

  return (
    <Table data-testid="data-testid">
      <TableHead>
        <tr>
          <TableHeaderCell>{t('Title')}</TableHeaderCell>
          <TableHeaderCell>
            {type === 'Archived' ? t('DateArchived') : t('DeadlineForSigning')}
          </TableHeaderCell>
          <TableHeaderCell>{t('Status')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>
        {documents.map((document, index) => {
          return (
            <SigningCasesListItem
              key={index}
              document={document}
              onClick={onSigningCaseClick(index)}
            />
          );
        })}
      </tbody>
    </Table>
  );
};
