import { call, put, takeLatest } from 'redux-saga/effects';
import * as Api from 'src/state/api';
import { notificationsManager } from 'src/state/Notifications';
import { getType } from 'typesafe-actions';
import { fetchCountries } from './actions';

function* fetchCountriesSaga(): Generator {
  try {
    const response: any = yield call(Api.fetchCountries) || {};
    yield put(fetchCountries.success(response.data));
  } catch (e) {
    yield put(notificationsManager.addNotification(e));
  }
}

export function* watchFetchCountries(): Generator {
  yield takeLatest(getType(fetchCountries.request), fetchCountriesSaga);
}
