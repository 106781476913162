import { Grid } from '@material-ui/core';
import React from 'react';
import { GridStyled } from 'src/styles/layout';
import CircleButton from '../CircleButton';
import { t } from 'i18next';

interface IProps {
  editable?: boolean;
  needsRefresh?: boolean;
  disabled: boolean;
  toggleEdit: () => void;
  removeSignatory: () => void;
  refresh: () => void;
}

const SignatoryMenu = ({
  disabled,
  toggleEdit,
  removeSignatory,
  needsRefresh = false,
  editable = false,
  refresh,
}: IProps) => {
  const notOrDisabledComponent = () =>
    !disabled ? (
      <GridCircleButtonComponent
        altText={t('Ok')}
        onClick={refresh}
        type="button"
        iconType="ok"
      />
    ) : (
      <GridCircleButtonComponent
        altText={t('Edit')}
        onClick={toggleEdit}
        type="button"
        iconType="pencil"
      />
    );

  return (
    <Grid container={true} justify="center">
      {editable ? notOrDisabledComponent : null}
      {needsRefresh ? (
        <GridCircleButtonComponent
          altText={t('Refresh')}
          onClick={refresh}
          type="button"
          iconType="refresh"
        />
      ) : null}
      <GridCircleButtonComponent
        altText={t('Delete')}
        onClick={removeSignatory}
        type="button"
        iconType="delete"
      />
    </Grid>
  );
};

export default SignatoryMenu;

interface IGridCircleButtonComponent {
  onClick?: () => void;
  iconType?: 'ok' | 'delete' | 'refresh' | 'pencil';
  type?: 'button' | 'submit' | 'reset';
  altText?: string;
}

const GridCircleButtonComponent = ({
  altText,
  type,
  iconType,
  onClick,
}: IGridCircleButtonComponent) => {
  return (
    <GridStyled item={true} padding={0}>
      <CircleButton
        iconType={iconType}
        type={type}
        iconAltText={altText}
        onClick={onClick}
      />
    </GridStyled>
  );
};
