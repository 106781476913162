import Grid from '@material-ui/core/Grid';
import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { IProductArea } from 'src/models';
import { Title } from '../Title';
import {
  AreaContainer,
  CheckboxStyled,
  Description,
  GridCheckbox,
  TagButton,
} from './ProductArea.styles';

interface IArea extends WithNamespaces {
  productArea: IProductArea;
  showDescription: () => void;
  selected: boolean;
  onSelect: (e: any) => void;
}

class Area extends Component<IArea> {
  public render() {
    const { t, showDescription, onSelect, selected } = this.props;
    const { name, description } = this.props.productArea;

    return (
      <AreaContainer component={'section'} container={true} selected={selected}>
        <Grid
          alignItems="flex-start"
          component="header"
          container={true}
          item={true}
        >
          <Grid item={true} xs={10}>
            <Title as={'h2'}>{name}</Title>
          </Grid>
          <GridCheckbox item={true} xs={2}>
            <CheckboxStyled onClick={onSelect} checked={selected} />
          </GridCheckbox>
        </Grid>
        <Grid component="p" container={true} item={true}>
          <Description>{description}</Description>
        </Grid>
        <TagButton type="button" onClick={showDescription}>
          {t('LabelFullDescription')}
        </TagButton>
      </AreaContainer>
    );
  }
}

export default withNamespaces()(Area);
