import { ISigningMandateDetails } from 'src/models';
import { ActionType, createAsyncAction } from 'typesafe-actions';

const prefix = 'FETCH_MANDATE';

export const fetchMandate = createAsyncAction(
  `${prefix}_REQUEST`,
  `${prefix}_SUCCESS`,
  `${prefix}_FAILURE`
)<{ id: string }, ISigningMandateDetails, undefined>();

export type FetchMandate = ActionType<typeof fetchMandate>;
