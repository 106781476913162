import Grid, { GridProps } from '@material-ui/core/Grid';
import React from 'react';
import { darkBlue, darkRedPrimary, transparentBlue } from 'src/styles/colors';
import { mediaMaxWidth2 } from 'src/styles/layout';
import styled from 'styled-components';
import GridWrapper from '../GridWrapper';

const RowBorder = '1px solid rgba(0,0,0,.3)';
const ErrorBorder = `3px solid ${darkRedPrimary}`;

interface IRowProps {
  hasError: boolean;
  border: boolean;
}

const borderColor = error => {
  return error ? darkRedPrimary : darkBlue;
};

export const Row = styled(({ hasError, border, ...props }: any) => (
  <GridWrapper {...props} />
))<IRowProps & GridProps>`
  position: relative;
  border-left: ${props =>
    props.border ? `8px ${borderColor(props.hasError)} solid` : 'none'};
  border-top: ${props => (props.hasError ? ErrorBorder : RowBorder)};
  border-bottom: ${RowBorder};

  &:hover {
    background-color: ${transparentBlue};
  }
`;

export const Col = styled(Grid)`
  padding: 16px;
`;

export const GridPerson = styled(Grid)`
  ${mediaMaxWidth2.large`
    display: none;
  `}
`;

export const GridFullName = styled(Grid)`
  flex-grow: 1;
`;
