import styled from 'styled-components';
import {
  getHeaderColor,
  getHeaderFontFamily,
  getLoaderMessageFontSize,
} from 'src/styles';

export const LoadingMessage = styled.h1`
  font-family: ${({ theme }) => getHeaderFontFamily(theme)};
  font-size: ${({ theme }) => getLoaderMessageFontSize(theme)};
  color: ${({ theme }) => getHeaderColor(theme)};
  margin: 0;
`;
