import moment from 'moment';
import React from 'react';
import { MandateState } from 'src/models';
import { ValidityDate } from './components';
import { ValidityContainer } from './Validity.styles';
import { t } from 'i18next';

interface IValidity {
  from: string;
  to: string;
  status: MandateState;
}

const Validity = ({ from, to, status }: IValidity) => {
  return (
    <ValidityContainer data-testid="data-testid">
      {status === 'Deleted' ? (
        <span>{t('MandateRevoked')}</span>
      ) : status === 'New' ? (
        moment(to).date() < moment.now() ? (
          <span>{t('Dash')}</span>
        ) : (
          <span>{t('MandateStatusPending')}</span>
        )
      ) : (
        <>
          <ValidityDate title={t('MandateValidFrom')} content={from} />
          <ValidityDate title={t('ValidTill')} content={to} />
        </>
      )}
    </ValidityContainer>
  );
};

export default Validity;
