import React from 'react';
import { Grid } from '@material-ui/core';
import { PortalButton } from 'src/components/Buttons';
import { Config } from 'src/state';
import { t } from 'i18next';

interface IDialogModalMenu {
  onConfirm: () => void;
  onCancel?: () => void;
}

const DialogModalMenu = ({ onConfirm, onCancel }: IDialogModalMenu) => {
  const rejectButtonType = Config.isRealkreditDenmark()
    ? 'primary'
    : 'secondary';

  return (
    <Grid container wrap="wrap" spacing={16}>
      <Grid item xs={12} sm={6}>
        <PortalButton onClick={onConfirm} fullWidth>
          {t('Yes')}
        </PortalButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <PortalButton onClick={onCancel} colorType={rejectButtonType} fullWidth>
          {t('No')}
        </PortalButton>
      </Grid>
    </Grid>
  );
};

export default DialogModalMenu;
