import styled from 'styled-components';
import { darkGrayText } from 'src/styles';

interface NameProps {
  isBold?: boolean;
}

export const DocumentName = styled.div<NameProps>`
  word-break: break-all;
  font-weight: ${props => (props.isBold ? 'bold' : '400')};
`;

export const DocumentNameSubtitle = styled.div`
  color: ${darkGrayText};
  font-size: 0.8em;
  margin-top: 0.2em;
  word-break: break-all;
`;
