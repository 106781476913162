import React from 'react';
import { Table, TableHead, TableHeaderCell } from 'src/components';
import { MandateLimits } from 'src/models';
import { SigningRuleTabelRow } from './components';
import { t } from 'i18next';

interface ISigningRules {
  limits: MandateLimits;
}

const SigningRules = ({ limits }: ISigningRules) => {
  return (
    <Table>
      <TableHead>
        <tr>
          <TableHeaderCell>{t('MandateProductArea')}</TableHeaderCell>
        </tr>
      </TableHead>
      <tbody>
        {limits.map((limit, index) => (
          <SigningRuleTabelRow key={index} limit={limit} />
        ))}
      </tbody>
    </Table>
  );
};

export default SigningRules;
