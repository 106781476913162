import styled from 'styled-components';

export const LanguageLink = styled.a`
  color: ${({ theme }) => theme.pallete.primary.main};
  transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;
  padding-top: 19px;
  padding-bottom: 19px;

  &:active,
  &:hover {
    color: ${({ theme }) => theme.pallete.action.hover} !important;
    cursor: pointer;
    text-decoration: none;
  }
`;
