import { withNamespaces, WithNamespaces } from 'react-i18next';
import React, { Component, HTMLAttributes } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Label } from 'src/components/Input';
import { CountryOptions } from 'src/pages/Onboarding/models';
import { fetchCountries } from 'src/state/Countries';
import * as fromRoot from 'src/state/rootReducer';

interface Props extends HTMLAttributes<HTMLDivElement>, WithNamespaces {
  value: CountryOptions;
  onChange: (e: any) => void;
  fetchData: typeof fetchCountries.request;
  countries: ReturnType<typeof fromRoot.getCountryOptions>;
  isLoading: ReturnType<typeof fromRoot.getIsCountriesLoading>;
}

class CountryInput extends Component<Props> {
  componentDidMount() {
    if (!this.props.countries.length) {
      this.props.fetchData();
    }
  }

  render() {
    const { value, onChange, countries, isLoading, t } = this.props;

    return (
      <div data-testid="country-select">
        <Label>{t('AddCitizenship')}</Label>
        <Select
          value={value}
          onChange={onChange}
          placeholder={t('EnterCitizenship')}
          isMulti={true}
          isClearable={true}
          isSearchable={true}
          isLoading={isLoading}
          options={countries}
          isOptionDisabled={this.isDisabled}
        />
      </div>
    );
  }

  isDisabled = () => this.props.value.length >= 3;
}

const mapStateToProps = state => ({
  countries: fromRoot.getCountryOptions(state),
  isLoading: fromRoot.getIsCountriesLoading(state),
});

export default connect(
  mapStateToProps,
  {
    fetchData: fetchCountries.request,
  }
)(withNamespaces()(CountryInput));
