import React, { ComponentProps, HTMLAttributes } from 'react';
import { Content } from '../../styles/layout';
import Notifications from '../PortalList/Notifications';
import { Container, Section } from './styles';
import { Title } from './Title';

interface Props extends HTMLAttributes<HTMLElement> {
  title?: string;
  popout?: boolean;
  showNotifications?: boolean;
  className?: string;
  type?: ComponentProps<typeof Container>['type'];
  labelText?: string;
}

export const Card = (props: Props) => {
  const {
    className,
    children,
    title,
    popout = false,
    showNotifications,
    type,
    labelText,
    ...rest
  } = props;

  return (
    <Section popout={popout} className={className} {...rest}>
      <Content>
        {showNotifications ? <Notifications /> : null}
        <Container type={type} data-testid="card-container">
          {title ? <Title labelText={labelText}>{title}</Title> : null}
          {children}
        </Container>
      </Content>
    </Section>
  );
};
