import { action, ActionType } from 'typesafe-actions';
import ActionTypes from './types';

interface SetPayload {
  index: string;
  value: boolean;
}

export const productAreasSelect = {
  set: (payload: SetPayload) => action(ActionTypes.SET_PRODUCT_AREA, payload),
};

export type ProductAreasSelect = ActionType<typeof productAreasSelect>;
