import { Button } from 'src/components/Buttons';
import { darkBlue } from 'src/styles/colors';
import styled from 'styled-components';

export const Circle = styled(Button)`
  background-color: transparent;
  border: none;
  font-size: 1.6em;
  width: 1.6em;
  height: 1.6em;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border: 1px solid ${darkBlue};
    border-radius: 0.8em;
    cursor: pointer;
  }
`;
