import React from 'react';
import { CircleSpan } from './Circle.styles';
import { ICircleStep } from '../../components/types';

const Circle = ({ active, activeStep, children }: ICircleStep) => {
  return (
    <CircleSpan active={active} activeStep={activeStep}>
      {children}
    </CircleSpan>
  );
};

export default Circle;
