import { ISigningMandateDetails } from 'src/models';
import { fetchMandate, FetchMandate } from './actions';
import { getType, isActionOf } from 'typesafe-actions';

interface ICompanyMandates {
  isLoading: boolean;
  data?: ISigningMandateDetails;
}

const initialState: ICompanyMandates = {
  isLoading: false,
};

type State = Readonly<typeof initialState>;

const mandate = (state: State = initialState, action: FetchMandate) => {
  if (getType(fetchMandate.request) === action.type) {
    return {
      ...state,
      isLoading: true,
    };
  } else if (isActionOf(fetchMandate.success, action)) {
    return {
      data: action.payload,
      isLoading: false,
    };
  } else if (isActionOf(fetchMandate.failure, action)) {
    return initialState;
  }

  return state;
};

export default mandate;

export const getMandate = (state: State) => state.data;

export const getIsLoading = (state: State) => state.isLoading;
