import React from 'react';
import { Table, TableHead } from 'src/components';
import { ISigningMandateDetails } from 'src/models';
import { MandatesList, MandatesTableLineBreak } from './components';
import { TableHeaderCell } from './MandatesTable.styles';
import { t } from 'i18next';

interface IMandatesTable {
  activeMandates: ISigningMandateDetails[];
  inactiveMandates: ISigningMandateDetails[];
}

const MandatesTable = ({
  activeMandates,
  inactiveMandates,
}: IMandatesTable) => (
  <Table>
    <TableHead>
      <tr>
        <TableHeaderCell>{t('Title')}</TableHeaderCell>
        <TableHeaderCell>{t('MandateValidFrom')}</TableHeaderCell>
        <TableHeaderCell>{t('ValidTill')}</TableHeaderCell>
      </tr>
    </TableHead>
    <tbody>
      <MandatesList
        list={activeMandates}
        type="Active"
        data-testid="active-mandate"
      />
      {inactiveMandates.length && activeMandates.length ? (
        <MandatesTableLineBreak />
      ) : null}
      <MandatesList
        list={inactiveMandates}
        type="Inactive"
        data-testid="inactive-mandate"
      />
    </tbody>
  </Table>
);

export default MandatesTable;
