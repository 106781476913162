import axios from 'axios';
import { ApiTypes } from 'src/models';
import { Config } from 'src/state/Config';
import { queryParameters } from '../constants';

const getSigningApi = () => `${Config.host}/Signing`;

export const createSignature: ApiTypes.CreateSignature = async (
  signingCaseId,
  contractingPartyId
) =>
  axios.post(
    `${getSigningApi()}/CreateSignature`,
    {
      caseId: signingCaseId,
      contractingPartyId,
    },
    {
      params: {
        [queryParameters.languageCode]: Config.getLanguageCode(),
      },
    }
  );

export const isSignatureSuccessful: ApiTypes.IsSignatureSuccessful = async (
  signatureId,
  signingCaseId,
  contractingPartyId
) =>
  axios.post(`${getSigningApi()}/IsSignatureSuccessful`, {
    caseId: signingCaseId,
    contractingPartyId,
    signatureId,
  });
