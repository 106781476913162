import React, { Component } from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { TRootState } from 'src/state';
import * as fromRoot from 'src/state/rootReducer';
import Details from '../Details';

interface IMandateDetails extends WithNamespaces {
  company: ReturnType<typeof fromRoot.getSelectedCompanyFormatted>;
  mandateTitle: ReturnType<typeof fromRoot.getMandateTitle>;
  signatories: ReturnType<typeof fromRoot.getSignatoriesFormatted>;
  productAreas: ReturnType<typeof fromRoot.getProductAreasNames>;
}

class MandateDetails extends Component<IMandateDetails> {
  public render() {
    const { company, t, signatories, mandateTitle, productAreas } = this.props;

    return (
      <>
        <Details title={t('MandateTitleLong')} content={[mandateTitle]} />
        <Details title={t('MandateCompany')} content={[company]} />
        <Details
          title={t('MandateAuthorisedMandataries')}
          content={signatories}
        />
        <Details title={t('MandateGrants')} content={productAreas} />
      </>
    );
  }
}

const mapStateToProps = (state: TRootState) => ({
  company: fromRoot.getSelectedCompanyFormatted(state),
  mandateTitle: fromRoot.getMandateTitle(state),
  productAreas: fromRoot.getProductAreasNames(state),
  signatories: fromRoot.getSignatoriesFormatted(state),
});

const connectedComponent = connect(mapStateToProps)(MandateDetails);
export default withNamespaces()(connectedComponent);
