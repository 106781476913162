import { call, put, takeEvery } from 'redux-saga/effects';
import { IdentityValidation } from 'src/models';
import { validateIdentity } from 'src/state/api';
import { signatoriesActions } from './actions';
import types from './types';

interface Identity {
  data: IdentityValidation;
}

export function* validateIdentitySaga(
  action: ReturnType<typeof signatoriesActions.addSignatory>
): Generator {
  const { payload } = action;
  try {
    const response: any = yield call(validateIdentity, {
      externalId: payload.externalId,
      fullName: payload.fullName,
    });

    if (response.data.isValid) {
      yield put(
        signatoriesActions.addSignatorySuccess({
          error: '',
          externalId: payload.externalId,
          fullName: response.data.fullName,
        })
      );
    } else {
      yield put(
        signatoriesActions.addSignatorySuccess({
          error: 'CustomerNotFound',
          externalId: payload.externalId,
          fullName: payload.fullName,
        })
      );
    }
  } catch (e) {
    yield put(
      signatoriesActions.addSignatoryFailure({
        error: 'InternalServerError',
        externalId: payload.externalId,
        fullName: payload.fullName,
      })
    );
  }
}

export function* watchAddSignatory(): Generator {
  yield takeEvery(types.ADD_SIGNATORY, validateIdentitySaga);
}

export function* watchValidateIdentity(): Generator {
  yield takeEvery(types.VALIDATE_SIGNATORY, validateIdentitySaga);
}
