import React, { FC } from 'react';
import { TableCellTitle } from 'src/components';
import { getTableRowColor } from '../../services';
import { MandatesListType } from 'src/pages/Mandates/models';

interface Props {
  type: MandatesListType;
}

const TableTitleCell: FC<Props> = props => {
  return (
    <TableCellTitle color={getTableRowColor(props.type)}>
      {props.children}
    </TableCellTitle>
  );
};

export default TableTitleCell;
