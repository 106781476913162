import { t } from 'i18next';
import React from 'react';
import { GenericFeedback, MessageScreen } from 'src/components';

export const ErrorCard = () => {
  return (
    <GenericFeedback>
      <MessageScreen
        title={t('LegitimizationFailedError')}
        info={t('LegitimizationError')}
      />
    </GenericFeedback>
  );
};
